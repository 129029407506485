import { KpkApiFacet } from "@keepeek/api-client";
import {
  convertToKpkMedia,
  customerCommonOverrideAtom,
  customSelectorInOut,
  formsQuerySelector,
  FunctionOverrideKey,
  KpkFilterType,
  KpkMedia,
  References,
  sheetFieldsSelector,
} from "@keepeek/commons";
import { selectorFamily, SerializableParam } from "recoil";

import { serializeComplexeParamsForRecoil } from "../../../lib/recoil-utils";
import { DATAVIEW_DEFAULT_TOTAL_COUNT } from "../constants";
import { DataViewElementsSelector } from "../types";
import { getDataViewContentQuery } from "./get";

/**
 * Get everything for a dataView
 *
 * TODO: remove key: DataViewKey | BasketKey for a more generic approach
 * Use only the input, so we will have more chance to use the same selector twice
 * @author JBO
 *
 */
export const dataViewSelector = selectorFamily<
  {
    elements: KpkMedia[];
    totalCount: number;
    facets: KpkApiFacet[];
    references: References;
  },
  DataViewElementsSelector & SerializableParam
>({
  key: "DataViewSelector",
  get:
    (params) =>
    ({ get }) => {
      const elementsOut = get(dataViewElementsSelector(params));
      const facetsOut = get(dataViewFacetsSelector(params));
      const totalCountOut = get(dataViewElementsTotalCountSelector(params));
      const referencesOut = get(dataViewReferencesSelectors(params));
      // Custom out
      const { elements, facets, totalCount, references } = customSelectorInOut(
        FunctionOverrideKey.DataViewElementsOut,
        {
          key: params.key,
          elements: elementsOut,
          facets: facetsOut,
          totalCount: totalCountOut,
          references: referencesOut,
        },
        get(customerCommonOverrideAtom),
      );

      return {
        elements,
        facets,
        totalCount,
        references,
      };
    },
});

export const dataViewElementsTotalCountSelector = selectorFamily<
  number,
  DataViewElementsSelector & SerializableParam
>({
  key: "DataViewElementsTotalCountSelector",
  get:
    (params) =>
    ({ get }) => {
      const advancedSearchResult = get(getDataViewContentQuery(params));
      if (!advancedSearchResult) {
        return DATAVIEW_DEFAULT_TOTAL_COUNT;
      }
      return advancedSearchResult?.totalCount ?? 0;
    },
});

export const dataViewElementsSelector = selectorFamily<
  KpkMedia[],
  DataViewElementsSelector & SerializableParam
>({
  key: "DataViewElementsSelector",
  get:
    (params) =>
    ({ get }) => {
      const sheetFields = get(sheetFieldsSelector) ?? [];
      const advancedSearchResult = get(getDataViewContentQuery(params));

      if (advancedSearchResult?._embedded?.media) {
        return (
          advancedSearchResult._embedded.media
            .map((KpkApiMedia) =>
              convertToKpkMedia(get(formsQuerySelector), KpkApiMedia, sheetFields),
            )
            .filter((kpkMedia): kpkMedia is KpkMedia => kpkMedia !== undefined) || []
        );
      }
      return [];
    },
});

export const dataViewFacetsSelector = selectorFamily<
  KpkApiFacet[],
  DataViewElementsSelector & SerializableParam
>({
  key: "DataViewFacetsSelector",
  get:
    (params) =>
    ({ get }) => {
      const advancedSearchResult = get(getDataViewContentQuery(params));
      return advancedSearchResult?._embedded?.facet ?? [];
    },
});

export const dataViewReferencesSelectors = selectorFamily<
  References,
  DataViewElementsSelector & SerializableParam
>({
  key: "DataViewElementsSelector",
  get:
    ({ key, filters, page, filtersConfiguration, sort, size }) =>
    ({ get }) => {
      const advancedSearchResult = get(
        getDataViewContentQuery(
          serializeComplexeParamsForRecoil({
            key,
            filters,
            page,
            filtersConfiguration,
            sort,
            size,
          }),
        ),
      );
      const notFoundReferences: References["notFound"] = [];
      const foundReferences: References["found"] = [];
      const allReferences: References["all"] = [];
      if (advancedSearchResult?._embedded?.["notFoundReference"] && filters) {
        let i = 0;
        filters.forEach((f) => {
          // We don't need found and not found references for folders
          if (f.filter.type !== KpkFilterType.Folder) {
            const notFoundReferenceForFilter =
              advancedSearchResult._embedded?.["notFoundReference"][i];
            if (notFoundReferenceForFilter) {
              notFoundReferenceForFilter.forEach((r) => {
                notFoundReferences.push(r);
              });
            }
            f.filter.values.forEach((v) => {
              allReferences.push(v.label);
              if (!notFoundReferences.includes(v.label)) {
                foundReferences.push(v.label);
              }
            });
          }
          i++;
        });
      }
      const referencesOut: References = {
        notFound: notFoundReferences,
        found: foundReferences,
        all: allReferences,
      };
      return referencesOut;
    },
});
