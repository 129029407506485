import {
  KMosaicAnimationType,
  KMosaicElementSize,
  KMosaicHorizontalTextAlignType,
  KMosaicTextBackgroundType,
  KMosaicVerticalTextAlignType,
  KpkMosaicLabelCustomElement,
} from "@keepeek/refront-components";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { JsonConfig } from "../../../lib/commonPagePropsUtil";
import { getAdminConfigSectionData } from "../../../lib/config/customer-config-utils";
import logger from "../../../lib/logger-utils";
import { Mosaic } from "../../../models/configuration/components/mosaic";
import { Type } from "../../../models/configuration/definitions/widget";

import { KMosaicWidgetProps } from ".";

export function getMosaicConfiguration(
  customerSectionConfiguration: JsonConfig[],
  widgetId: string,
): KMosaicWidgetProps | undefined {
  const mosaics = getAdminConfigSectionData<Mosaic[]>(
    customerSectionConfiguration,
    CONFIGURATION_SECTION_PATH.COMPONENTS_MOSAIC,
  );

  if (mosaics?.length === undefined || mosaics.length === 0) {
    logger.info(`getMosaicConfiguration - mosaics is empty or undefined`);
    return;
  }

  const mosaic = mosaics?.find((m) => m.id === widgetId);
  if (!mosaic) {
    logger.info(`getMosaicConfiguration - can't find mosaic widget id ${widgetId}`);
    return;
  }

  return {
    title: mosaic.title || "",
    widgetId: mosaic.id,
    detailFullScreen: mosaic.detailFullScreen || false,
    size: (mosaic.size as unknown as KMosaicElementSize) || KMosaicElementSize.M,
    animationType:
      (mosaic.animation?.animationType as unknown as KMosaicAnimationType) ||
      KMosaicAnimationType.NoAnimation,
    folderIds: mosaic.folderIds ?? [],
    mosaicElements: mosaic?.mosaicElements ?? [],
    carousel: mosaic?.carousel ?? false,
    showSubTitle: mosaic?.showSubTitle ?? true,
    centeredMosaics: mosaic?.centeredMosaics ?? false,
    backgroundColor: mosaic?.backgroundColor ?? "transparent",
    content: mosaic?.content ?? [],
    borderRadius: mosaic?.borderRadius ?? "",
    labelCustom: (mosaic?.labelCustom as unknown as KpkMosaicLabelCustomElement) ?? {
      verticalTextAlign: KMosaicVerticalTextAlignType.Bottom,
      horizontalTextAlign: KMosaicHorizontalTextAlignType.Left,
      backgroundTextEffect: KMosaicTextBackgroundType.BottomEffect,
      backgroundTextColor: "#000000",
      textColor: "#FFFFFF",
    },
    type: Type.Mosaic,
  };
}
