/* eslint-disable @next/next/no-img-element */
import React from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { Box, useTheme } from "@mui/material";
import clsx from "clsx";

const WidgetCardSelectorSheet = function ({
  testId = "WidgetCardSelectorSheet",
  className,
}: BaseComponentProps) {
  const theme = useTheme();
  return (
    <Box
      className={clsx("WidgetCardSelectorSheet", className)}
      style={{
        height: "100%",
        width: "100%",
        padding: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
      }}
      data-testid={testId}
    >
      <Box style={{ backgroundColor: theme.palette.grey[300], height: "1rem", width: "33%" }} />
      <Box style={{ display: "flex", gap: theme.spacing(1) }}>
        <Box
          style={{ backgroundColor: theme.palette.primary.main, height: "1rem", width: "25%" }}
        />
        <Box
          style={{ backgroundColor: theme.palette.primary.main, height: "1rem", width: "20%" }}
        />
        <Box
          style={{ backgroundColor: theme.palette.primary.main, height: "1rem", width: "30%" }}
        />
      </Box>
      <Box style={{ display: "flex", gap: theme.spacing(1) }}>
        <Box
          style={{ backgroundColor: theme.palette.primary.main, height: "1rem", width: "30%" }}
        />
        <Box
          style={{ backgroundColor: theme.palette.primary.main, height: "1rem", width: "35%" }}
        />
        <Box
          style={{ backgroundColor: theme.palette.primary.main, height: "1rem", width: "30%" }}
        />
      </Box>
      <Box style={{ backgroundColor: theme.palette.grey[300], height: "1rem", width: "45%" }} />
      <Box style={{ display: "flex", gap: theme.spacing(1) }}>
        <Box
          style={{ backgroundColor: theme.palette.primary.main, height: "1rem", width: "50%" }}
        />
        <Box
          style={{ backgroundColor: theme.palette.primary.main, height: "1rem", width: "10%" }}
        />
      </Box>
      <Box style={{ backgroundColor: theme.palette.grey[300], height: "1rem", width: "25%" }} />
      <Box style={{ display: "flex", gap: theme.spacing(1) }}>
        <Box
          style={{ backgroundColor: theme.palette.primary.main, height: "1rem", width: "20%" }}
        />
        <Box
          style={{ backgroundColor: theme.palette.primary.main, height: "1rem", width: "33%" }}
        />
      </Box>
    </Box>
  );
};

export default WidgetCardSelectorSheet;
