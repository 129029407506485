import { formsQuerySelector, DataViewKey } from "@keepeek/commons";
import { selectorFamily } from "recoil";

import { getFieldsToDisplay } from "../../lib/field-utils";
import { configSectionPageSearchSelector } from "../config/selectors";
import { dataViewElement } from "../dataView/selectors/element";

export type DataViewElementFieldsToDisplaySelectorType = {
  functionalFieldsToDisplay: string[];
  technicalFieldsToDisplay: string[];
};

export const dataViewElementFieldsToDisplaySelector = selectorFamily<
  DataViewElementFieldsToDisplaySelectorType,
  { key: DataViewKey; id: number }
>({
  key: "DataViewElementFieldsToDisplaySelector",
  get:
    ({ key, id }) =>
    ({ get }) => {
      const element = get(dataViewElement({ key, id }));
      const forms = get(formsQuerySelector);

      const { functionalFieldsToDisplay, technicalFieldsToDisplay } = get(
        configSectionPageSearchSelector,
      ) ?? { functionalFieldsToDisplay: undefined, technicalFieldsToDisplay: undefined };

      if (!element) {
        return {
          functionalFieldsToDisplay: [],
          technicalFieldsToDisplay: [],
        };
      }

      const fields = {
        functionalFieldsToDisplay:
          (element && getFieldsToDisplay(functionalFieldsToDisplay || {}, element, forms)) || [],
        technicalFieldsToDisplay:
          (element && getFieldsToDisplay(technicalFieldsToDisplay || {}, element, forms)) || [],
      };

      return fields;
    },
});
