import React from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { Box, useTheme } from "@mui/material";
import clsx from "clsx";

import { KIcon } from "../../../../../components/common/KIcon";

const WidgetCardSelectorAttachments = function ({
  testId = "WidgetCardSelectorAttachments",
  className,
}: BaseComponentProps) {
  const theme = useTheme();

  return (
    <Box
      className={clsx("WidgetCardSelectorAttachments", className)}
      data-testid={testId}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        gap: theme.spacing(1),
        flexShrink: 1,
        padding: theme.spacing(1),
      }}
    >
      <Box
        style={{
          backgroundColor: theme.palette.grey[200],
          minHeight: theme.spacing(3),
          width: "100%",
        }}
      >
        <KIcon>link</KIcon>
      </Box>
      <Box
        style={{
          backgroundColor: theme.palette.primary.main,
          width: `calc(100% - ${theme.spacing(3)})`,
          minHeight: theme.spacing(3),
        }}
      />
      <Box
        style={{
          backgroundColor: theme.palette.primary.main,
          width: `calc(100% - ${theme.spacing(3)})`,
          minHeight: theme.spacing(3),
        }}
      />
      <Box
        style={{
          backgroundColor: theme.palette.primary.main,
          width: `calc(100% - ${theme.spacing(3)})`,
          minHeight: theme.spacing(3),
        }}
      />
    </Box>
  );
};

export default WidgetCardSelectorAttachments;
