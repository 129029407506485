import {
  KpkApiGetAdvancedSearchParams,
  KpkApiPagedResult,
  KpkApiSearchMediasEmbeddedMapping,
} from "@keepeek/api-client";
import {
  BasketKey,
  currentLocaleAtom,
  customerCommonOverrideAtom,
  customSelectorInOut,
  DataViewKey,
  fetcherModeAtom,
  FunctionOverrideKey,
  KpkFilterType,
} from "@keepeek/commons";
import { selectorFamily, SerializableParam } from "recoil";

import { getFieldsForApi, getVisualAlertsFieldsForApi } from "../../../lib/field-utils";
import {
  addTechnicalAndMandatoryFields,
  formatFieldsForKpkApi,
  formatSortValueForKpkApi,
} from "../../../lib/kpk-api-utils";
import logger from "../../../lib/logger-utils";
import { serializeComplexeParamsForRecoil } from "../../../lib/recoil-utils";
import {
  extractFacetFilters,
  extractQueryFilters,
  extractQueryFiltersForFieldParameter,
  FqType,
  hasCriterias,
} from "../../../lib/search/search-media";
import {
  configSectionComponentDownloadManagerSelector,
  configSectionPageSearchSelector,
} from "../../config/selectors";
import { jsonConfigSelector } from "../../config/selectors/init";
import { dataViewFetcherSelector } from "../../fetcher/selectors/dataview";
import { shouldRefreshGetAdvancedSearchQuery } from "../atoms";
import { DataViewElementsSelector } from "../types";
import { advancedSearchIsExpired, getBusinessFilterFullTextValue } from "../utils";
import { dataViewAdvancedSearchId } from "./create";

type GetAdvancedSearchQueryKey = {
  searchParams: KpkApiGetAdvancedSearchParams;
  shouldRefreshId: number;
} & SerializableParam;

export const getAdvancedSearchQuery = selectorFamily<
  KpkApiPagedResult<KpkApiSearchMediasEmbeddedMapping> | undefined,
  GetAdvancedSearchQueryKey
>({
  key: "GetAdvancedSearchQuery",
  get:
    ({ searchParams }) =>
    async ({ get }) => {
      // We want the advanced search to be refreshed with current locale because of facet filtering returned by the API
      get(currentLocaleAtom);
      const { getAdvancedSearch } = get(dataViewFetcherSelector(get(fetcherModeAtom)));

      if (searchParams.fq?.some((q) => q.includes(FqType.Basket))) {
        // If we're in an advanced search filtered by basket
        // We want to handle the error 400 without notification at this stage
        searchParams = {
          ...searchParams,
          axiosRequestConfig: { disableNotificationErrorCodes: [400] },
        };
      }

      const advancedSearch = await getAdvancedSearch(searchParams);
      if (!advancedSearch) {
        return undefined;
      }

      const { data } = advancedSearch;
      return data;
    },
});

export const getDataViewContentQuery = selectorFamily<
  KpkApiPagedResult<KpkApiSearchMediasEmbeddedMapping> | undefined,
  DataViewElementsSelector & SerializableParam & { customFields?: string[] }
>({
  key: "GetDataViewContent",
  get:
    ({
      page: pageIn,
      size: sizeIn,
      sort: sortIn,
      filters: filtersIn,
      customFields: customFieldsIn,
      filtersConfiguration,
      key,
      overrideFields,
      enableFacets,
    }) =>
    ({ get }) => {
      if (filtersConfiguration === null || filtersIn === null) {
        logger.debug("getDataViewContentQuery: mandatory params are undefined : do not continue", {
          filtersConfiguration,
          filtersIn,
        });
        return;
      }

      // Custom IN
      const { advancedSearch, page, size, sort, filters, customFields } = customSelectorInOut(
        FunctionOverrideKey.DataViewElementsIn,
        {
          key,
          advancedSearch: get(
            dataViewAdvancedSearchId(
              serializeComplexeParamsForRecoil({
                key,
                filters: filtersIn,
              }),
            ),
          ),
          page: pageIn,
          size: sizeIn,
          sort: sortIn,
          filters: filtersIn,
          customFields: customFieldsIn || [],
        },
        get(customerCommonOverrideAtom),
      );

      // Don't call if we don't have an advancedSearchId
      if (!advancedSearch) {
        logger.debug("GetDataViewContent : no advancedSearchId to make the call");
        return;
      }

      // Don't call if it's a basket KEY and there is not basket filter
      if (key === BasketKey.PANEL && !filters.some((f) => f.filter.type === KpkFilterType.Basket)) {
        logger.debug("GetDataViewContent : search canceled because there is no filter on basket", {
          key,
          filters,
        });
        return;
      }

      // Don't call if config is not yet ready to avoid refresh with configSectionPageSearchSelector deps
      const jsonConfig = get(jsonConfigSelector);
      if (jsonConfig === undefined) {
        logger.debug("dataViewElements : search canceled because configuration is not yet ready");
        return;
      }

      // Don't make the call if the expiration date is due
      // Wait for a new advanced search id
      if (advancedSearchIsExpired(advancedSearch?.expirationDate ?? "")) {
        logger.debug("dataViewElements : advancedSearchIsExpired");
        return;
      }

      const fields = get(dataViewFieldConstructorSelector({ key, customFields }));

      // TODO: remove "size === 1" condition when we will be sure to have stable properties accross differents call
      // at the moment: This condition enforce to have enough properties in common, to only have 1 call for differrent content in the APP
      // Content to look at for the definitive fix: totalCount, active filters, filters, stats, [more features?].
      const searchParams: KpkApiGetAdvancedSearchParams = {
        advancedSearchId: advancedSearch?.advancedSearchId ?? "",
        page,
        size,
        sort: formatSortValueForKpkApi(sort),
        fields: formatFieldsForKpkApi(size === 1 ? ["id"] : overrideFields || fields),
        q: getBusinessFilterFullTextValue(filters),
        fq: extractQueryFilters(filters),
        f: extractQueryFiltersForFieldParameter(filters), //TODO : we have to add f parameters for advanced search because of API. Hope an update to use filters criterias in advanced search...
        notFoundReference: hasCriterias(filters),
        facetFields: enableFacets || size === 1 ? extractFacetFilters(filtersConfiguration) : [],
      };

      const shouldRefreshId = get(shouldRefreshGetAdvancedSearchQuery(key));
      const advancedSearchResult = get(
        getAdvancedSearchQuery(
          serializeComplexeParamsForRecoil({
            searchParams,
            shouldRefreshId,
          }),
        ),
      );

      return advancedSearchResult;
    },
});

export const dataViewFieldConstructorSelector = selectorFamily<
  string[],
  { key: DataViewKey | BasketKey; customFields: string[] }
>({
  key: "DataViewFieldSelector",
  get:
    ({ key, customFields = [] }) =>
    ({ get }) => {
      const customerConfigSectionPageSearch = get(configSectionPageSearchSelector);
      const { enableOtherFormats } = get(configSectionComponentDownloadManagerSelector) ?? {
        enableOtherFormats: undefined,
      };
      if (enableOtherFormats) {
        customFields = [...customFields, "attachmentCounts"];
      }
      const fields = addTechnicalAndMandatoryFields([
        ...getFieldsForApi(
          customerConfigSectionPageSearch?.technicalFieldsToDisplay ?? {},
          key && key === BasketKey.PANEL,
        ),
        ...getFieldsForApi(customerConfigSectionPageSearch?.functionalFieldsToDisplay ?? {}),
        ...getVisualAlertsFieldsForApi(customerConfigSectionPageSearch?.visualAlerts ?? []),
        ...customFields,
        ...(enableOtherFormats ? ["mediaLinkCount"] : []),
      ]);

      return fields;
    },
});
