import { KpkApiResource } from "@keepeek/api-client";
import { UIOptionsType } from "@rjsf/utils";

import { Resource } from "../../../../providers/resource/hooks";

/**
 * API Resource nature corresponding to a folder name in BO with all refront resources
 */
export const API_RESOURCE_NATURE: string = "reFront";

export enum LoadingStatus {
  LOADING = "loading",
  LOADED = "loaded",
  ERROR = "error",
  NONE = "none",
}

export enum ResourceType {
  VIDEO = "video",
  IMAGE = "image",
  LOGO = "logo",
  UNKNOWN = "unknown",
}

export const convertToKpkResources = (kpkApiResources: KpkApiResource[]): Resource[] => {
  const result: Resource[] = [];
  kpkApiResources.forEach(async (r) => {
    const resource: Resource = { id: r.id, fileName: r.fileName };
    result.push(resource);
  });
  return result;
};

export const getResourceType = (uiOptions: UIOptionsType): ResourceType => {
  if (!uiOptions.type) {
    return ResourceType.UNKNOWN;
  }
  if (typeof uiOptions.type === "string" && uiOptions.type.trim().toLowerCase() === "image") {
    return ResourceType.IMAGE;
  } else if (typeof uiOptions.type === "string" && uiOptions.type.trim().toLowerCase() === "logo") {
    return ResourceType.LOGO;
  } else if (
    typeof uiOptions.type === "string" &&
    uiOptions.type.trim().toLowerCase() === "video"
  ) {
    return ResourceType.VIDEO;
  } else {
    return ResourceType.UNKNOWN;
  }
};
