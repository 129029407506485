import React, { FC, useEffect, useMemo } from "react";

import { DataViewKey, isClientSide, useShareToken } from "@keepeek/commons";
import { ElementSummary, withSuspense } from "@keepeek/refront-components";
import { LinearProgress } from "@mui/material";
import { useRouter } from "next/router";
import { useRecoilValue } from "recoil";

import { KGrid } from "../../../../components/refront-components-overrides";
import { activeBusinessFiltersFromURLSelector } from "../../../../providers/businessFilter/selectors/activeFilters";
import { dataViewSize } from "../../../../providers/dataView/atoms";
import { useDataView } from "../../../../providers/dataView/hooks/useDataView";
import { useKeyboardSelection } from "../../../../providers/dataView/hooks/useKeyboardSelection";
import MemoizedElementSummary from "./MemoizedElementSummary";

const MosaicDataView: FC<React.PropsWithChildren<unknown>> = function () {
  const filters = useRecoilValue(activeBusinessFiltersFromURLSelector);
  const { elements } = useDataView({ key: DataViewKey.SEARCH, filters });
  useKeyboardSelection(DataViewKey.SEARCH, elements);
  const size = useRecoilValue(dataViewSize(DataViewKey.SEARCH));
  const sizeArray = useMemo(
    () => Array(Math.min(size, elements.length)).fill(null),
    [elements.length, size],
  );
  const router = useRouter();

  // Client scroll on element
  useEffect(() => {
    const hasHash = router.asPath.split("#element-");
    if (hasHash.length === 2 && isClientSide()) {
      const elementId = hasHash[1];
      const domEl = document.getElementById(`element-${elementId}`);
      if (domEl?.offsetTop) {
        window.scrollTo({ top: domEl?.offsetTop, behavior: "smooth" });
      }
    }
  }, [router.asPath]);

  const { isShare, shareTokenDownloadAuthorized } = useShareToken(
    router.pathname,
    router.query.tokenId,
  );

  return (
    <KGrid id="MosaicDataView">
      {sizeArray.map((_p, i) => (
        <li id={`element-${i}`} key={i}>
          <MemoizedElementSummary
            id={elements && elements.length > i ? elements[i].id : 0}
            isShare={isShare}
            shareTokenDownloadAuthorized={shareTokenDownloadAuthorized}
          />
        </li>
      ))}
    </KGrid>
  );
};

export const MosaicDataViewLoading: FC<React.PropsWithChildren<unknown>> = function () {
  const size = useRecoilValue(dataViewSize(DataViewKey.SEARCH));
  return (
    <>
      <LinearProgress color={"primary"} />
      <KGrid id="MosaicDataView" className={"MosaicDataView-KGrid-loading"}>
        {Array(size)
          .fill(null)
          .map((_p, i) => (
            <li key={i}>
              <ElementSummary loading className={"MosaicDataView-ElementSummary-loading"} />
            </li>
          ))}
      </KGrid>
    </>
  );
};

export default withSuspense(MosaicDataView, { LoadingComponent: <MosaicDataViewLoading /> });
