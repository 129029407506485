import { KpkMedia } from "@keepeek/commons";
import { selector, selectorFamily } from "recoil";

import { configSectionShareManagerSelector } from "../../config/selectors";
import { shareManagerSelectionsState } from "../atoms/selection";
import {
  ShareManagerSelection,
  ShareManagerSelectionResolved,
  ShareManagerSelectionType,
} from "../models";
import { shareBasketsSelector } from "./shareManagerBasketSelectors";
import { shareUnitaryPublicSharesSelector } from "./shareManagerUnitaryPublicShareSelectors";

export const shareManagerSelectionsSelector = selectorFamily<
  ShareManagerSelectionResolved[],
  ShareManagerSelection[]
>({
  key: "ShareManagerSelectionsSelector",
  get:
    (selections) =>
    ({ get }) => {
      const basketTypeElements = selections
        .filter((s) => s.type === ShareManagerSelectionType.BASKET)
        .flatMap((s) => s.elements.map((e) => e.id));
      const elementTypeElements = selections
        .filter((s) => s.type === ShareManagerSelectionType.UNITARY_PUBLIC_SHARE)
        .flatMap((s) => s.elements);

      const selectionsResolved: ShareManagerSelectionResolved[] = [];

      let basketsSelection: ShareManagerSelectionResolved | undefined;
      if (basketTypeElements.length > 0) {
        basketsSelection = {
          type: ShareManagerSelectionType.BASKET,
          resolved: get(shareBasketsSelector(basketTypeElements)),
        };
        selectionsResolved.push(basketsSelection);
      }

      let elementsSelection: ShareManagerSelectionResolved | undefined;
      // Is the unitary public share activated ?
      const unitaryPublicShareActive =
        get(configSectionShareManagerSelector)?.unitaryPublicShare?.active ?? false;
      if (elementTypeElements.length > 0 && unitaryPublicShareActive) {
        elementsSelection = {
          type: ShareManagerSelectionType.UNITARY_PUBLIC_SHARE,
          resolved: get(shareUnitaryPublicSharesSelector(elementTypeElements)),
        };
        selectionsResolved.push(elementsSelection);
      }

      return selectionsResolved;
    },
});

export const shareManagerSelectionsIdsSelector = selector<KpkMedia["id"][]>({
  key: "ShareManagerSelectionsIdsSelector",
  get: ({ get }) => {
    const selections = get(shareManagerSelectionsState);
    return selections.map((s) => s.elements.map((e) => e.id)).flat();
  },
});
