// When adding the selection to the download manager selected elements

import {
  directExportNumberLimitSelector,
  fetcherModeAtom,
  maxElementsByDownloadSelector,
  isVisitorState,
} from "@keepeek/commons";
import { selector } from "recoil";

import {
  downloadManagerOpenState,
  DownloadManagerOpenStatus,
  downloadManagerOpenStatusState,
  downloadManagerSelectedElementsState,
} from "../atoms/selections";
import { isMaxReachedAnonymous } from "../utils";

// Check some rules before opening the download manager
export const downloadManagerSelectionSelector = selector<number[]>({
  key: "DownloadManagerSelectionSelector",
  get: ({ get }) => get(downloadManagerSelectedElementsState),
  set: ({ get, set }, newValue: number[]) => {
    let state = true;
    let status = DownloadManagerOpenStatus.ALLOWED;
    // Rules:
    // The number of element should not exceed the number downloadable by one call
    const maxElements = get(maxElementsByDownloadSelector);
    const directExportNumberLimit = get(directExportNumberLimitSelector);
    const fetcherMode = get(fetcherModeAtom);
    const isVisitor = get(isVisitorState);
    if (isMaxReachedAnonymous(newValue.length, fetcherMode, directExportNumberLimit, isVisitor)) {
      status = DownloadManagerOpenStatus.MAX_REACHED_ANONYMOUS;
      state = false;
    } else if (newValue.length > maxElements) {
      status = DownloadManagerOpenStatus.MAX_REACHED;
      state = false;
    }
    // Set the selection only if allowed to avoid calling Back End for nothing
    if (status === DownloadManagerOpenStatus.ALLOWED) {
      set(downloadManagerSelectedElementsState, newValue);
    }
    set(downloadManagerOpenStatusState, status);
    set(downloadManagerOpenState, state);
  },
});
