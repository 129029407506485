import { KpkApiAttachment, KpkApiAttachmentCloudProviderState } from "@keepeek/api-client";
import {
  KpkAttachment,
  KpkAttachmentMetaDate,
  KpkAttachmentMetaDateType,
  KpkAttachmentMetaSize,
  KpkAttachmentMetaType,
} from "@keepeek/commons";

import { getNormalizeFileSize } from "../size-util";

/**
 * Convert a KpkApiAttachment to KpkAttachment
 * @param kpkApiAttachment kpk api attachment to convert
 * @param locale data locale to use
 * @param filterOnline true to only return online attachments
 */
export const convertToKpkAttachments = (
  kpkApiAttachment: KpkApiAttachment[] | undefined,
  locale: string,
  filterOnline: boolean,
): KpkAttachment[] => {
  const attachments: KpkAttachment[] = [];
  if (kpkApiAttachment) {
    for (const apiAttachment of kpkApiAttachment) {
      if (
        filterOnline &&
        apiAttachment.cloudProviderState !== KpkApiAttachmentCloudProviderState.Online
      ) {
        continue;
      }
      if (apiAttachment.id && apiAttachment.originalFilename && apiAttachment.url) {
        const metas: Array<KpkAttachmentMetaSize | KpkAttachmentMetaDate> = [];
        if (apiAttachment.fileSize) {
          const size = getNormalizeFileSize(apiAttachment.fileSize);
          if (size) {
            metas.push({
              type: KpkAttachmentMetaType.Size,
              size: size.size,
              unit: size.unitLabel,
            });
          }
        }
        if (apiAttachment.updateDate && apiAttachment.updateUserId) {
          metas.push({
            type: KpkAttachmentMetaType.Date,
            date: apiAttachment.updateDate,
            dateType: KpkAttachmentMetaDateType.Updated,
            userId: apiAttachment.updateUserId,
          });
        } else if (apiAttachment.creationDate && apiAttachment.creationUserId) {
          metas.push({
            type: KpkAttachmentMetaType.Date,
            date: apiAttachment.creationDate,
            dateType: KpkAttachmentMetaDateType.Created,
            userId: apiAttachment.creationUserId,
          });
        }

        const defaultLocale = "en";
        const titleInCurrentLocale = apiAttachment.title?.find(
          (t) => t.lang?.toLowerCase() === locale.toLowerCase(),
        );
        const titleInDefaultLocale = apiAttachment.title?.find(
          (t) => t.lang?.toLowerCase() === defaultLocale,
        );
        const descriptionInCurrentLocale = apiAttachment.description?.find(
          (d) => d.lang?.toLowerCase() === locale.toLowerCase(),
        );
        const descriptionInDefaultLocale = apiAttachment.description?.find(
          (d) => d.lang?.toLowerCase() === defaultLocale,
        );
        attachments.push({
          id: apiAttachment.id,
          originalFilename: apiAttachment.originalFilename,
          title: titleInCurrentLocale?.value || titleInDefaultLocale?.value || "",
          url: apiAttachment.url,
          description: descriptionInCurrentLocale?.value || descriptionInDefaultLocale?.value || "",
          metas,
        });
      }
    }
  }
  return attachments;
};
