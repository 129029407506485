import React, { useEffect, useState } from "react";

import { Box, Container, Paper, Typography } from "@mui/material";
import { Field, getUiOptions } from "@rjsf/utils";
import "filepond/dist/filepond.min.css";
import { FilePondErrorDescription, FilePondFile } from "filepond";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import { useSnackbar } from "notistack";
import { FilePond, registerPlugin } from "react-filepond";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { useFileResource } from "../../../../providers/resource/hooks";
import logger from "../../../logger-utils";
import ExtensionList from "./components/ExtensionList";
import LoadingInformation from "./components/LoadingInformation";
import ResourceRenderer from "./components/ResourceRenderer";
import { API_RESOURCE_NATURE, getResourceType, LoadingStatus, ResourceType } from "./utils";

/**
 * Custom field to display a resource selector in any admin page
 */
const CustomSelectResource: Field = function ({ schema, uiSchema, formData, onChange }) {
  const { t } = useTranslation();
  const resourceType: ResourceType = getResourceType(getUiOptions(uiSchema));
  registerPlugin(FilePondPluginFileValidateSize);
  const [resourceUploadState, setResourceUploadState] = useState<LoadingStatus>(LoadingStatus.NONE);
  const { enqueueSnackbar } = useSnackbar();
  const { createResource, replaceResource } = useFileResource();

  const [currentResourceUrl, setCurrentResourceUrl] = useState<string>(formData?.url);
  const [currentResourceId, setCurrentResourceId] = useState<string>(formData?.id);

  useEffect(() => {
    if (formData) {
      setCurrentResourceUrl(formData.url);
      setCurrentResourceId(formData.id);
    }
  }, [formData, formData?.id, formData?.url]);

  async function handleAddFile(file: FilePondFile): Promise<{
    id: string;
    url: string;
  }> {
    if (!file) {
      return Promise.reject();
    }
    if (!currentResourceUrl) {
      const myUuid = uuidv4();
      let id = file.filenameWithoutExtension.replace(/[^a-zA-Z0-9]/g, "");
      id = id.concat("_").concat(myUuid.substring(0, 5));
      const url = await createResource(file.file, API_RESOURCE_NATURE, id);
      if (url) {
        setResourceUploadState(LoadingStatus.LOADED);
        enqueueSnackbar(t("admin:resource.create.success"), {
          variant: "success",
        });
        return { id, url };
      } else {
        setResourceUploadState(LoadingStatus.ERROR);
        return Promise.reject();
      }
    } else {
      const url = await replaceResource(file.file, API_RESOURCE_NATURE, currentResourceId);
      if (url) {
        setResourceUploadState(LoadingStatus.LOADED);
        enqueueSnackbar(t("admin:resource.replace.success"), {
          variant: "success",
        });
        return { id: currentResourceId, url };
      } else {
        setResourceUploadState(LoadingStatus.ERROR);
        return Promise.reject();
      }
    }
  }

  const handleResourceRemove = (): void => {
    onChange({ id: "", url: "" });
  };
  const hideResourceRemoveButton = getUiOptions(uiSchema).hideResourceRemoveButton === true;

  return (
    <Container component={Paper}>
      <Typography variant="h6">
        {schema.title ? t(schema.title) : t("admin:labels.translation.resource.label")}
      </Typography>
      <ResourceRenderer
        resourceType={resourceType}
        resourceUrl={currentResourceUrl}
        onResourceRemove={handleResourceRemove}
        hideResourceRemoveButton={hideResourceRemoveButton}
      />
      <ExtensionList resourceType={resourceType} />
      <LoadingInformation status={resourceUploadState} />
      <Box
        sx={{
          ...((resourceUploadState === LoadingStatus.LOADING ||
            resourceUploadState === LoadingStatus.LOADED) && { display: "none" }),
          paddingBottom: 0.5,
        }}
      >
        <FilePond
          onaddfilestart={() => setResourceUploadState(LoadingStatus.LOADING)}
          allowMultiple={false}
          name="files"
          maxFileSize={"50MB"}
          labelMaxFileSizeExceeded={t("admin:resource.upload.error.maxFileSizeExceeded") as string}
          labelMaxFileSize={t("admin:resource.upload.error.maxFileSize") as string}
          labelIdle={t("admin:resource.upload.label") as string}
          onaddfile={async (error: FilePondErrorDescription | null, file: FilePondFile) => {
            if (error === null) {
              const resource = await handleAddFile(file);
              if (resource) {
                onChange({ id: resource.id, url: resource.url });
              }
            } else {
              setResourceUploadState(LoadingStatus.ERROR);
            }
          }}
          onerror={(status, file) => {
            logger.error(
              "Error while loading file ",
              file?.filename,
              " with error code ",
              status.code,
            );
          }}
          credits={false}
        />
      </Box>
    </Container>
  );
};

export default CustomSelectResource;
