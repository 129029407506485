import { BusinessFilter, BusinessFilterNature, DataViewKey, MediaField } from "@keepeek/commons";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { dataViewPage, selectedElements } from "../../dataView/atoms";
import { getBusinessFilterFirstValue } from "../../dataView/utils";
import { activeBusinessFiltersFromURLSelector } from "../selectors/activeFilters";
import { BusinessFilterExtraParams } from "../types/url";
import { createFolderBusinessFilter } from "../utils/create/createFolderBusinessFilter";
import { useLBF } from "./useLBF";

/**
 * Update the filters with active filter connector (currently: LBF)
 * Receive the activeFilters at the same time for convenience
 * @author JBO
 */
export const useActiveFilters = (): {
  activeFilters: BusinessFilter[];
  setActiveFilters: (filters: BusinessFilter[], extraParams?: BusinessFilterExtraParams) => void;
  resetFiltersExceptFolder: () => void;
} => {
  const { setLBF } = useLBF();
  const setPage = useSetRecoilState(dataViewPage(DataViewKey.SEARCH));
  const activeFilters = useRecoilValue(activeBusinessFiltersFromURLSelector);
  const setSelectedElements = useSetRecoilState(selectedElements(DataViewKey.SEARCH));
  const { t } = useTranslation();

  /**
   * Update active filters trough current active filters connector
   * At the moment, we're using LBF
   * @author JBO
   *
   * @param {BusinessFilter[]} filters
   */
  const setActiveFilters = (filters: BusinessFilter[], extraParams?: BusinessFilterExtraParams) => {
    setLBF(filters, extraParams);
    // reset page 1 on filter change :
    // TODO : setPage has to be move to manage genericity : NO DATAVIEW CONCEPT IN FILTERS
    setPage(1);
    // Reset selected items when change filters
    setSelectedElements([]);
  };

  /**
   * Reset all active filters to none, except the folder filter
   * @author CRO
   */
  const resetFiltersExceptFolder = () => {
    const filterFolder = getBusinessFilterFirstValue(
      activeFilters,
      BusinessFilterNature.ADVANCED,
      MediaField.FOLDER_ID,
    );
    if (filterFolder) {
      setActiveFilters([
        createFolderBusinessFilter(
          filterFolder.id,
          filterFolder.label,
          t("business-filter.advanced.folder.label"),
        ),
      ]);
    } else {
      setActiveFilters([]);
    }
  };

  return { activeFilters, setActiveFilters, resetFiltersExceptFolder };
};
