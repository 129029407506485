import React, { FC, useEffect } from "react";

import { KButton, KButtonProps } from "@keepeek/refront-components";
import { useTheme } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { animated, useSpring } from "react-spring";

import { KIcon } from "../common/KIcon";

type ShowAttachmentButton = {
  attachmentsCount: number;
} & KButtonProps;

const ShowAttachmentButton: FC<React.PropsWithChildren<ShowAttachmentButton>> = function ({
  className,
  paletteColor,
  attachmentsCount,
  onClick,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const attachmentsCountFontSize =
    attachmentsCount > 99 ? "0.6rem" : theme.typography.caption.fontSize || "0.75rem";

  const [pop, popApi] = useSpring(() => ({
    scale: 1,
  }));
  const attachmentsAnimatedCount = useSpring({ count: attachmentsCount });

  const attachmentsCurrentCount = attachmentsAnimatedCount.count.to((x) =>
    x > 99 ? "99+" : x.toFixed(0),
  );

  useEffect(() => {
    popApi.start({
      to: async (next) => {
        await next({ scale: 1.5 });
        await next({ scale: 1 });
      },
      config: {
        duration: theme.transitions.duration.shortest,
      },
    });
    return () => {
      popApi.start({ scale: 1 });
    };
  }, [attachmentsCount, popApi, theme.transitions.duration.shortest]);

  return (
    <KButton
      style={{
        marginLeft: theme.spacing(1),
      }}
      size="large"
      paletteColor={paletteColor}
      className={clsx("ShowAttachmentButton", className)}
      variant="outlined"
      onClick={onClick}
      aria-label={t("media-detail.action.show-attachment.label") as string}
      title={t("media-detail.action.show-attachment.label") as string}
      sx={{
        "& .ShowAttachmentButton-Animated": {
          border: "1px solid",
          borderColor: "primary.main",
          position: "absolute",
          height: (theme) => theme.spacing(2.5),
          width: (theme) => theme.spacing(2.5),
          maxWidth: "6vh",
          maxHeight: "6vh",
          right: (theme) => theme.spacing(-0.5),
          top: (theme) => theme.spacing(-0.5),
          backgroundColor: "background.default",
          borderRadius: "50%",
          color: "primary.main",
          fontSize: attachmentsCountFontSize,
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
    >
      <KIcon fontSize="small">attachment</KIcon>&nbsp;
      <animated.div style={pop} className="ShowAttachmentButton-Animated">
        {attachmentsCurrentCount}
      </animated.div>
    </KButton>
  );
};

export default ShowAttachmentButton;
