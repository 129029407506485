import { buildThemeWithLegacyOptions } from "@keepeek/refront-components";
import { CustomerAppTheme } from "@keepeek/refront-customers";
import { Theme } from "@mui/material";
import { ThemeOptions } from "@mui/material/styles/createTheme";
import { deepmerge } from "@mui/utils";
import { AnyColor, Colord, colord, extend } from "colord";
import namesPlugin from "colord/plugins/names";
import * as CSS from "csstype";
import cloneDeep from "lodash/cloneDeep";
import { useRecoilValue } from "recoil";

import { CONFIGURATION_SECTION_PATH } from "../components/layout/adminMenu";
import { Theme as AdminThemeConfiguration } from "../models/configuration/theme";
import { useConfiguration } from "../providers/config/hooks";
import { customerAppThemeSelector } from "../providers/config/selectors/init";
import { frontEditState } from "../providers/frontEdit/atoms/frontEdit";
import { getCurrentContentEditsSelector } from "../providers/frontEdit/selectors/currentContentEdits";
import logger from "./logger-utils";
import { useThemeTemplates } from "./theme-templates-utils";
// eslint-disable-next-line jest/require-hook
extend([namesPlugin]);

export function isValidColor(color: AnyColor | Colord) {
  return colord(color)?.isValid();
}

export function toHexColor(color: AnyColor | Colord) {
  return colord(color)?.toHex();
}

const buildCustomerTheme = (
  customerAppTheme: CustomerAppTheme | undefined,
  adminThemeConfiguration: AdminThemeConfiguration | undefined,
): Theme => {
  const { action, background, primary, secondary, error, text, borderRadius, extendThemeOptions } =
    adminThemeConfiguration ?? {};
  const adminThemeOptions: ThemeOptions = {
    palette: {
      ...(background &&
        background.default &&
        isValidColor(background.default) && {
          background: {
            default: toHexColor(background.default),
            paper:
              background.paper && isValidColor(background.paper)
                ? toHexColor(background.paper)
                : undefined,
          },
        }),

      ...(primary &&
        primary.main &&
        isValidColor(primary.main) && {
          primary: {
            main: toHexColor(primary.main),
            light:
              primary.light && isValidColor(primary.light) ? toHexColor(primary.light) : undefined,
          },
        }),

      ...(secondary &&
        secondary.main &&
        isValidColor(secondary.main) && {
          secondary: {
            main: toHexColor(secondary.main),
          },
        }),

      ...(error &&
        error.main &&
        isValidColor(error.main) && {
          error: {
            main: toHexColor(error.main),
          },
        }),

      ...(text &&
        (text.primary || text.secondary || text.disabled || text.hint) && {
          text: {
            ...(text.primary &&
              isValidColor(text.primary) && {
                primary: toHexColor(text.primary),
              }),
            ...(text.secondary &&
              isValidColor(text.secondary) && {
                secondary: toHexColor(text.secondary),
              }),
            ...(text.disabled &&
              isValidColor(text.disabled) && {
                disabled: toHexColor(text.disabled),
              }),
            ...(text.hint &&
              isValidColor(text.hint) && {
                hint: toHexColor(text.hint),
              }),
          },
        }),

      ...(action &&
        action.hover &&
        isValidColor(action.hover) && {
          action: {
            hover: toHexColor(action.hover),
          },
        }),
    },
    shape: {
      ...(borderRadius && { borderRadius }),
    },
    // historical overrides... i don't know why :
    components: {
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: ".25rem",
          },
        },
      },
    },
  };

  let themeOptions = deepmerge<ThemeOptions>(
    customerAppTheme?.materialThemeOptions ?? {},
    adminThemeOptions,
  );
  // are there overloads of the theme from the admin front?
  if (extendThemeOptions) {
    try {
      themeOptions = deepmerge<ThemeOptions>(themeOptions, JSON.parse(extendThemeOptions));
    } catch (error) {
      logger.error(`buildCustomerTheme - extendThemeOptions is invalid to parse: ${error}`);
    }
  }
  try {
    return buildThemeWithLegacyOptions(themeOptions);
  } catch (error) {
    logger.error("buildCustomerTheme - extendThemeOptions is invalid", error);
  }
  return buildThemeWithLegacyOptions();
};

/**
 * Hook allowing to return a theme, a theme without font and only the fonts
 */
export type UseCustomerTheme = {
  theme: Theme;
  themeWithoutCustomFonts: Theme;
  customFonts: CSS.AtRule.FontFace[] | undefined;
};
export const useCustomerTheme = (): UseCustomerTheme => {
  const customerTheme = useRecoilValue(customerAppThemeSelector);
  let adminThemeConfiguration = useConfiguration<AdminThemeConfiguration>(
    CONFIGURATION_SECTION_PATH.THEME,
  );

  const frontEdit = useRecoilValue(frontEditState);
  const currentContentEdit = useRecoilValue(
    getCurrentContentEditsSelector({
      endpoint: `${CONFIGURATION_SECTION_PATH.THEME}`,
    }),
  );
  const currentEditData =
    currentContentEdit && currentContentEdit.data ? currentContentEdit.data : customerTheme;

  if (frontEdit && currentEditData) {
    const { background, primary, secondary, error, text } = adminThemeConfiguration || {};
    const { default: defaultBg, paper: paperBg } = currentEditData.background || {};
    const { main: primaryMain, light: primaryLight } = currentEditData.primary || {};
    const { main: secondaryMain } = currentEditData.secondary || {};
    const { main: errorMain } = currentEditData.error || {};
    const {
      primary: textPrimary,
      secondary: textSecondary,
      disabled: textDisabled,
      hint: textHint,
    } = currentEditData.text || {};

    adminThemeConfiguration = {
      ...adminThemeConfiguration,
      background: {
        ...background,
        default: isValidColor(defaultBg) ? toHexColor(defaultBg) : background?.default,
        paper: isValidColor(paperBg) ? toHexColor(paperBg) : background?.paper,
      },
      primary: {
        ...primary,
        main: isValidColor(primaryMain) ? toHexColor(primaryMain) : primary?.main,
        light: isValidColor(primaryLight) ? toHexColor(primaryLight) : primary?.light,
      },
      secondary: {
        ...secondary,
        main: isValidColor(secondaryMain) ? toHexColor(secondaryMain) : secondary?.main,
      },
      error: {
        ...error,
        main: isValidColor(errorMain) ? toHexColor(errorMain) : error?.main,
      },
      text: {
        ...text,
        primary: isValidColor(textPrimary) ? toHexColor(textPrimary) : text?.primary,
        secondary: isValidColor(textSecondary) ? toHexColor(textSecondary) : text?.secondary,
        disabled: isValidColor(textDisabled) ? toHexColor(textDisabled) : text?.disabled,
        hint: isValidColor(textHint) ? toHexColor(textHint) : text?.hint,
      },
    };
  }

  const theme = buildCustomerTheme(customerTheme, adminThemeConfiguration);
  const { themeTemplates } = useThemeTemplates(theme);

  const themeWithTemplatesOverrides = deepmerge(theme, themeTemplates);

  let customFonts: CSS.AtRule.FontFace[] | undefined;
  const themeWithoutCustomFonts = cloneDeep(theme);
  if (theme.components?.MuiCssBaseline?.styleOverrides?.hasOwnProperty("@font-face")) {
    const tempFonts = theme?.components?.MuiCssBaseline?.styleOverrides?.["@font-face"];
    customFonts = Array.isArray(tempFonts) ? tempFonts : [tempFonts];
    delete themeWithoutCustomFonts?.components?.MuiCssBaseline?.styleOverrides?.["@font-face"];
  }
  return {
    theme: themeWithTemplatesOverrides,
    themeWithoutCustomFonts,
    customFonts,
  };
};
