import {
  KpkApiAdvancedSearchCriteria,
  KpkApiAdvancedSearchFilter,
  KpkApiAdvancedSearchFilterFieldType,
  KpkApiAdvancedSearchFilterModifier,
  KpkApiAdvancedSearchFilterType,
  KpkApiFacet,
} from "@keepeek/api-client";
import {
  BusinessFilter,
  BusinessFilterNature,
  FunctionOverrideKey,
  FunctionOverrideReturn,
  KpkFilter,
  KpkFilterType,
  KpkMedia,
  MediaField,
} from "@keepeek/commons";

import { BusinessFilterFilter, Type } from "../../models/configuration/components/businessFilter";
import logger from "../logger-utils";
import { getCustomerOverrideFunction } from "../overrides";

export type SearchId = null | {
  advancedSearchId: string | null;
  expirationDate: string | undefined;
};

export type ParsedSearchResult = {
  medias: KpkMedia[];
  facets: Array<KpkApiFacet>;
  totalCount: number;
  searchId: SearchId;
};

export enum FqType {
  Facet = "facet:",
  Basket = "basketId:",
}
export const FOLDER_FACET_ID = "folderName";
export const MINUTES_UNIT = "m";

function adaptDuration(id: number, label: string): number {
  let newId = id;
  if (label.includes(MINUTES_UNIT)) {
    newId = id * 60;
  }
  return newId;
}

export const convertToKpkApiAdvancedSearchFilter = (
  filter: KpkFilter,
  criterias: KpkApiAdvancedSearchCriteria[],
): KpkApiAdvancedSearchFilter | null => {
  // Try to find if this function has been overridden :
  const customFunction: FunctionOverrideReturn[FunctionOverrideKey.ConvertToKpkApiAdvancedSearchFilter] =
    getCustomerOverrideFunction<FunctionOverrideKey.ConvertToKpkApiAdvancedSearchFilter>(
      FunctionOverrideKey.ConvertToKpkApiAdvancedSearchFilter,
      { filter },
    );
  if (customFunction) {
    return customFunction;
  }

  const criteria = criterias.find((c) => c.internalFieldName === filter.id);
  if (!criteria || !criteria.fieldType || !criteria.type) {
    logger.info(
      `No data from API for advanced filter ${filter.id} - ${criteria}`,
      filter,
      criteria,
    );
    return null;
  }
  if (!filter.modifiers) {
    logger.warn(`Advanced filter modifiers is empty ${filter.id}`, filter);
    return null;
  }
  const modifier = filter.modifiers.find((m) => m.selected);
  if (!modifier) {
    logger.warn(`Advanced filter have no selected modifier ${filter.id}`, filter);
    return null;
  }
  const convertedModifier = modifier.type as unknown as KpkApiAdvancedSearchFilterModifier;
  if (!convertedModifier) {
    logger.warn(`Advanced filter modifier can't be converted to API modifier ${filter.id}`, filter);
    return null;
  }
  let values;
  if (filter.type === KpkFilterType.Radio) {
    values = filter.values.filter((v) => v.selected).map((v) => v.id);
  } else if (filter.type === KpkFilterType.Duration) {
    values = filter.values.map((v) => adaptDuration(v.id, v.label));
  } else {
    values = filter.values.map((v) => v.id);
  }
  return {
    internalFieldName: filter.internalName ?? filter.id,
    fieldType: criteria.fieldType as unknown as KpkApiAdvancedSearchFilterFieldType,
    showSub: filter.type === KpkFilterType.Folder,
    values,
    modifier: convertedModifier,
    type: criteria.type as unknown as KpkApiAdvancedSearchFilterType,
  };
};

export function extractAdvancedFilters(
  filters: Array<BusinessFilter>,
  criterias: KpkApiAdvancedSearchCriteria[],
): KpkApiAdvancedSearchFilter[] {
  const ret: KpkApiAdvancedSearchFilter[] = [];
  if (filters.length === 0) {
    return ret;
  }
  filters.forEach((filter) => {
    if (
      filter.nature === BusinessFilterNature.ADVANCED ||
      filter.nature === BusinessFilterNature.FACET_MULTI
    ) {
      const kpkApiAdvancedFilter = convertToKpkApiAdvancedSearchFilter(filter.filter, criterias);
      if (kpkApiAdvancedFilter) {
        ret.push(kpkApiAdvancedFilter);
      }
    }
  });

  return ret;
}

export function hasCriterias(filters?: BusinessFilter[]): boolean {
  if (!filters || filters.length === 0) {
    return false;
  }
  return filters.some(
    (f) => f.nature === BusinessFilterNature.ADVANCED && f.filter?.values?.length > 0,
  );
}

export function extractQueryFiltersForFieldParameter(filters?: BusinessFilter[]): string[] {
  if (!filters || filters.length === 0) {
    return [];
  }

  return filters.reduce((acc: string[], f: BusinessFilter) => {
    switch (f.nature) {
      case BusinessFilterNature.ADVANCED: {
        if (f.filter && f.filter.values && f.filter.type === KpkFilterType.Text) {
          f.filter.values.forEach((v) => {
            acc.push(`${f.filter.id}:${v.label}`);
          });
        }
        break;
      }
      default:
        break;
    }
    return acc;
  }, []);
}

export function extractFacetFilters(filters?: BusinessFilterFilter[]): string[] {
  if (!filters || filters.length === 0) {
    return [];
  }

  return filters.reduce((acc: string[], f: BusinessFilterFilter) => {
    if (f.filters && (f.filters?.length ?? 0) > 0) {
      for (const filter of f.filters) {
        switch (filter.type) {
          case Type.FacetMulti:
          case Type.Facet: {
            if (filter.name) {
              if (filter.name === MediaField.FOLDER_ID) {
                acc.push(FOLDER_FACET_ID);
              } else {
                acc.push(filter.name);
              }
            }
            break;
          }
          default:
            break;
        }
      }
    } else {
      switch (f.type) {
        case Type.FacetMulti:
        case Type.Facet: {
          if (f.name) {
            if (f.name === MediaField.FOLDER_ID) {
              acc.push(FOLDER_FACET_ID);
            } else {
              acc.push(f.name);
            }
          }
          break;
        }
        default:
          break;
      }
    }
    return acc;
  }, []);
}

export function extractQueryFilters(filters?: BusinessFilter[]): string[] {
  if (!filters || filters.length === 0) {
    return [];
  }

  return filters.reduce((acc: string[], f: BusinessFilter) => {
    switch (f.nature) {
      case BusinessFilterNature.FACET: {
        const facetId = f.filter.id;
        f.filter.values
          .filter((f) => f.selected)
          .forEach((v) => {
            const facetValue = v.id;
            acc.push(
              `${FqType.Facet}${
                f.filter.internalName ? f.filter.internalName : facetId
              }:${facetValue}`,
            );
          });
        break;
      }
      case BusinessFilterNature.BASKET: {
        const basketId = f.filter.values[0].id;
        acc.push(`${FqType.Basket}${basketId}`);
        break;
      }
      default:
        break;
    }

    return acc;
  }, []);
}
