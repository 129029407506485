import { BasketKey, DataViewKey, KpkMedia } from "@keepeek/commons";
import { atom, atomFamily } from "recoil";

import { SpecificDataviewType } from "../../containers/DataView/Modes";
import { localStorageEffect } from "../../lib/recoil-persist";
import {
  DATAVIEW_BASKET_DEFAULT_SIZE,
  DATAVIEW_DEFAULT_PAGE,
  DATAVIEW_DEFAULT_SIZE,
  DATAVIEW_DEFAULT_SORT,
} from "./constants";

export const dataViewPage = atomFamily<number, DataViewKey | BasketKey>({
  key: "DataViewPage",
  default: DATAVIEW_DEFAULT_PAGE,
  effects: (key) => [localStorageEffect(`DataViewPage-${key}`)],
});

export const dataViewSize = atomFamily<number, DataViewKey | BasketKey>({
  key: "DataViewSize",
  default: (key) =>
    key === BasketKey.PANEL ? DATAVIEW_BASKET_DEFAULT_SIZE : DATAVIEW_DEFAULT_SIZE,
  effects: (key) => [localStorageEffect(`DataViewSizeAtom-${key}`)],
});

export const dataViewSort = atomFamily<string, DataViewKey | BasketKey>({
  key: "DataViewSort",
  default: DATAVIEW_DEFAULT_SORT,
  effects: (key) => [localStorageEffect(`DataViewSort-${key}`)],
});

export const dataViewDisplayMode = atomFamily<SpecificDataviewType, DataViewKey | BasketKey>({
  key: "DataViewDisplayMode",
  default: SpecificDataviewType.MOSAIC,
  effects: (key) => [localStorageEffect(`DataViewDisplayMode-${key}`)],
});

export const selectedElements = atomFamily<KpkMedia["id"][], DataViewKey | BasketKey>({
  key: "SelectedElements",
  default: [],
  effects: (key) => [localStorageEffect(`SelectedElements-${key}`)],
});

// Keep a state for the whole selection of current search for dataflow
export const shouldSelectAllInCurrentSearch = atomFamily<boolean, DataViewKey | BasketKey>({
  key: "ShouldSelectAllAtom",
  default: false,
});

// Keep a state to know if we should update the creation of advance search query
export const shouldUpdateAdvancedSearchId = atomFamily<number, DataViewKey | BasketKey>({
  key: "ShouldUpdateAdvancedSearchId",
  default: 0,
});

// Keep a state to know if we should refresh the result of advanced search query
export const shouldRefreshGetAdvancedSearchQuery = atomFamily<number, DataViewKey | BasketKey>({
  key: "ShouldRefreshGetAdvancedSearchQuery",
  default: 0,
});

export const shouldNotCreateAdvancedId = atomFamily<boolean, DataViewKey | BasketKey>({
  key: "ShouldNotCreateAdvancedId",
  default: (key) => {
    // ADD here keys that does not need to be fetched on load
    if (key === BasketKey.PANEL) {
      return true;
    }
    return false;
  },
});

export const shouldClearSelectedElementsByKeysAtom = atom<(BasketKey | DataViewKey)[]>({
  key: "ShouldClearSelectedElementsByKeys",
  default: [],
});
