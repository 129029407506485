import { KpkApiAdvancedSearchFilter } from "@keepeek/api-client";
import {
  AdvancedSearchSelector,
  BasketKey,
  BusinessFilter,
  DataViewKey,
  fetcherModeAtom,
  isAuthenticatedSelector,
} from "@keepeek/commons";
import { selectorFamily, SerializableParam } from "recoil";

import { serializeComplexeParamsForRecoil } from "../../../lib/recoil-utils";
import { extractAdvancedFilters } from "../../../lib/search/search-media";
import { advancedSearchCriteriasSelector } from "../../advancedSearch/selectors";
import { configSectionGlobalPermanentFiltersSelector } from "../../config/selectors";
import { dataViewFetcherSelector } from "../../fetcher/selectors/dataview";
import { shouldNotCreateAdvancedId, shouldUpdateAdvancedSearchId } from "../atoms";
import { convertToRightType, mergePermanentFilters } from "../utils";

export const dataViewAdvancedSearchFilterSelector = selectorFamily<
  KpkApiAdvancedSearchFilter[] | null,
  Array<BusinessFilter & SerializableParam>
>({
  key: "DataViewAdvancedSearchFilterSelector",
  get: (filters) =>
    function ({ get }) {
      const permanentFilters = get(configSectionGlobalPermanentFiltersSelector);
      if (permanentFilters === null) {
        // we are in loading state => do not continue
        console.debug(
          "dataViewAdvancedSearchFilterSelector permanentFilters is mandatory cancel call",
        );
        return null;
      }
      const criterias = get(advancedSearchCriteriasSelector) || [];

      const convertedFilters = extractAdvancedFilters(filters, criterias);
      const parametersFiltersTyped = permanentFilters.map((pf) => ({
        ...pf,
        values: pf.values?.map((v) => (v = convertToRightType(v))),
      }));

      const filter = mergePermanentFilters(convertedFilters, parametersFiltersTyped);
      return filter;
    },
});

export const dataViewAdvancedSearchIdQuery = selectorFamily<
  AdvancedSearchSelector | null,
  {
    update: number;
    isAuthenticated: boolean;
    filters: Array<KpkApiAdvancedSearchFilter>;
  } & SerializableParam
>({
  key: "DataViewAdvancedSearchIdQuery",
  get:
    ({ filters }) =>
    async ({ get }) => {
      const { createAdvancedSearch } = get(dataViewFetcherSelector(get(fetcherModeAtom)));
      const createdAdvancedSearch = await createAdvancedSearch(filters);

      if (!createdAdvancedSearch) {
        return null;
      }

      const advancedSearch = {
        advancedSearchId: createdAdvancedSearch.data.id || "",
        expirationDate: createdAdvancedSearch.data.expirationDate || "",
      };

      return advancedSearch;
    },
});

export const dataViewAdvancedSearchId = selectorFamily<
  AdvancedSearchSelector | null,
  {
    key: DataViewKey | BasketKey;
    filters: Array<BusinessFilter> | null;
  } & SerializableParam
>({
  key: "DataViewAdvancedSearchId",
  get: ({ key, filters }) =>
    function ({ get }) {
      // Let the possibility to avoid the call
      const shouldNot = get(shouldNotCreateAdvancedId(key));
      if (shouldNot) {
        return null;
      }

      // Need to use shouldUpdate to have the deps refresh because of expiration date
      const isAuthenticated = get(isAuthenticatedSelector);
      const update = get(shouldUpdateAdvancedSearchId(key));
      let advancedSearchFilters: KpkApiAdvancedSearchFilter[] | null = null;
      if (filters) {
        advancedSearchFilters = get(
          dataViewAdvancedSearchFilterSelector(
            filters.map((f) => serializeComplexeParamsForRecoil(f)),
          ),
        );
      }
      if (advancedSearchFilters === null) {
        // we are in loading state => do not continue
        console.debug("dataViewAdvancedSearchId advancedSearchFilters is mandatory cancel call");
        return null;
      }
      const advancedSearch = get(
        dataViewAdvancedSearchIdQuery(
          serializeComplexeParamsForRecoil({
            update,
            isAuthenticated,
            filters: advancedSearchFilters,
          }),
        ),
      );

      return advancedSearch;
    },
});
