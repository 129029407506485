import React, { FC } from "react";

import { KpkMedia, KpkMetaField, MetaField } from "@keepeek/commons";
import { ElementMetaField } from "@keepeek/refront-components";

type ElementsMetaFieldProps = {
  media: KpkMedia;
  fields: Array<string>;
};
export const ElementsMetaField: FC<React.PropsWithChildren<ElementsMetaFieldProps>> = function (
  props,
) {
  const fieldsToDisplay = [...props.fields];
  if (fieldsToDisplay.length === 0) {
    fieldsToDisplay.push(MetaField.TITLE);
  }
  return (
    <>
      {fieldsToDisplay.map((fctField) => {
        let metaField: KpkMetaField | undefined;
        if (fctField === MetaField.TITLE) {
          metaField = props.media.title;
        } else {
          metaField = props.media.fields?.find((f) => f.id === fctField);
        }
        if (metaField) {
          return <ElementMetaField key={metaField.id} field={metaField} summaryDisplay />;
        }
        return null;
      })}
    </>
  );
};
export default ElementsMetaField;
