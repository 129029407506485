import React, { FC } from "react";

import { FieldFolder } from "@keepeek/refront-components";
import { Container, FormControl, Paper, TextField, Typography } from "@mui/material";
import { FieldProps } from "@rjsf/utils";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import {
  KImageWidgetURL,
  LinkType,
} from "../../../../models/configuration/components/kImageWidget";
import { frontEditState } from "../../../../providers/frontEdit/atoms/frontEdit";
import LinkTypeSelector from "../components/LinkTypeSelector";
import MailLink, { MailLinkData } from "../components/MailLink";
import UrlLink from "../components/UrlLink";
import { convertToMailToFormat, extractToMailLinkData } from "../utils/custom-links-field-utils";

const CustomImageUrlField: FC<React.PropsWithChildren<FieldProps<KImageWidgetURL>>> = function ({
  formData,
  onChange,
  schema,
}) {
  const { t } = useTranslation("admin");
  const frontEdit = useRecoilValue(frontEditState);
  const allowedLinkTypes = Object.values(LinkType);
  const targetBlank: KImageWidgetURL["targetBlank"] = formData?.targetBlank ?? false;
  const href: KImageWidgetURL["href"] = formData?.href ?? "";
  const title: KImageWidgetURL["title"] = formData?.title ?? "";
  const folderId: KImageWidgetURL["folderId"] = formData?.folderId ?? -1;
  const type: KImageWidgetURL["linkType"] =
    (formData?.linkType && (formData?.linkType as LinkType)) || LinkType.URL;
  const mailLinkData: MailLinkData = extractToMailLinkData(href);

  const handleChange = (data: KImageWidgetURL) => onChange(data);

  return (
    <Container
      maxWidth="xl"
      sx={{
        padding: 4,
        margin: 0,
      }}
      component={Paper}
    >
      <Typography variant="h6">
        {schema.title ? t(schema.title) : t("components.kImageWidget.kImageWidgetUrl.label")}
      </Typography>
      <LinkTypeSelector
        allowedLinkTypes={allowedLinkTypes}
        type={type}
        onChange={(data) =>
          handleChange({
            ...formData,
            linkType: data,
            href: data === LinkType.Mail || data === LinkType.URL ? "" : formData?.href,
          })
        }
        frontEdit={frontEdit}
      />
      {type === LinkType.URL && (
        <UrlLink
          onUrlChange={(data) =>
            handleChange({
              ...formData,
              href: data,
            })
          }
          onOpenInNewTabChange={(data) =>
            handleChange({
              ...formData,
              targetBlank: data,
            })
          }
          openInNewTab={targetBlank}
          url={href}
        />
      )}
      {type === LinkType.Mail && (
        <MailLink
          recipients={mailLinkData.recipients}
          body={mailLinkData.body}
          subject={mailLinkData.subject}
          onChange={(data) =>
            handleChange({
              ...formData,
              href: convertToMailToFormat(data),
            })
          }
        />
      )}
      {type === LinkType.Folder && (
        <FormControl fullWidth>
          <FieldFolder
            helperText={t("components.kImageWidget.kImageWidgetUrl.folder.description")}
            title=""
            forceSelection={true}
            multiple={false}
            initialFolderIds={folderId ? [folderId] : []}
            onChange={(values) =>
              handleChange({
                ...formData,
                folderId: values && values.length > 0 ? values[0] : undefined,
              })
            }
          />
        </FormControl>
      )}
      <TextField
        label={t("components.kImageWidget.kImageWidgetUrl.title.label")}
        value={title}
        variant="outlined"
        onChange={(e) =>
          handleChange({
            ...formData,
            title: e.target.value,
          })
        }
        fullWidth
        className={clsx("CustomImageUrlField-input")}
        margin="normal"
      />
    </Container>
  );
};

export default CustomImageUrlField;
