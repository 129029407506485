import {
  createAdvancedSearch,
  createPublicShareAdvancedSearch,
  getAdvancedSearch,
  getPublicShareAdvancedSearch,
  KpkApiAdvancedSearch,
  KpkApiAdvancedSearchFilter,
  KpkApiAdvancedSearchFilterType,
  KpkApiGetAdvancedSearchParams,
  KpkApiPagedResult,
  KpkApiSearchMediasEmbeddedMapping,
} from "@keepeek/api-client";
import {
  buildParamsForShare,
  currentLocaleAtom,
  FetcherMode,
  fetcherTokenIdAtom,
  isAuthenticatedSelector,
  shareTokenAxiosHeadersSelector,
} from "@keepeek/commons";
import { AxiosResponse } from "axios";
import { selectorFamily } from "recoil";

import { getAxiosClientInstance } from "../../../lib/axios/axios-utils";

export type DataviewFetcher = {
  /**
   * Fetcher to create an advanced search
   */
  createAdvancedSearch: (
    filter: KpkApiAdvancedSearchFilter[],
  ) => Promise<AxiosResponse<KpkApiAdvancedSearch>> | Promise<undefined>;
  /**
   * Fetcher to get advanced search result
   */
  getAdvancedSearch: (
    searchParams: KpkApiGetAdvancedSearchParams,
  ) =>
    | Promise<AxiosResponse<KpkApiPagedResult<KpkApiSearchMediasEmbeddedMapping>>>
    | Promise<undefined>;
};

/**
 * Dataview fetcher with default to logged api paths
 */
export const dataViewFetcherSelector = selectorFamily<DataviewFetcher, FetcherMode>({
  key: "DataViewFetcherSelector",
  get:
    (mode) =>
    ({ get }) => {
      const isAuthenticated = get(isAuthenticatedSelector);
      const currentLocale = get(currentLocaleAtom);
      const tokenId = get(fetcherTokenIdAtom);
      const headers = get(shareTokenAxiosHeadersSelector);
      switch (mode) {
        case FetcherMode.SHARE:
          return {
            createAdvancedSearch: (filter) => {
              return createPublicShareAdvancedSearch(
                getAxiosClientInstance(),
                buildParamsForShare(
                  {
                    filter,
                  },
                  headers,
                  tokenId,
                ),
              );
            },
            getAdvancedSearch: (params) => {
              return getPublicShareAdvancedSearch(
                getAxiosClientInstance(),
                buildParamsForShare(params, headers, tokenId),
              );
            },
          };

        default:
          if (!isAuthenticated || !currentLocale) {
            console.debug(
              "dataViewFetcherSelector current locale atom is not valued or isAuthenticated is false",
              {
                isAuthenticated,
                currentLocale,
              },
            );
            return {
              createAdvancedSearch: () => Promise.resolve(undefined),
              getAdvancedSearch: () => Promise.resolve(undefined),
            };
          }

          return {
            createAdvancedSearch: (filter) => {
              const acc: Array<KpkApiAdvancedSearchFilter> = filter.reduce(
                (acc: KpkApiAdvancedSearchFilter[], f: KpkApiAdvancedSearchFilter) => {
                  if (f.type === KpkApiAdvancedSearchFilterType.Thesaurusfield) {
                    const internalFieldNameWithLocale = f.internalFieldName?.split(".");
                    acc.push({
                      ...f,
                      type: KpkApiAdvancedSearchFilterType.Textfield,
                      internalFieldName:
                        internalFieldNameWithLocale?.length === 1
                          ? `${f.internalFieldName}.${currentLocale.toUpperCase()}`
                          : f.internalFieldName,
                    });
                  } else {
                    acc.push(f);
                  }
                  return acc;
                },
                [],
              );
              return createAdvancedSearch(getAxiosClientInstance(), {
                filter: acc,
                notFoundReference: true,
              });
            },
            getAdvancedSearch: (searchParams) => {
              return getAdvancedSearch(getAxiosClientInstance(), searchParams);
            },
          };
      }
    },
});
