import React from "react";

import { BaseComponentProps, SanitizeHtml } from "@keepeek/refront-components";
import { Box, Chip, useTheme } from "@mui/material";
import clsx from "clsx";

import { AssociationsWidget } from "../../../../../models/configuration/components/associationsWidget";
import { useCurrentLocale } from "../../../../../providers/i18n/hooks";

const WidgetCardSelectorAssociation = function ({
  testId = "WidgetCardSelectorAssociation",
  className,
  backgroundColor,
  elementId,
  title,
}: AssociationsWidget & BaseComponentProps) {
  const theme = useTheme();
  const locale = useCurrentLocale();
  const localizedTitle = !!title && title.find((t) => t.language === locale)?.value;
  return (
    <Box
      className={clsx("WidgetCardSelectorAssociation", className)}
      data-testid={testId}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        gap: theme.spacing(1),
        flexShrink: 1,
        backgroundColor: backgroundColor,
        padding: theme.spacing(1),
      }}
    >
      {!!localizedTitle && <SanitizeHtml html={localizedTitle} />}
      <Box
        style={{
          backgroundColor: theme.palette.grey[200],
          minHeight: theme.spacing(3),
          width: "100%",
        }}
      >
        {!!elementId && <Chip size="small" label={`id: ${elementId}`} />}
      </Box>
      <Box
        style={{
          backgroundColor: theme.palette.primary.main,
          width: `calc(100% - ${theme.spacing(3)})`,
          minHeight: theme.spacing(3),
        }}
      />
      <Box
        style={{
          backgroundColor: theme.palette.primary.main,
          width: `calc(100% - ${theme.spacing(3)})`,
          minHeight: theme.spacing(3),
        }}
      />
      <Box
        style={{
          backgroundColor: theme.palette.primary.main,
          width: `calc(100% - ${theme.spacing(3)})`,
          minHeight: theme.spacing(3),
        }}
      />
    </Box>
  );
};

export default WidgetCardSelectorAssociation;
