import { KpkMedia } from "@keepeek/commons";
import { atom } from "recoil";

/**
 * Add to basket Modal state
 */
export const addToBasketModalState = atom<boolean>({
  key: "AddToBasketModalState",
  default: false,
});

/**
 * add to basket selection
 */
export const addToBasketSelection = atom<KpkMedia["id"][]>({
  key: "AddToBasketSelection",
  default: [],
});
