export interface VisualAlertsSchema {
    backgroundColor?:   string;
    borderRadius?:      number;
    color?:             string;
    dateTargetMode:     DateTargetMode;
    dateTargetNumber:   number;
    dateTargetUnit:     DateTargetUnit;
    displayFieldValue?: boolean;
    fieldInternalName:  string;
    fieldType:          FieldType;
    fontSize?:          number;
    marginBottom?:      number;
    marginLeft?:        number;
    marginRight?:       number;
    marginTop?:         number;
    padding?:           number;
    position:           Position;
    subStatusId?:       number;
    text:               TranslationSchema[];
}

export enum DateTargetMode {
    ExpireWithin = "EXPIRE_WITHIN",
    ExpiredSince = "EXPIRED_SINCE",
    ExpiredSinceLessThan = "EXPIRED_SINCE_LESS_THAN",
}

export enum DateTargetUnit {
    Days = "DAYS",
    Months = "MONTHS",
    Years = "YEARS",
}

export enum FieldType {
    Date = "DATE",
    SubStatus = "SUB_STATUS",
}

export enum Position {
    BottomLeft = "BOTTOM_LEFT",
    BottomRight = "BOTTOM_RIGHT",
    TopLeft = "TOP_LEFT",
    TopRight = "TOP_RIGHT",
}

export interface TranslationSchema {
    empty?:      boolean;
    language:    Language;
    ressources?: any;
    value?:      string;
}

export enum Language {
    De = "de",
    En = "en",
    Es = "es",
    Fr = "fr",
    It = "it",
}
