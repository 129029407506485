import {
  KpkApiAdvancedSearchCriteria,
  KpkApiAdvancedSearchCriteriaFieldType,
  KpkApiSheetField,
} from "@keepeek/api-client";
import {
  BusinessFilter,
  BusinessFilterNature,
  getInternalNameWithoutLocale,
  KpkFilterModifier,
  KpkFilterModifierType,
  KpkFilterType,
  KpkFilterValue,
} from "@keepeek/commons";
import { getI18n } from "react-i18next";

import logger from "../../../../lib/logger-utils";
import { convertKpkApiAdvancedSearchCriteriaTypeToKpkFilterType } from "../types/convertKpkApiAdvancedSearchCriteriaTypeToKpkFilterType";

/**
 * Return an advanced filter list (multiple filter because of i18n...)
 */
export function buildAdvancedFilterList(
  internalName: string,
  sheetFields: KpkApiSheetField[] | undefined,
  criterias: KpkApiAdvancedSearchCriteria[] | undefined,
): BusinessFilter[] {
  const ret: BusinessFilter[] = [];
  if (!internalName) {
    return ret;
  }
  // first, try to find exact match for internal field name :
  const filteredCriteriaList = criterias?.filter((c) => c.internalFieldName === internalName) ?? [];
  // if nothing found, try to find with "startsWith" to find i18n criteria field :
  if ((!filteredCriteriaList || filteredCriteriaList.length === 0) && criterias) {
    filteredCriteriaList.push(
      ...criterias.filter((c) => c.internalFieldName?.startsWith(`${internalName}.`)),
    );
  }
  if (!filteredCriteriaList || filteredCriteriaList.length === 0) {
    logger.info(`No data from API for advanced filter ${internalName}`);
    return ret;
  }
  for (const criteria of filteredCriteriaList) {
    if (!criteria.availableModifiers) {
      logger.info(`No availableModifiers from API for advanced filter ${internalName}`);
      continue;
    }
    const filterType = convertKpkApiAdvancedSearchCriteriaTypeToKpkFilterType(criteria.type);
    if (!filterType) {
      logger.info("this filter type is not implemented", criteria);
      continue;
    }
    let filterLabel = internalName;
    const internalFieldName = getInternalNameWithoutLocale(criteria.internalFieldName || "");
    if (!internalFieldName) {
      continue;
    }
    if (criteria.fieldType === KpkApiAdvancedSearchCriteriaFieldType.Metafield) {
      const sheetField = sheetFields?.find((sf) => sf.id === internalFieldName);
      if (sheetField && sheetField.title) {
        filterLabel = sheetField.title;
      }
    } else if (criteria.fieldType === KpkApiAdvancedSearchCriteriaFieldType.Mediafield) {
      filterLabel = getI18n().t(`mediafield_${internalFieldName}`);
    }
    const values: Array<KpkFilterValue> = [];
    let modifiers: Array<KpkFilterModifier> | null = criteria.availableModifiers.map(
      (apiModifier) => {
        return {
          selected: null,
          type: apiModifier as unknown as KpkFilterModifierType,
        };
      },
    );

    if (filterType === KpkFilterType.Thesaurus) {
      modifiers = [
        {
          type: KpkFilterModifierType.ContainsOne,
          selected: true,
        },
      ];
    }
    // Special treatment for advanced search filters of radio type :
    // We force the modifiers and values to only YES / NO choice :
    if (filterType === KpkFilterType.Radio) {
      values.push(
        {
          id: true,
          label: getI18n().t("business-filter.advanced.radio.yes.label"),
          selected: false,
        },
        {
          id: false,
          label: getI18n().t("business-filter.advanced.radio.no.label"),
          selected: false,
        },
      );
      modifiers = [
        {
          type: KpkFilterModifierType.ContainsOne,
          selected: true,
        },
      ];
    }
    ret.push({
      nature: BusinessFilterNature.ADVANCED,
      filter: {
        id: criteria.internalFieldName || "",
        label: filterLabel,
        values,
        modifiers,
        type: filterType,
      },
    });
  }
  return ret;
}
