import {
  BusinessFilter,
  BusinessFilterNature,
  KpkFilterModifierType,
  KpkFilterType,
  MediaField,
} from "@keepeek/commons";

export function createFolderBusinessFilter(
  folderId: number,
  folderName: string,
  filterLabel: string,
): BusinessFilter {
  return {
    nature: BusinessFilterNature.ADVANCED,
    filter: {
      id: MediaField.FOLDER_ID,
      label: filterLabel,
      modifiers: [{ type: KpkFilterModifierType.EqualsOne, selected: true }],
      type: KpkFilterType.Folder,
      values: [
        {
          id: folderId,
          label: folderName,
          selected: null,
        },
      ],
    },
  };
}
