import React, { FC } from "react";

import { KCConsumerStatus } from "@keepeek/commons";
import { useRouter } from "next/router";

import { useAuthProvider } from "../../providers/auth/hooks/useAuthProvider";
import { useAuthRedirectUri } from "../../providers/auth/hooks/useAuthRedirectUri";
import LoadingAuth from "../auth/LoadingAuth";

const AuthProvider: FC<React.PropsWithChildren<unknown>> = function ({ children }) {
  // init Keycloak configuration
  const { pathname, push } = useRouter();

  const { redirectUri } = useAuthRedirectUri();

  const { loading, status } = useAuthProvider({
    pathname,
    unauthorizedCallback: () => push(redirectUri),
  });

  if (loading || status !== KCConsumerStatus.Authorized) {
    return <LoadingAuth forceShow />;
  }

  return <>{children}</>;
};

export default AuthProvider;
