import React, { FC, ReactNode } from "react";

import { EmotionCache } from "@emotion/cache";
import { ERROR_CODE_QUERY_PARAM } from "@keepeek/commons";
import { NotificationsCenter, withSuspense } from "@keepeek/refront-components";
import { useRouter } from "next/router";
import { RecoilRoot } from "recoil";

import { Message } from "../../lib/commonPagePropsUtil";
import I18nProvider from "../../lib/i18n/I18nProvider";
import { NotistackProvider } from "../../lib/NotistackProvider";
import { useMandatoryInitialRecoilState } from "../../providers/mandatory/hooks";
import KError from "../Errors";
import FrontEdit from "../FrontEdit";
import KHead from "../KHead";
import AuthProvider from "./AuthProvider";
import AxiosProvider from "./AxiosProvider";
import Consumer from "./Consumer";
import CustomerThemeProvider from "./CustomerThemeProvider";
import KScripts from "./KScripts";
import Share from "./Share";
import { getMessagesToDisplay } from "./utils";

export type AppProps = {
  Component: FC<React.PropsWithChildren<unknown>>;
  pageProps: { children?: ReactNode };
  emotionCache: EmotionCache;
};

const App: FC<React.PropsWithChildren<AppProps>> = function ({
  Component,
  emotionCache,
  pageProps,
}) {
  const router = useRouter();
  const { setMandatoryInitializeRecoilState } = useMandatoryInitialRecoilState();

  const displayMessages: Message[] = getMessagesToDisplay(
    router.query[ERROR_CODE_QUERY_PARAM] ? (router.query[ERROR_CODE_QUERY_PARAM] as string) : "",
  );

  return (
    <RecoilRoot
      initializeState={({ set }) => {
        // Set mandatory initial recoil state
        setMandatoryInitializeRecoilState(set);
      }}
    >
      <AxiosProvider>
        <I18nProvider>
          <CustomerThemeProvider emotionCache={emotionCache}>
            <AuthProvider>
              <KHead />
              <KScripts />
              <NotistackProvider>
                <KError initialMessages={displayMessages}>
                  <Share>
                    <Consumer>
                      <FrontEdit>
                        <Component {...pageProps} />
                      </FrontEdit>
                    </Consumer>
                  </Share>
                </KError>
              </NotistackProvider>
            </AuthProvider>
            <NotificationsCenter />
          </CustomerThemeProvider>
        </I18nProvider>
      </AxiosProvider>
    </RecoilRoot>
  );
};

export default withSuspense(App);
