/* eslint-disable @next/next/no-img-element */
import React from "react";

import { useCurrentUser } from "@keepeek/commons";
import { BaseComponentProps } from "@keepeek/refront-components";
import { Box, Chip, useTheme } from "@mui/material";
import clsx from "clsx";

import { KHeaderWidget } from "../../../../../models/configuration/components/kHeaderWidget";
import { useCurrentLocale } from "../../../../../providers/i18n/hooks";

const WidgetCardSelectorHeader = function ({
  testId = "WidgetCardSelectorHeader",
  className,
  backgroundColor,
  kHeaderWidgetLogo,
  kHeaderWidgetActions,
}: KHeaderWidget & BaseComponentProps) {
  const theme = useTheme();
  const locale = useCurrentLocale();
  const { currentUser } = useCurrentUser();
  const logoByLocale = kHeaderWidgetLogo?.url?.find((u) => u.language === locale);
  let logo = logoByLocale?.value;
  if (logoByLocale?.resource) {
    logo = logoByLocale?.resource.url;
  }
  return (
    <Box
      className={clsx("WidgetCardSelectorHeader", className)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        width: "100%",
      }}
      data-testid={testId}
    >
      <Box
        style={{
          backgroundColor: backgroundColor ?? theme.palette.primary.main,
          borderBottom: `1px solid ${theme.palette.primary.main}`,
          width: "100%",
          padding: theme.spacing(1),
        }}
      >
        {!!logo && <img src={logo} alt="logo" height={theme.spacing(4)} />}
        {kHeaderWidgetActions?.kHeaderWidgetActionsUser?.active && (
          <Chip size="small" label={currentUser.firstName} />
        )}
        {kHeaderWidgetActions?.kHeaderWidgetActionsCustomLinks && (
          <Chip
            size="small"
            label={`link: ${kHeaderWidgetActions?.kHeaderWidgetActionsCustomLinks.length}`}
          />
        )}
      </Box>
      <Box
        style={{
          backgroundColor: theme.palette.primary.main,
          height: theme.spacing(5),
          width: "80%",
          borderRadius: ".25rem .25rem 0 0",
          flexGrow: 1,
          marginTop: theme.spacing(2),
        }}
      />
    </Box>
  );
};

export default WidgetCardSelectorHeader;
