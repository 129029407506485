import {
  DownloadRequestStatus,
  DownloadSource,
  ElementDownload,
  KpkApiDeleteUserSelectionParams,
  KpkApiGetDownloadRequestsParams,
  KpkApiGetDownloadRequestsResult,
  KpkApiMediaTermsOfUse,
  KpkApiPostDownloadRequestsParams,
  KpkApiPostDownloadValidationRequestsParams,
  KpkApiPostPublicDownloadValidationRequestsParams,
  KpkApiSelectionAvailableExportFormats,
  KpkApiUser,
} from "@keepeek/api-client";
import { FetcherMode, FormatType, KpkMedia } from "@keepeek/commons";
import {
  DownloadManagerCSUContent,
  DownloadManagerFormatsByType,
} from "@keepeek/refront-components";
import * as Sentry from "@sentry/nextjs";
import { AxiosError, AxiosResponse } from "axios";
import trim from "lodash/trim";
import { ProviderContext, SnackbarKey } from "notistack";
import { getI18n } from "react-i18next";
import { v4 } from "uuid";

import { isAxiosError } from "../../lib/axios/axios-utils";
import logger from "../../lib/logger-utils";
import { SentryBreadcrumbCategory } from "../../lib/sentry/breadcrumbs";
import { Labels, TranslationSchema } from "../../models/configuration/labels";

const EMAIL_VALIDATOR_REGEX = /\S+@\S+\.\S+/;

export function convertToKpkFormatsByType(
  apiFormatsByTypes: KpkApiSelectionAvailableExportFormats[],
): DownloadManagerFormatsByType[] {
  const formatsByTypes: DownloadManagerFormatsByType[] = [];

  apiFormatsByTypes.forEach((apiFormatsByType) => {
    // Convert formats
    const formats: FormatType[] = [];
    apiFormatsByType.exportFormats.forEach((apiFormat) => {
      // Convert format metas
      const metas: FormatType["metas"] = [];
      metas.push({ type: "extension", value: apiFormat.extension });
      if (apiFormat.quality) {
        // TODO: Create a meta type quality
      }
      if (apiFormat.resolution) {
        metas.push({ type: "resolution", value: apiFormat.resolution, unit: "dpi" });
      }
      if (apiFormat.sizingType) {
        // TODO: Create a meta type sizingType
      }
      const format: FormatType = {
        id: apiFormat.id,
        title: apiFormat.title,
        description: apiFormat.description,
        active: false,
        metas,
        // Backoffice need a / followed by the format id, in case of public share
        // it makes things easier to have this syntax because export format self
        // href is not provided.
        href: `/${apiFormat.id}`,
      };
      formats.push(format);
    });

    // Convert Formats by Type
    const elements: KpkMedia["id"][] = [];
    apiFormatsByType.mediasList.forEach((m) => {
      if (m) {
        elements.push(m);
      }
    });
    const formatsByType: DownloadManagerFormatsByType = {
      id: apiFormatsByType.id,
      type: apiFormatsByType.title,
      definition: apiFormatsByType.definition,
      elements: apiFormatsByType.mediasList,
      formats,
    };
    formatsByTypes.push(formatsByType);
  });

  return formatsByTypes;
}

function asyncWait(time: number): Promise<"resolved"> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve("resolved");
    }, time);
  });
}

export async function downloadFormats(
  deleteSelection: (
    params: KpkApiDeleteUserSelectionParams,
  ) => Promise<AxiosResponse> | Promise<undefined>,
  postDownloadRequests: (
    params: KpkApiPostDownloadRequestsParams,
  ) => Promise<AxiosResponse> | Promise<undefined>,
  getDownloadRequests: (
    params: KpkApiGetDownloadRequestsParams,
  ) => Promise<AxiosResponse<KpkApiGetDownloadRequestsResult>> | Promise<undefined>,
  formatsByTypes: DownloadManagerFormatsByType[],
  snackBar: ProviderContext,
  user: KpkApiUser,
  t: (trad: string, vars?: any) => string,
  selectionId: string | null,
): Promise<void> {
  const { enqueueSnackbar, closeSnackbar } = snackBar;

  // Create elementDownloads
  // Convert formatsByTypes to elementDownloads
  const elementDownloads: ElementDownload[] = [];
  formatsByTypes.forEach((formatsByType) => {
    // extract downloadSources
    const downloadSources: DownloadSource[] = [];
    formatsByType.formats.forEach((format) => {
      // Only if it's active and it is'nt an ask for download
      if (format.active && format.id !== `${formatsByType.type}_${formatTypeIdAskDownload}`) {
        downloadSources.push({ from: { href: format.href } });
      }
    });
    if (downloadSources.length > 0) {
      const elementDownload: ElementDownload = {
        mediaIds: formatsByType.elements,
        downloadSources,
      };
      elementDownloads.push(elementDownload);
    }
  });
  // Do not try to download if we don't have any element to download
  if (elementDownloads.length === 0) {
    logger.debug("downloadFormats - No elements to download");
    return;
  }

  Sentry.addBreadcrumb({
    category: SentryBreadcrumbCategory.VISUAL_FEEDBACK,
    message: `downloadFormats - ${t("download-manager.status.processing.label")}: ${elementDownloads
      .flatMap((eld) => eld.mediaIds.map((mid) => mid))
      .join(",")}`,
  });

  try {
    // Notify the user for UX Purpose
    const snackPrep: SnackbarKey = enqueueSnackbar(t("download-manager.status.processing.label"), {
      persist: true,
      variant: "info",
      key: "preparingDownloadMsg",
      id: "preparingDownloadMsg",
    });

    // Create the download request
    await downloadFileRequest(
      postDownloadRequests,
      getDownloadRequests,
      elementDownloads,
      closeSnackbar,
      snackPrep,
      enqueueSnackbar,
      t,
    );
  } catch (error) {
    // In case of fail, first, close all snacks
    closeSnackbar();

    // WARNING: At the moment, if the user have a selection too big for a browser download
    // We will send him an email with his files
    // The error code is 500 with a message with the email address of the user in it
    // Catch it here and tell him that an email will be sent
    if (isAxiosError(error) && (error as AxiosError).response?.status === 500) {
      enqueueSnackbar(t("download-manager.status.email.label", { email: user.email }), {
        variant: "success",
        persist: false,
      });
      Sentry.addBreadcrumb({
        category: SentryBreadcrumbCategory.VISUAL_FEEDBACK,
        message: `downloadFormats - ${t("download-manager.status.email.label", {
          email: user.email,
        })}: ${elementDownloads.flatMap((eld) => eld.mediaIds.map((mid) => mid)).join(",")}`,
      });
    } else {
      // For everything else, tell the user that something went wrong
      enqueueSnackbar(t("download-manager.status.error.label"), {
        variant: "error",
        persist: false,
      });
      logger.error("downloadFormats", error);
    }
  }
  // Delete the user selection
  if (selectionId) {
    try {
      // don't need any feedback on the call
      // it's only for a cleaner Back-end state
      await deleteSelection({
        userId: user.id,
        selectionId,
        axiosRequestConfig: { handleErrorLocally: true },
      });
    } catch (error) {
      logger.info("downloadManagerDeleteSelection", error);
    }
  }
}

export const formatTypeIdAskDownload = "formatTypeIdAskDownload";
export const formatTypeAskDownload = (
  count: number,
  active?: boolean,
  id?: FormatType["id"],
): FormatType => ({
  id: id ?? formatTypeIdAskDownload,
  title: getI18n().t("download-manager.formatTypeIdAskDownload.title", { count }),
  description: getI18n().t("download-manager.formatTypeIdAskDownload.description", { count }),
  metas: [],
  active: active ?? false,
  href: "",
});

export const extractAskDownloadElementIds = (
  formatsByTypes: DownloadManagerFormatsByType[],
): KpkMedia["id"][] => {
  let elementIds: KpkMedia["id"][] = [];
  formatsByTypes.forEach((fbt) => {
    const haveAskDownload = fbt.formats.some(
      (f) => f.id === `${fbt.type}_${formatTypeIdAskDownload}` && f.active,
    );
    if (haveAskDownload) {
      elementIds = [...elementIds, ...fbt.elements];
    }
  });
  return elementIds;
};

export const createDownloadValidationRequest = async (
  postDownloadValidationRequests: (
    params:
      | KpkApiPostDownloadValidationRequestsParams
      | KpkApiPostPublicDownloadValidationRequestsParams,
  ) => Promise<AxiosResponse | undefined | unknown>,
  elementsId: KpkMedia["id"][],
  reason: string,
  email?: string,
  lastName?: string,
  firstName?: string,
  responsibleIds?: number[],
) => {
  await postDownloadValidationRequests({
    elementsId,
    reason,
    mail: email,
    lastName,
    firstName,
    responsibleIds,
  });
};

export const convertKpkApiMediaTermsOfUsesToDownloadManagerCSUContents = (
  terms: KpkApiMediaTermsOfUse[],
): DownloadManagerCSUContent[] => {
  return terms.map(
    (t) =>
      ({
        elementId: t.mediaId,
        elementTitle: t.mediaTitle,
        terms: t.terms.map((tt) => ({ name: tt.name, value: tt.value })),
      } as DownloadManagerCSUContent),
  );
};

export async function downloadFileRequest(
  postDownloadRequests: (
    params: KpkApiPostDownloadRequestsParams,
  ) => Promise<AxiosResponse> | Promise<undefined>,
  getDownloadRequests: (
    params: KpkApiGetDownloadRequestsParams,
  ) => Promise<AxiosResponse<KpkApiGetDownloadRequestsResult>> | Promise<undefined>,
  elementDownloads: ElementDownload[],
  closeSnackbar: (key?: SnackbarKey | undefined) => void,
  snackPrep: SnackbarKey,
  enqueueSnackbar,
  t: (trad: string, vars?: any) => string,
) {
  const create = await postDownloadRequests({
    elementDownloads,
    axiosRequestConfig: { handleErrorLocally: true },
  });
  if (!create) {
    throw new Error("Cannot post download requests without authorization");
  }

  // Create the downloadRequestId for reading purpose
  const requestId: string = create.headers?.location;
  const downloadRequestId = requestId.split("/")[requestId.split("/").length - 1];
  let status: DownloadRequestStatus = DownloadRequestStatus.PROCESSING;
  let downloadUrl: string | undefined = "";
  let callCount = 0;
  do {
    // Create a safe pattern for the API
    if (callCount < 5) {
      await asyncWait(2000);
    } else if (callCount < 10) {
      await asyncWait(5000);
    } else if (callCount < 20) {
      await asyncWait(10000);
    } else {
      await asyncWait(15000);
    }
    const read = await getDownloadRequests({
      downloadRequestId,
      axiosRequestConfig: { handleErrorLocally: true },
    });
    if (!read) {
      throw new Error("Cannot get download requests without authorization");
    }
    callCount += 1;
    status = read.data.status;
    if (status === DownloadRequestStatus.SUCCESS) {
      downloadUrl = read.data.target?.["kpk:target"];
    }
  } while (status === DownloadRequestStatus.PROCESSING);

  // Remove the prep snack when we download
  closeSnackbar(snackPrep);

  if (downloadUrl) {
    // Create a download snack
    const snackDwl: SnackbarKey = enqueueSnackbar(t("download-manager.status.download.label"), {
      persist: true,
      variant: "success",
      key: "ongoingDownloadMsg",
      id: "ongoingDownloadMsg",
    });
    // Launch the download via native side effect
    window.location.href = `${downloadUrl}?dl=true`;
    const cacheBuster = v4();
    window.location.href = `${downloadUrl}?dl=true&cache-buster=${cacheBuster}`;
    Sentry.addBreadcrumb({
      category: SentryBreadcrumbCategory.VISUAL_FEEDBACK,
      message: `downloadFormats - downloadFileRequest - ${t(
        "download-manager.status.download.label",
      )}: ${downloadUrl}`,
    });
    // Remove the download snack when we downloaded after at least 2 seconds for when it's too fast
    await asyncWait(2000);
    closeSnackbar(snackDwl);
  }
}

export const isBlank = (fieldValue?: string): boolean => {
  if (fieldValue === undefined) {
    return false;
  }
  return trim(fieldValue).length === 0;
};

export const isEmailError = (fieldValue?: string): boolean => {
  if (fieldValue === undefined) {
    return false;
  }
  return !EMAIL_VALIDATOR_REGEX.test(trim(fieldValue));
};

/**
 * Used in the event that the user is a visitor OR has gone through a public sharing link
 * AND the number of items to download exceeds the number authorized live (without sending by email) => techpro=DIRECT_EXPORT_NUMBER_LIMIT.
 * Temporary solution until we can send a specific email to the API to receive downloads in a visitor or public share context.
 */
export const isMaxReachedAnonymous = (
  elementsNbr: number,
  fetcherMode: FetcherMode,
  directExportNumberLimit: number | undefined,
  isVisitor: boolean,
): boolean => {
  if (directExportNumberLimit === undefined) {
    return false;
  } else {
    return (
      (isVisitor || FetcherMode.SHARE === fetcherMode) && elementsNbr > directExportNumberLimit
    );
  }
};

export const findDownloadManagerLabelByKeyAndLocale = (
  labelsConfigSection: Labels[] | undefined,
  labelKey: string,
  locale: string | undefined,
): TranslationSchema | undefined => {
  const label = labelsConfigSection
    // key is suffixed by "_one" or "_other"
    ?.find((l) => l.key?.startsWith(labelKey))
    ?.translation?.find((t) => t.language.toLowerCase() === locale?.toLowerCase());

  if (label?.empty) {
    logger.debug(`[Download Manager] '${labelKey}' label is hidden by configuration.`);
  }

  return label;
};
