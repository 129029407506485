import { sanitizeUrl } from "@braintree/sanitize-url";
import { isLocalURL } from "next/dist/shared/lib/router/router";
import { isAbsoluteUrl } from "next/dist/shared/lib/utils";
import sanitize from "sanitize-html";

import { PagesPath } from "../../containers/App/utils";

const DEFAULT_WAIT_MS_BEFORE_DISPLAY_ALERT = 20000;

export /**
 * Take a path and verify if it pass the rules
 * Use it to prevent XSS ATTACK to be send to "router.push"
 * @author JBO
 *
 * @param {(string | undefined)} unverifiedPath
 * @returns {(string | undefined)}
 */
const verifyPath = (unverifiedPath: string | undefined): string | undefined => {
  if (unverifiedPath) {
    const verifiedPath = sanitizeUrl(sanitize(unverifiedPath));
    // Check basic execution code
    if (
      verifiedPath.includes("javascript") ||
      verifiedPath.includes("data") ||
      verifiedPath.includes("mailto")
    ) {
      return undefined;
    }
    // Check any URL that can lead to outside of the ReFront
    if (verifiedPath.startsWith("http") || verifiedPath.startsWith("www")) {
      return undefined;
    }
    // Check with Next if it is routable
    if (!isLocalURL(verifiedPath)) {
      return undefined;
    }
    // Check with Next that it is not absolute
    if (isAbsoluteUrl(verifiedPath)) {
      return undefined;
    }
    // Check if we have an authorized corresponding page
    if (
      !(
        verifiedPath.startsWith(PagesPath.HOME_PAGE) ||
        verifiedPath.startsWith(PagesPath.ELEMENT_PAGE) ||
        verifiedPath.startsWith(PagesPath.SEARCH_PAGE) ||
        verifiedPath.startsWith(PagesPath.REDIRECT) ||
        verifiedPath.startsWith(PagesPath.ADMIN)
      )
    ) {
      return undefined;
    }

    return verifiedPath;
  }
  return undefined;
};

export const isAlertMessageEnabled = (msBeforeDisplayMessage?: number): boolean =>
  msBeforeDisplayMessage !== undefined && msBeforeDisplayMessage > 0;

export const getWaitMsBeforeDisplayAlert = (
  loading: boolean,
  specificWaitMs?: string,
): undefined | number => {
  if (loading) {
    return undefined;
  } else if (specificWaitMs) {
    return parseInt(specificWaitMs);
  } else {
    return DEFAULT_WAIT_MS_BEFORE_DISPLAY_ALERT;
  }
};
