import { KpkMedia } from "@keepeek/commons";
import { atom } from "recoil";

// Keep a status to know if the opening was allowed or denied in order to print error
export enum DownloadManagerOpenStatus {
  ALLOWED = "allowed",
  MAX_REACHED = "max_reached",
  /**
   * Used in the event that the user is a visitor OR has gone through a public sharing link
   * AND the number of items to download exceeds the number authorized live (without sending by email) => techpro=DIRECT_EXPORT_NUMBER_LIMIT.
   * Temporary solution until we can send a specific email to the API to receive downloads in a visitor or public share context.
   */
  MAX_REACHED_ANONYMOUS = "max_reached_anonymous",
}
export const downloadManagerOpenStatusState = atom<DownloadManagerOpenStatus>({
  key: "DownloadManagerOpenStatusState",
  default: DownloadManagerOpenStatus.ALLOWED,
});

// Modal opening state
export const downloadManagerOpenState = atom<boolean>({
  key: "DownloadManagerOpenState",
  default: false,
});

// Selected Elements
// The download manager keep is own state of selected elements, and we inject it
// so we don't have to worry if we're selecting one element but have an active
// selection in the dataview or we're triggering it from the baskets or anything
// else. The download manager is generic
export const downloadManagerSelectedElementsState = atom<KpkMedia["id"][]>({
  key: "DownloadManagerSelectedElementsState",
  default: [],
});

/**
 * A tab in download manager is loading
 */
export const tabLoadingAtom = atom<boolean>({
  key: "TabLoadingAtom",
  default: false,
});

/**
 * Elements id list selected to download
 */

export const elementsSelectionIdState = atom<KpkMedia["id"][] | null>({
  key: "ElementsSelectionIdState",
  default: null,
});
