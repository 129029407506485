import { useEffect, useState } from "react";

import { currentLocaleAtom, KpkAttachment, KpkMedia } from "@keepeek/commons";
import * as Sentry from "@sentry/nextjs";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { copy } from "../../../lib/clipboard";
import logger from "../../../lib/logger-utils";
import { SentryBreadcrumbCategory } from "../../../lib/sentry/breadcrumbs";
import { Global } from "../../../models/configuration/global";
import { useConfiguration } from "../../config/hooks";
import { attachmentSelector, attachmentsQuerySelector } from "../selectors/getAttachments";
import { attachmentsUriSelector, attachmentUriQuerySelector } from "../selectors/uri";

export type UseCopyAttachment = {
  copyAttachment: (attachmentId?: KpkAttachment["id"]) => void;
  attachment: KpkAttachment | undefined;
  loadingAttachment: boolean;
  loading: boolean;
  copyInHTMLMode: boolean;
};
type UseCopyAttachmentProps = {
  attachmentId?: KpkAttachment["id"];
  elementId?: KpkMedia["id"];
};

export const useCopyAttachment = ({
  attachmentId,
  elementId,
}: UseCopyAttachmentProps): UseCopyAttachment => {
  const { copyInHTMLMode = false } = useConfiguration<Global>(
    CONFIGURATION_SECTION_PATH.GLOBAL,
  ) ?? { copyInHTMLMode: undefined };
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const locale = useRecoilValue(currentLocaleAtom);

  const [clickedAttachment, setClickedAttachment] = useState<KpkAttachment["id"] | undefined>(
    undefined,
  );

  const attachmentQuery = useRecoilValueLoadable(
    attachmentSelector({ locale, attachmentId, elementId }),
  );
  const attachment = attachmentQuery.state === "hasValue" ? attachmentQuery.contents : undefined;
  const loadingAttachment = attachmentQuery.state === "loading";

  const uriQuery = useRecoilValueLoadable(
    attachmentUriQuerySelector({ elementId, attachmentId: clickedAttachment }),
  );
  const uriRes = uriQuery.state === "hasValue" ? uriQuery.contents : undefined;
  const uri = uriRes?.uri;

  const loading = uriQuery.state === "loading";

  const copyAttachment = (passedAttachmentId?: KpkAttachment["id"]) => {
    logger.info(`Copy asked`);
    setClickedAttachment(passedAttachmentId || attachmentId);
    Sentry.addBreadcrumb({
      category: SentryBreadcrumbCategory.USER_INTERACTION,
      message: `copyAttachment, id: ${passedAttachmentId || attachmentId}`,
    });
  };

  useEffect(() => {
    if (clickedAttachment && uri) {
      logger.info(`Copy attachment url ${attachmentId}, elementId: ${elementId}`);
      if (copyInHTMLMode && attachment) {
        const uriHTML = `<a href="${uri}">${
          attachment.title ? attachment.title : uri
        }</a></br></br>`;
        copy(uri, uriHTML);
      } else {
        copy(uri);
      }
      logger.info(`End of copy attachment url`);
      enqueueSnackbar(t("attachment.copy.url"), {
        variant: "success",
      });
      // Reset the click so we can re ask for a copy
      setClickedAttachment(undefined);
    }
  }, [
    attachment,
    attachmentId,
    clickedAttachment,
    copyInHTMLMode,
    elementId,
    enqueueSnackbar,
    t,
    uri,
  ]);

  return {
    copyAttachment,
    attachment,
    loadingAttachment,
    loading,
    copyInHTMLMode,
  };
};

export type UseCopyAttachments = {
  copyAttachments: () => void;
  copyAttachment: (attachmentId: KpkAttachment["id"]) => void;
  attachments: KpkAttachment[] | undefined;
  loadingAttachments: boolean;
  loading: boolean;
  copyInHTMLMode: boolean;
};
type UseCopyAttachmentsProps = {
  elementId?: KpkMedia["id"];
};

export const useCopyAttachments = ({ elementId }: UseCopyAttachmentsProps): UseCopyAttachments => {
  const { copyInHTMLMode = false } = useConfiguration<Global>(
    CONFIGURATION_SECTION_PATH.GLOBAL,
  ) ?? { copyInHTMLMode: undefined };
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const locale = useRecoilValue(currentLocaleAtom);
  const attachmentsQuery = useRecoilValueLoadable(attachmentsQuerySelector({ locale, elementId }));

  const attachments = attachmentsQuery.state === "hasValue" ? attachmentsQuery.contents : undefined;

  const loadingAttachments = attachmentsQuery.state === "loading";

  const [clickedElement, setClickedElement] = useState<KpkMedia["id"] | undefined>(undefined);

  const paramsUrisQuery:
    | { attachmentId: KpkAttachment["id"]; elementId?: KpkMedia["id"] }[]
    | undefined =
    attachments?.map(({ id }) => ({
      attachmentId: id,
      elementId: clickedElement,
    })) ?? undefined;
  const urisQuery = useRecoilValueLoadable(attachmentsUriSelector(paramsUrisQuery));
  const loading = urisQuery.state === "loading";
  const urisRes = urisQuery.state === "hasValue" ? urisQuery.contents : undefined;

  const copyAttachments = () => {
    setClickedElement(elementId);
    Sentry.addBreadcrumb({
      category: SentryBreadcrumbCategory.USER_INTERACTION,
      message: `copyAttachments, id: ${elementId}`,
    });
  };

  useEffect(() => {
    if (urisRes && urisRes.length > 0 && clickedElement) {
      // Reset the click so we can re ask for a copy
      setClickedElement(undefined);
      logger.info(`Copy all attachments for element  ${elementId}`);
      let allUri: string = "";
      let allUriHTML: string = "";
      urisRes.forEach((uriRes) => {
        if (uriRes) {
          const { uri, id } = uriRes;
          allUri += `${uri}\n\n`;
          if (copyInHTMLMode) {
            const attachment: KpkAttachment | undefined = attachments?.find((a) => a.id === id);
            allUriHTML += `<a href="${uri}">${
              attachment && attachment.title ? attachment.title : uri
            }</a></br></br>`;
          }
        } else {
          logger.error(
            `copy all attachments for elementId ${elementId}: at least one attachmenId haven't resolved any URI`,
          );
        }
      });
      if (copyInHTMLMode) {
        copy(allUri, allUriHTML);
      } else {
        copy(allUri);
      }
      enqueueSnackbar(t("attachments.copy.url"), {
        variant: "success",
      });

      logger.info(`End of copy all attachments url`);
    }
  }, [attachments, clickedElement, copyInHTMLMode, elementId, enqueueSnackbar, t, urisRes]);

  const { copyAttachment } = useCopyAttachment({ elementId });

  return {
    copyAttachments,
    copyAttachment,
    loading,
    copyInHTMLMode,
    attachments,
    loadingAttachments,
  };
};
