import React, { FC } from "react";

import ListDataView from "./ListDataView";
import MosaicDataView from "./MosaicDataView";

export enum SpecificDataviewType {
  MOSAIC = "mosaic",
  LIST = "list",
}

export type SpecificDataviewProps = {
  type: SpecificDataviewType;
};
const SpecificDataview: FC<React.PropsWithChildren<SpecificDataviewProps>> = function ({ type }) {
  switch (type) {
    case SpecificDataviewType.LIST:
      return <ListDataView />;
    default:
      return <MosaicDataView />;
  }
};

export default SpecificDataview;
