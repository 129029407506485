import React, { ComponentType, FC } from "react";

import {
  FunctionOverrideKey,
  FunctionOverrideParams,
  FunctionOverrideReturn,
} from "@keepeek/commons";
import {
  ComponentOverrideKey,
  ICustomerOverride,
  LayoutOverrideKey,
  PageOverrideKey,
  WrapperOverrideKey,
} from "@keepeek/refront-customers";
import { ComponentAugmentKey, ComponentAugmentProps } from "@keepeek/refront-customers";

import { getCustomerConfig } from "./config/customer-config-utils";
import logger from "./logger-utils";
import { parseConfigSection } from "./query-util";

const Noop: FC<React.PropsWithChildren<any>> = function () {
  return null;
};

/**
 * Returns the customer override component for the given key, or undefined if there is this key is not overriden for the current customer.
 */
export const getCustomerOverrideComponent = function <P = {}>(
  overrideKey: PageOverrideKey | ComponentOverrideKey | LayoutOverrideKey | WrapperOverrideKey,
  defaultComponent: ComponentType<React.PropsWithChildren<P>> = Noop,
): ComponentType<React.PropsWithChildren<P>> {
  let overrideComponent: ComponentType<React.PropsWithChildren<P>> | undefined;
  try {
    const override: ICustomerOverride = getCustomerConfig().overrides;
    if (!override) {
      return defaultComponent;
    }
    if (isPageOverrideKey(overrideKey) && override.page && override.page[overrideKey]) {
      overrideComponent = override.page[overrideKey];
    } else if (
      isLayoutOverrideKey(overrideKey) &&
      override.layout &&
      override.layout[overrideKey]
    ) {
      overrideComponent = override.layout[overrideKey];
    } else if (
      !isPageOverrideKey(overrideKey) &&
      override.component &&
      override.component[overrideKey]
    ) {
      overrideComponent = override.component[overrideKey];
    } else if (
      isWrapperOverrideKey(overrideKey) &&
      override.wrapper &&
      override.wrapper[overrideKey]
    ) {
      overrideComponent = override.wrapper[overrideKey];
    }
  } catch (e) {
    logger.info("can't load customer override", e);
  }

  return overrideComponent || defaultComponent;
};

/**
 * Returns the component for a custom page based on the given path
 */
export const getCustomerOverrideRoute = function <P = {}>(
  customRouteQuery?: string | string[],
): React.ComponentType<React.PropsWithChildren<P>> | undefined {
  try {
    const override: ICustomerOverride = getCustomerConfig().overrides;
    if (!override) {
      return;
    }

    let path: string | undefined = parseConfigSection(customRouteQuery);
    path = path && `/${path}`;
    if (path && override.route && override.route[path]) {
      return override.route[path];
    }
  } catch (e) {
    logger.info("can't load customer override", e);
  }
};

/**
 * Returns the customer override function for the given key, or undefined if there is no override for this function.
 */
export function getCustomerOverrideFunction<K extends FunctionOverrideKey>(
  overrideFunctionKey: K,
  params: FunctionOverrideParams[K],
): FunctionOverrideReturn[K] | undefined {
  try {
    const override: ICustomerOverride = getCustomerConfig().overrides;

    if (override && override.function && override.function[overrideFunctionKey]) {
      return (override.function[overrideFunctionKey] as Function)(params);
    }

    return undefined;
  } catch (e) {
    logger.info("can't load customer override", e);
  }
}

const isPageOverrideKey = function (
  overrideKey: PageOverrideKey | ComponentOverrideKey | LayoutOverrideKey | WrapperOverrideKey,
): overrideKey is PageOverrideKey {
  return Object.values(PageOverrideKey).includes(overrideKey as PageOverrideKey);
};

const isLayoutOverrideKey = function (
  overrideKey: PageOverrideKey | ComponentOverrideKey | LayoutOverrideKey | WrapperOverrideKey,
): overrideKey is LayoutOverrideKey {
  return Object.values(LayoutOverrideKey).includes(overrideKey as LayoutOverrideKey);
};

const isWrapperOverrideKey = function (
  overrideKey: PageOverrideKey | ComponentOverrideKey | LayoutOverrideKey | WrapperOverrideKey,
): overrideKey is WrapperOverrideKey {
  return false;
  //return Object.values(WrapperOverrideKey).includes(overrideKey as WrapperOverrideKey); // TODO : decommenter si WrapperOverrideKey a de nouveau des elements
};

export const GetCustomerAugmentedComponent = function <K extends ComponentAugmentKey>({
  overrideKey,
  props,
  Node,
}: {
  overrideKey: K;
  props: ComponentAugmentProps[K];
  Node: React.FC<React.PropsWithChildren<ComponentAugmentProps[K]>>;
}) {
  const override: ICustomerOverride = getCustomerConfig().overrides;
  if (!!override?.augmentComponent?.[overrideKey]) {
    const Component = override.augmentComponent[overrideKey] as unknown as React.FC<
      React.PropsWithChildren<
        ComponentAugmentProps[K] & {
          Node: React.FC<React.PropsWithChildren<ComponentAugmentProps[K]>>;
        }
      >
    >;
    if (Component) {
      return <Component {...props} Node={Node} />;
    }
  }

  return <Node {...props} />;
};
