import { useEffect, useState } from "react";

import { currentLocaleAtom, KpkAttachment, KpkMedia } from "@keepeek/commons";
import * as Sentry from "@sentry/nextjs";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";

import { SentryBreadcrumbCategory } from "../../../lib/sentry/breadcrumbs";
import { attachmentsQuerySelector } from "../selectors/getAttachments";
import {
  attachmentsUriSelector,
  attachmentUriQuerySelector,
  KpkAttachmentUri,
} from "../selectors/uri";

export type UseDownloadAttachment = {
  loading: boolean;
  uri: KpkAttachmentUri["uri"];
  uriRes: KpkAttachmentUri | undefined;
  onDownloadAttachment: (attachmentId?: KpkAttachment["id"]) => void;
};

type UseDownloadAttachmentProps = {
  attachmentId?: KpkAttachment["id"];
  elementId?: KpkMedia["id"];
};

export const useDownloadAttachment = ({
  attachmentId,
  elementId,
}: UseDownloadAttachmentProps): UseDownloadAttachment => {
  const [clickedAttachment, setClickedAttachment] = useState<KpkMedia["id"] | undefined>(undefined);

  const uriQuery = useRecoilValueLoadable(
    attachmentUriQuerySelector({ elementId, attachmentId: clickedAttachment }),
  );
  const uriRes = uriQuery.state === "hasValue" ? uriQuery.contents : undefined;
  const uri = uriRes?.uri;
  const loading = uriQuery.state === "loading";

  const onDownloadAttachment = (passedAttachmentId?: KpkAttachment["id"]) => {
    setClickedAttachment(passedAttachmentId || attachmentId);
    Sentry.addBreadcrumb({
      category: SentryBreadcrumbCategory.USER_INTERACTION,
      message: `onDownloadAttachment, id: ${passedAttachmentId || attachmentId}`,
    });
  };

  useEffect(() => {
    if (uri && clickedAttachment) {
      // Reset the click so we can re ask for a downlaod
      setClickedAttachment(undefined);
      window.open(uri, "_BLANK");
    }
  }, [clickedAttachment, uri]);

  return {
    uri,
    uriRes,
    loading,
    onDownloadAttachment,
  };
};

export type UseDownloadAttachments = {
  loading: boolean;
  uris: KpkAttachmentUri["uri"][] | undefined;
  urisRes: (KpkAttachmentUri | undefined)[] | undefined;
  onDownloadAttachments: () => void;
  onDownloadAttachment: (attachmentId: KpkAttachment["id"]) => void;
  attachments: KpkAttachment[] | undefined;
  loadingAttachments: boolean;
};

type UseDownloadAttachmentsProps = {
  elementId?: KpkMedia["id"];
};

export const useDownloadAttachments = ({
  elementId,
}: UseDownloadAttachmentsProps): UseDownloadAttachments => {
  const locale = useRecoilValue(currentLocaleAtom);
  const attachmentsQuery = useRecoilValueLoadable(attachmentsQuerySelector({ locale, elementId }));
  const attachments = attachmentsQuery.state === "hasValue" ? attachmentsQuery.contents : undefined;

  const loadingAttachments = attachmentsQuery.state === "loading";

  const [clickedElement, setClickedElement] = useState<KpkMedia["id"] | undefined>(undefined);

  const paramsUrisQuery:
    | { attachmentId: KpkAttachment["id"]; elementId?: KpkMedia["id"] }[]
    | undefined =
    attachments?.map(({ id }) => ({
      attachmentId: id,
      elementId: clickedElement,
    })) ?? undefined;
  const urisQuery = useRecoilValueLoadable(attachmentsUriSelector(paramsUrisQuery));

  const loading = urisQuery.state === "loading";

  const urisRes = urisQuery.state === "hasValue" ? urisQuery.contents : undefined;
  const uris = urisRes?.map((u) => u?.uri);

  const onDownloadAttachments = () => {
    setClickedElement(elementId);
    Sentry.addBreadcrumb({
      category: SentryBreadcrumbCategory.USER_INTERACTION,
      message: `onDownloadAttachments, id: ${elementId}`,
    });
  };

  useEffect(() => {
    if (uris && uris.length > 0 && clickedElement) {
      // Reset the click so we can re ask for a downlaod
      setClickedElement(undefined);
      uris.forEach((uri) => {
        if (uri) {
          window.open(uri, "_BLANK");
        }
      });
    }
  }, [clickedElement, uris]);

  const { onDownloadAttachment } = useDownloadAttachment({ elementId });

  return {
    attachments,
    loadingAttachments,
    loading,
    urisRes,
    uris,
    onDownloadAttachments,
    onDownloadAttachment,
  };
};
