import { fetcherModeAtom, KpkMedia, DataViewKey } from "@keepeek/commons";
import { DOWNLOAD_MANAGER_FORMAT_BY } from "@keepeek/refront-components";
import * as Sentry from "@sentry/nextjs";
import uniq from "lodash/uniq";
import {
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";

import { ActionValue } from "../../components/search/Dataview/Actions";
import logger from "../../lib/logger-utils";
import { SentryBreadcrumbCategory } from "../../lib/sentry/breadcrumbs";
import { useBackContext } from "../backContext/hooks";
import { addToBasketSelection } from "../basket/atoms/addTo";
import { activeBusinessFiltersFromURLSelector } from "../businessFilter/selectors/activeFilters";
import { selectedElements } from "../dataView/atoms";
import { useDataView } from "../dataView/hooks/useDataView";
import { dataViewElement, elementIsSelected } from "../dataView/selectors/element";
import { downloadManagerPreferenceFormatByState } from "../downloadManager/atoms/preferences";
import { downloadManagerSelectionSelector } from "../downloadManager/selectors/selections";
import { dataviewElementFetcherSelector } from "../fetcher/selectors/dataviewElement";
import { useShareManagerSelections } from "../shareManager/hooks/useShareManagerSelections";
import { ShareManagerSelectionType } from "../shareManager/models";
import { dataViewElementFieldsToDisplaySelector } from "./selectors";

export type UseDataViewElement = {
  element: KpkMedia | undefined;
  isSelected: boolean;
  setSelected: SetterOrUpdater<boolean>;
  functionalFieldsToDisplay: string[];
  technicalFieldsToDisplay: string[];
  loadingFields: boolean;
  selectionMode: boolean;
  click: () => void;
  doubleClick: () => void;
  actionClick: (action: ActionValue) => void;
  majClick: () => void;
  associationClick: () => void;
};

export enum QueryParamElement {
  HIGHLIGHT_ASSOCIATIONS = "highlightAssociations",
}

export const useDataViewElement = (key: DataViewKey, id: KpkMedia["id"]): UseDataViewElement => {
  const filters = useRecoilValue(activeBusinessFiltersFromURLSelector);
  const { elements } = useDataView({ key, filters });
  const element = useRecoilValue(dataViewElement({ key, id }));
  const mode = useRecoilValue(fetcherModeAtom);
  const { getElementBackContext } = useRecoilValue(dataviewElementFetcherSelector(mode));
  const [isSelected, setSelected] = useRecoilState(elementIsSelected({ key, id }));
  const queryFields = useRecoilValueLoadable(dataViewElementFieldsToDisplaySelector({ key, id }));

  const functionalFieldsToDisplay =
    queryFields.state === "hasValue" ? queryFields.contents.functionalFieldsToDisplay : [];
  const technicalFieldsToDisplay =
    queryFields.state === "hasValue" ? queryFields.contents.technicalFieldsToDisplay : [];

  const [selecteds, setSelecteds] = useRecoilState(selectedElements(key));
  const selectionMode: boolean = selecteds.length > 0;

  const setSelectionToDownloadManager = useSetRecoilState(downloadManagerSelectionSelector);
  const setSelectionToAdd = useSetRecoilState(addToBasketSelection);
  const setPreferenceFormat = useSetRecoilState(downloadManagerPreferenceFormatByState);
  const { goToPageWithBackContext } = useBackContext();

  const { handleSelections } = useShareManagerSelections();

  function click() {
    setSelected((prevValue) => !prevValue);
    Sentry.addBreadcrumb({
      category: SentryBreadcrumbCategory.USER_INTERACTION,
      message: `useDataViewElement - click, key: ${key}, id: ${id}`,
    });
  }

  function doubleClick() {
    Sentry.addBreadcrumb({
      category: SentryBreadcrumbCategory.USER_INTERACTION,
      message: `useDataViewElement - doubleClick, key: ${key}, id: ${id}`,
    });
    goToPageWithBackContext(getElementBackContext(id));
  }

  function actionClick(action: ActionValue) {
    Sentry.addBreadcrumb({
      category: SentryBreadcrumbCategory.USER_INTERACTION,
      message: `useDataViewElement - actionClick, key: ${key}, id: ${id}, action: ${action}`,
    });
    if (!selectionMode) {
      switch (action) {
        case ActionValue.OPEN_ELEMENT:
          goToPageWithBackContext(getElementBackContext(id));
          break;
        case ActionValue.DOWNLOAD:
          setSelectionToDownloadManager([id]);
          break;
        case ActionValue.SHOW_ATTACHMENT:
          setPreferenceFormat(DOWNLOAD_MANAGER_FORMAT_BY.OTHER);
          setSelectionToDownloadManager([id]);
          break;
        case ActionValue.ADD_TO_BASKET:
          setSelectionToAdd([id]);
          break;
        case ActionValue.SHARE:
          handleSelections([
            {
              type: ShareManagerSelectionType.UNITARY_PUBLIC_SHARE,
              elements: [
                {
                  id: id,
                  title: elements.find((e) => e.id === id)?.title.value || "",
                  mediaType: elements.find((e) => e.id === id)?.mediaType || "image/jpg",
                },
              ],
            },
          ]);
          break;
        default:
          logger.debug(`${action} sur l'élément ${id}`);
          break;
      }
    }
  }

  function majClick() {
    Sentry.addBreadcrumb({
      category: SentryBreadcrumbCategory.USER_INTERACTION,
      message: `useDataViewElement - majClick, key: ${key}, id: ${id}`,
    });
    // First set this element
    setSelected(true);
    // Check if we have at least one another element to match
    if (selecteds.length !== 0) {
      // Create a copy of the current selected to avoid waiting for Recoil state update
      // And add our current id in it, no need to be clean of doublon
      const currSelecteds = [...selecteds, id];

      let firstElementSelectedIndex = -1;
      let lastElementSelectedIndex = -1;
      let selectedIndex = elements.findIndex((el) => el.id === id);
      let selectedIsTheFirst = false;

      // Iterate over elements to find first index
      elements.some((el, i) => {
        const match = currSelecteds.includes(el.id);
        if (match) {
          firstElementSelectedIndex = i;
          selectedIsTheFirst = el.id === id;
        }
        return match;
      });

      // In case the first element is the one we're selecting, make the opposite selection
      if (selectedIsTheFirst) {
        selectedIndex = [...elements].reverse().findIndex((el) => el.id === id);
        // Iterate over reversed elements to find last index
        [...elements].reverse().some((el, i) => {
          const match = currSelecteds.includes(el.id);
          if (match) {
            lastElementSelectedIndex = i;
          }
          return match;
        });
      }

      // Then set the ids in the selecteds array
      const startIndex = selectedIsTheFirst ? lastElementSelectedIndex : firstElementSelectedIndex;
      const iterator = selectedIsTheFirst ? [...elements].reverse() : elements;
      iterator.slice(startIndex, selectedIndex).forEach((el) => {
        setSelecteds((currVal) => uniq([...currVal, el.id]));
      });
    }
  }

  function associationClick() {
    Sentry.addBreadcrumb({
      category: SentryBreadcrumbCategory.USER_INTERACTION,
      message: `useDataViewElement - associationClick, key: ${key}, id: ${id}`,
    });
    goToPageWithBackContext({
      sourcePage: { pathname: `/search` },
      targetPage: {
        pathname: `/element`,
        query: { id: id, mode: QueryParamElement.HIGHLIGHT_ASSOCIATIONS },
      },
    });
  }

  return {
    element,
    isSelected,
    setSelected,
    functionalFieldsToDisplay,
    technicalFieldsToDisplay,
    loadingFields: queryFields.state === "loading",
    selectionMode,
    click,
    doubleClick,
    actionClick,
    majClick,
    associationClick,
  };
};
