import React, { FC, ReactNode } from "react";

import { KpkMedia, KpkMetaField, KpkFolder, MediaField } from "@keepeek/commons";
import { BaseComponentProps, dayjs } from "@keepeek/refront-components";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { capitalize } from "lodash";
import isArray from "lodash/isArray";
import trim from "lodash/trim";
import { useTranslation } from "react-i18next";

import logger from "../../../lib/logger-utils";
import FormatTechnicalFolders from "./FormatTechnicalFolders";
import { classes, StyledFormatTechnicalFields } from "./styles";

import "dayjs/plugin/duration";

export type FormatTechnicalFieldsProps = {
  element: KpkMedia;
  technicalFieldsToDisplay: string[];
} & BaseComponentProps;

const FormatTechnicalField: FC<{
  field: KpkMetaField;
  fields: KpkMetaField[] | undefined;
  folders?: KpkFolder[] | undefined;
}> = function ({ field, fields }) {
  const { t } = useTranslation();
  switch (field.id) {
    case MediaField.RESOLUTION: {
      if (field.value === "-1") {
        return null;
      }
      const value = t("mediafield_resolution_dpi", { resolution: field.value });
      return (
        <Typography
          variant="caption"
          className={classes.field}
          sx={{ whiteSpace: "nowrap" }}
          title={field.name}
        >
          {value}
        </Typography>
      );
    }
    case MediaField.DURATION_IN_SECONDS: {
      if (field.value === "" || field.value === "0") {
        return null;
      }
      const duration = Number(isArray(field.value) ? field.value[0] : field.value);
      return (
        <Typography variant="caption" className={classes.field} title={field.name}>
          {dayjs.duration(duration, "seconds").format("H:mm:ss")}
        </Typography>
      );
    }
    case MediaField.WIDTH: {
      const widthValue = field.value;
      const heightValue = fields?.find((t) => t.id === MediaField.HEIGHT)?.value;
      if (widthValue && heightValue && widthValue !== "-1" && heightValue !== "-1") {
        return (
          <Typography variant="caption" title={field.name} className={classes.field}>
            {widthValue}
            <small>x</small>
            {heightValue}
          </Typography>
        );
      }
      return null;
    }
    case MediaField.HEIGHT:
      // do nothing cause it is handled in WIDTH
      return null;
    case MediaField.MEDIA_TYPE: {
      return (
        <Typography
          variant="caption"
          className={clsx(classes.field, classes.mediaType)}
          title={field.name}
        >
          {field.value}
        </Typography>
      );
    }
    case MediaField.FILE_SIZE_STRING: {
      const multi: boolean = field.value.toString().split(" ").length > 1;
      const value: string = multi ? field.value.toString().split(" ")[0] : field.value.toString();
      const size: string = multi ? field.value.toString().split(" ")[1] : "";
      return (
        <Typography variant="caption" className={classes.field} title={field.name}>
          {value}
          <small>{size}</small>
        </Typography>
      );
    }
    case MediaField.EXTENSION: {
      return (
        <Typography
          variant="caption"
          className={clsx(classes.field, classes.mediaType)}
          title={capitalize(field.name)}
        >
          {field.value}
        </Typography>
      );
    }
    case MediaField.IMPORT_DATE:
    case MediaField.UPDATE_DATE:
    case MediaField.TRASH_DATE:
    case MediaField.CREATION_DATE: {
      let val: ReactNode;
      if (field.value && !Array.isArray(field.value)) {
        val = dayjs(field.value).format("LL");
      }
      if (!val) {
        return null;
      }

      return (
        <Typography variant="caption" className={classes.field} title={field.name}>
          {val}
        </Typography>
      );
    }
    default: {
      if (field.value === null || field.value === undefined) {
        logger.debug(`field ${field.name} has no value`);
        return null;
      }
      if (typeof field.value === "string" && trim(field.value).length > 0) {
        return (
          <Typography variant="caption" className={classes.field} title={field.name}>
            {field.value}
          </Typography>
        );
      }
      return (
        <Typography variant="caption" className={classes.field} title={field.name}>
          {[...field.value].join(" _ ")}
        </Typography>
      );
    }
  }
};

const FormatTechnicalFields: FC<React.PropsWithChildren<FormatTechnicalFieldsProps>> = function ({
  element,
  technicalFieldsToDisplay,
  className,
}) {
  const { fields } = element;
  return (
    <StyledFormatTechnicalFields className={clsx("FormatTechnicalFields", classes.root, className)}>
      {technicalFieldsToDisplay.map((fieldToDisplay) => {
        if (fieldToDisplay === MediaField.FOLDER_ID) {
          return <FormatTechnicalFolders key={fieldToDisplay} folders={element.folders} />;
        }
        const field = fields?.find((f) => f.id === fieldToDisplay);
        if (!field) {
          return null;
        }
        return <FormatTechnicalField key={field.id} field={field} fields={fields} />;
      })}
    </StyledFormatTechnicalFields>
  );
};

export default FormatTechnicalFields;
