import React, { FC } from "react";

import { KpkMedia } from "@keepeek/commons";
import { BaseComponentProps } from "@keepeek/refront-components";
import { styled } from "@mui/material/styles";
import clsx from "clsx";

import { KIcon } from "../../../../components/common/KIcon";
import { CONFIGURATION_SECTION_PATH } from "../../../../components/layout/adminMenu";
import { Dataview, ElementIcon } from "../../../../models/configuration/components/dataview";
import { useConfiguration } from "../../../../providers/config/hooks";

const StyledKIcon = styled(KIcon)(({ theme }) => ({
  color: theme.palette.background.paper,
  textShadow: "0 2px 3px rgba(0,0,0,0.5)",
}));

type IconOverMediaProps = { element?: KpkMedia } & BaseComponentProps;

const IconOverMedia: FC<React.PropsWithChildren<IconOverMediaProps>> = function ({
  element,
  testId = "IconOverMedia",
  id,
  className,
}) {
  const dataViewConfig = useConfiguration<Dataview>(CONFIGURATION_SECTION_PATH.COMPONENTS_DATAVIEW);

  if (
    element?.mediaType.startsWith("video/") &&
    dataViewConfig?.elementIcons?.includes(ElementIcon.Video)
  ) {
    return (
      <StyledKIcon
        fontSize="large"
        data-testid={testId}
        id={id}
        className={clsx("IconOverMedia", "IconOverMedia-video", className)}
      >
        play_circle_outline
      </StyledKIcon>
    );
  }
  if (
    element?.mediaType.startsWith("image/") &&
    dataViewConfig?.elementIcons?.includes(ElementIcon.Image)
  ) {
    return (
      <StyledKIcon
        fontSize="large"
        data-testid={testId}
        id={id}
        className={clsx("IconOverMedia", "IconOverMedia-image", className)}
      >
        image
      </StyledKIcon>
    );
  }
  if (
    element?.mediaType.includes("pdf") &&
    dataViewConfig?.elementIcons?.includes(ElementIcon.PDF)
  ) {
    return (
      <StyledKIcon
        fontSize="large"
        data-testid={testId}
        id={id}
        className={clsx("IconOverMedia", "IconOverMedia-pdf", className)}
      >
        picture_as_pdf
      </StyledKIcon>
    );
  }
  return null;
};

export default IconOverMedia;
