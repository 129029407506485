import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { JsonConfig } from "../../../lib/commonPagePropsUtil";
import { getAdminConfigSectionData } from "../../../lib/config/customer-config-utils";
import logger from "../../../lib/logger-utils";
import { KImageWidget } from "../../../models/configuration/components/kImageWidget";
import { Type } from "../../../models/configuration/definitions/widget";

import { KImageWidgetProps } from "./index";

export function translatePosition(position): KImageWidgetProps["position"] | undefined {
  let verticalValue = "";
  let horizontalValue = "";

  if (position?.vertical) {
    if (position.vertical === "bottom") {
      verticalValue = "flex-end";
    } else if (position.vertical === "top") {
      verticalValue = "flex-start";
    } else {
      verticalValue = position.vertical;
    }
  }

  if (position?.horizontal) {
    if (position.horizontal === "right") {
      horizontalValue = "flex-end";
    } else if (position.horizontal === "left") {
      horizontalValue = "flex-start";
    } else {
      horizontalValue = position.horizontal;
    }
  }

  return {
    vertical: verticalValue,
    horizontal: horizontalValue,
    cover: position?.cover,
  };
}

export function translateKImageWidgetConfiguration(
  kImageWidget: KImageWidget,
): KImageWidgetProps | undefined {
  // For SSR, do not return any object undefined cause it can't be used in JSON
  let config: KImageWidgetProps = { widgetId: kImageWidget.id, type: Type.Image };
  const image = kImageWidget.kImageWidgetimage;
  const url = kImageWidget.kImageWidgetUrl;
  const position = translatePosition(kImageWidget.kImageWidgetposition);
  const backgroundColor = kImageWidget.backgroundColor;
  // Include only when defined
  if (image) {
    config = { ...config, image };
  }
  if (url) {
    config = { ...config, url };
  }
  if (position) {
    config = { ...config, position };
  }
  if (backgroundColor) {
    config = { ...config, backgroundColor };
  }

  return config;
}

export function getKImageWidgetConfiguration(
  customerSectionConfiguration: JsonConfig[],
  widgetId: KImageWidget["id"],
): KImageWidgetProps | undefined {
  const kImageWidgets = getAdminConfigSectionData<KImageWidget[]>(
    customerSectionConfiguration,
    CONFIGURATION_SECTION_PATH.COMPONENTS_K_IMAGE_WIDGET,
  );

  if (kImageWidgets?.length === undefined || kImageWidgets.length === 0) {
    logger.info(`getKImageWidgetConfiguration - kImageWidgets is empty or undefined`);
    return;
  }

  const kImageWidget: KImageWidget | undefined = kImageWidgets?.find((kiw) => kiw.id === widgetId);

  if (!kImageWidget) {
    logger.info(`getKImageWidgetConfiguration - can't find kImageWidget id ${widgetId}`);
    return;
  }

  return translateKImageWidgetConfiguration(kImageWidget);
}
