export interface Mosaic {
    animation?:        Animation;
    backgroundColor?:  string;
    borderRadius?:     string;
    carousel?:         boolean;
    centeredMosaics?:  boolean;
    content?:          TranslationSchema[];
    detailFullScreen?: boolean;
    folderIds?:        number[];
    id:                string;
    labelCustom?:      LabelCustom;
    mosaicElements?:   MosaicElement[];
    mosaicMode?:       string;
    showSubTitle?:     boolean;
    size:              Size;
    title?:            string;
}

export interface Animation {
    animationType?: AnimationType;
}

export enum AnimationType {
    NoAnimation = "NO_ANIMATION",
    ZoomOnHover = "ZOOM_ON_HOVER",
}

export interface TranslationSchema {
    empty?:      boolean;
    language:    Language;
    ressources?: any;
    value?:      string;
}

export enum Language {
    De = "de",
    En = "en",
    Es = "es",
    Fr = "fr",
    It = "it",
}

export interface LabelCustom {
    backgroundTextColor?:  string;
    backgroundTextEffect?: BackgroundTextEffect;
    horizontalTextAlign?:  HorizontalTextAlign;
    textColor?:            string;
    textSize?:             string;
    verticalTextAlign?:    VerticalTextAlign;
}

export enum BackgroundTextEffect {
    BottomEffect = "BOTTOM_EFFECT",
    NoBackground = "NO_BACKGROUND",
    TopEffect = "TOP_EFFECT",
    Unicolor = "UNICOLOR",
}

export enum HorizontalTextAlign {
    Center = "CENTER",
    Left = "LEFT",
    Right = "RIGHT",
}

export enum VerticalTextAlign {
    Bottom = "BOTTOM",
    Center = "CENTER",
    Top = "TOP",
    UnderMosaic = "UNDER_MOSAIC",
}

export interface MosaicElement {
    folderId?:    number;
    href?:        string;
    id?:          string;
    linkType?:    LinkType;
    targetBlank?: boolean;
    title?:       string;
    url?:         string;
}

export enum LinkType {
    Didomi = "DIDOMI",
    Folder = "FOLDER",
    Mail = "MAIL",
    URL = "URL",
}

export enum Size {
    L = "L",
    M = "M",
    S = "S",
    Xl = "XL",
}
