import React, { FC } from "react";

import { KpkMedia } from "@keepeek/commons";
import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import dayjs from "dayjs";
import { isArray } from "lodash";

import { VisualAlertsSchema } from "../../../../models/configuration/pages/search";

const PREFIX = "VisualAlert";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledVisualAlert = styled(Chip)<
  Pick<
    VisualAlertProps,
    | "backgroundColor"
    | "fontSize"
    | "padding"
    | "marginTop"
    | "marginRight"
    | "marginBottom"
    | "marginLeft"
    | "borderRadius"
  > & {
    customColor: VisualAlertProps["color"];
  }
>(
  ({
    theme,
    backgroundColor,
    customColor,
    fontSize,
    padding,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    borderRadius,
  }) => ({
    backgroundColor: isDefined(backgroundColor) ? `${backgroundColor}` : theme.palette.primary.main,
    color: isDefined(customColor) ? `${customColor}` : theme.palette.common.white,
    fontSize: isDefined(fontSize) ? `${fontSize}rem` : "0.8rem",
    padding: isDefined(padding) ? `${padding}px` : "5px",
    marginTop: isDefined(marginTop) ? `${marginTop}px` : theme.spacing(0.6),
    marginRight: isDefined(marginRight) ? `${marginRight}px` : theme.spacing(0.6),
    marginBottom: isDefined(marginBottom) ? `${marginBottom}px` : theme.spacing(0.6),
    marginLeft: isDefined(marginLeft) ? `${marginLeft}px` : theme.spacing(0.6),
    borderRadius: isDefined(borderRadius) ? `${borderRadius}px` : "0",
    "& .MuiChip-label": {
      padding: 0,
    },
  }),
);

export type VisualAlertProps = {
  text: string;
  element?: KpkMedia;
} & Omit<VisualAlertsSchema, "text">;

/**
 *
 * @param confParameter the value recieved from the admin
 * @returns true if the parameter has been defined by the user
 */
const isDefined = (confParameter: string | number | undefined): boolean => {
  return confParameter !== undefined;
};

/**
 * Component representing a "Visual Alert", it's a customizable "Chip", like a tag
 */
const VisualAlert: FC<React.PropsWithChildren<VisualAlertProps>> = function VisualAlert({
  children: _children,
  text,
  color,
  dateTargetMode,
  dateTargetNumber,
  dateTargetUnit,
  fieldType,
  position,
  fieldInternalName,
  element,
  backgroundColor,
  borderRadius,
  fontSize,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  padding,
}) {
  const title = () => {
    if (text.includes("{{") && text.includes("}}")) {
      const variable = text.split("{{")[1].split("}}")[0];
      let content = element?.fields?.find((f) => f.id === variable)?.value;
      content = isArray(content) ? content[0] : content || "";
      if (content.split("-").length >= 2 && typeof Number(content.charAt(0)) === "number") {
        content = dayjs(content).format("DD/MM/YYYY");
      }
      return text.replace(`{{${variable}}}`, content);
    }
    return text;
  };

  return (
    <StyledVisualAlert
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      fontSize={fontSize}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginTop={marginTop}
      padding={padding}
      className={clsx(
        classes.root,
        "VisualAlert",
        fieldType && `VisualAlert-${fieldType}`,
        dateTargetUnit && `VisualAlert-${dateTargetUnit}`,
        fieldInternalName && `VisualAlert-${fieldInternalName}`,
        position && `VisualAlert-${position}`,
      )}
      size="small"
      label={title()}
      customColor={color}
      title={title()}
      data-date-target-number={dateTargetNumber}
      data-date-target-unit={dateTargetUnit}
      data-date-target-mode={dateTargetMode}
      data-field-internal-name={fieldInternalName}
    />
  );
};

export default VisualAlert;
