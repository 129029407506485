import React, { FC } from "react";

import { FunctionOverrideKey, KpkMedia, useCurrentUser, useShareToken } from "@keepeek/commons";
import { KActionButton } from "@keepeek/refront-components";
import { Box } from "@mui/material";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { KIcon } from "../../../../components/common/KIcon";
import { CONFIGURATION_SECTION_PATH } from "../../../../components/layout/adminMenu";
import { ActionValue } from "../../../../components/search/Dataview/Actions";
import { getCustomerOverrideFunction } from "../../../../lib/overrides";
import { ShareManager } from "../../../../models/configuration/components/shareManager";
import { useConfiguration } from "../../../../providers/config/hooks";
import { configSectionPageSearchSelector } from "../../../../providers/config/selectors";
import { MEDIA_TYPE_RICH_ELEMENT } from "../../../Element/MediaDetailHeaderActions";

export type ElementActionsProps = {
  onActionClick: (value: ActionValue) => void;
  onActionMouseEnter?: (value: ActionValue) => void;
  element: KpkMedia;
};

const ElementActions: FC<React.PropsWithChildren<ElementActionsProps>> = function ({
  onActionClick,
  onActionMouseEnter = () => {},
  element,
}) {
  const { t } = useTranslation();
  const router = useRouter();
  const { isShare, shareTokenDownloadAuthorized } = useShareToken(
    router.pathname,
    router.query.tokenId,
  );
  const { authorizedActions } = useRecoilValue(configSectionPageSearchSelector) ?? {
    authorizedActions: undefined,
  };
  const customerElementActions = getCustomerOverrideFunction(FunctionOverrideKey.ElementActions, {
    elementId: element.id,
  });
  const shareManager = useConfiguration<ShareManager>(
    CONFIGURATION_SECTION_PATH.COMPONENTS_SHARE_MANAGER,
  );

  const { hasPublicShare } = useCurrentUser();

  return (
    <Box className="ElementActions" sx={{ minHeight: (theme) => theme.spacing(3) }}>
      {!isShare && shareManager?.unitaryPublicShare?.active && hasPublicShare && (
        <KActionButton
          onClick={() => onActionClick(ActionValue.SHARE)}
          title={t("dataview.action.unitary-public-share.label") as string}
          aria-label={t("dataview.action.unitary-public-share.label") as string}
          actionButtonName={ActionValue.SHARE}
        >
          <KIcon fontSize="small" baseClassName="fa" className="fa-share" />
        </KActionButton>
      )}
      {authorizedActions &&
        authorizedActions.attachments &&
        !!element.attachmentCounts &&
        !!element.attachmentCounts.online &&
        element.attachmentCounts.online > 0 &&
        (customerElementActions?.attachment ?? true) && (
          <KActionButton
            onClick={() => onActionClick(ActionValue.SHOW_ATTACHMENT)}
            onMouseEnter={() => onActionMouseEnter(ActionValue.SHOW_ATTACHMENT)}
            aria-label={t("dataview.action.show-attachment.label") as string}
            title={t("dataview.action.show-attachment.label") as string}
            actionButtonName={ActionValue.SHOW_ATTACHMENT}
          >
            <KIcon fontSize="small">attachment</KIcon>
          </KActionButton>
        )}
      {element.mediaType !== MEDIA_TYPE_RICH_ELEMENT &&
        (customerElementActions?.download ?? true) &&
        (!isShare || (isShare && shareTokenDownloadAuthorized)) && (
          <KActionButton
            onClick={() => onActionClick(ActionValue.DOWNLOAD)}
            onMouseEnter={() => onActionMouseEnter(ActionValue.DOWNLOAD)}
            aria-label={t("dataview.action.download.label", { count: 1 }) as string}
            title={t("dataview.action.download.label", { count: 1 }) as string}
            actionButtonName={ActionValue.DOWNLOAD}
          >
            <KIcon fontSize="small">cloud_download</KIcon>
          </KActionButton>
        )}
      {((!isShare && customerElementActions?.basket) ?? true) && (
        <KActionButton
          onClick={() => onActionClick(ActionValue.ADD_TO_BASKET)}
          onMouseEnter={() => onActionMouseEnter(ActionValue.ADD_TO_BASKET)}
          aria-label={t("dataview.action.add-to-basket.label", { count: 1 }) as string}
          title={t("dataview.action.add-to-basket.label", { count: 1 }) as string}
          actionButtonName={ActionValue.ADD_TO_BASKET}
        >
          <KIcon fontSize="small">shopping_basket</KIcon>
        </KActionButton>
      )}
    </Box>
  );
};

export default ElementActions;
