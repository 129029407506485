import { KpkMedia } from "@keepeek/commons";
import { dayjs } from "@keepeek/refront-components";
import { UnitTypeShort } from "dayjs";

import logger from "../../../../lib/logger-utils";
import {
  DateTargetMode,
  DateTargetUnit,
  FieldType,
  Position,
  VisualAlertsSchema,
} from "../../../../models/configuration/definitions/visualAlertsSchema";
import { VisualAlertProps } from "../VisualAlert";

/**
 *
 * @param visualAlertsConf Visual Alert configuration from Admin ReFront
 * @param element media
 * @param currentLocale locale
 * @returns list of VisualAlertProps by Position (only position with visualAlerts are present in the map)
 */
export function getVisualAlertsDataByPosition(
  visualAlertsConf: VisualAlertsSchema[] | undefined,
  element: KpkMedia,
  currentLocale: string | undefined = "fr",
  contextDescriptionForLogger: string = "",
): Map<Position, VisualAlertProps[]> {
  const visualAlertsDataByPosition = new Map<Position, VisualAlertProps[]>();
  for (const positionAsString in Position) {
    const position: Position = Position[positionAsString];
    const visualAlertsDataForCurrentPosition: VisualAlertProps[] = getVisualAlertsData(
      visualAlertsConf,
      element,
      currentLocale,
      position,
    );
    if (visualAlertsDataForCurrentPosition?.length) {
      visualAlertsDataByPosition.set(position, visualAlertsDataForCurrentPosition);
    }
  }

  if (visualAlertsDataByPosition.size) {
    // only log visual alerts map by position if the media have some, to not pollute logs
    logger.debug(
      `[getVisualAlertsDataByPosition]${contextDescriptionForLogger} media: ${element?.id}, visualAlertsDataByPosition:`,
      visualAlertsDataByPosition,
    );
  }

  return visualAlertsDataByPosition;
}

/**
 *
 * @param visualAlertsConf Visual Alert configuration from Admin ReFront
 * @param element media
 * @param currentLocale locale
 * @param position wished position
 * @returns A list of VisualAlertProps for potentials VisualAlerts of the media
 */
export function getVisualAlertsData(
  visualAlertsConf: VisualAlertsSchema[] | undefined,
  element: KpkMedia,
  currentLocale: string | undefined = "fr",
  position: Position,
): VisualAlertProps[] {
  let visualAlerts: VisualAlertProps[] = [];

  if (!visualAlertsConf) {
    return visualAlerts;
  }

  visualAlerts =
    visualAlertsConf
      .filter((visualAlertConf) => {
        if (
          (!visualAlertConf?.position && position === Position.TopRight) ||
          (visualAlertConf?.position && visualAlertConf.position === position)
        ) {
          // if no conf about position is present (old configurations) and the wished position by component is TOP_RIGHT
          // if the position wished by component is the one in the current configuration
          return true;
        }
        return false;
      })
      .filter((visualAlertConf) => {
        // depending on the type of the field check if the rules are respected and if the Visual Alert has to be displayed
        if (visualAlertConf.fieldType === FieldType.Date) {
          return isDateEligibleToDisplayVisualAlert(
            element,
            visualAlertConf.fieldInternalName,
            visualAlertConf.dateTargetMode,
            visualAlertConf.dateTargetNumber,
            visualAlertConf.dateTargetUnit,
          );
        } else if (visualAlertConf.fieldType === FieldType.SubStatus) {
          return isSubStatusEligibleToDisplayVisualAlert(element, visualAlertConf.subStatusId);
        }
        return false;
      })
      .map((visualAlertConf) => {
        const textValueForCurrentLocale =
          visualAlertConf.text.find((t) => t.language.toString().toLowerCase() === currentLocale)
            ?.value || "";
        const fieldValue = getFieldValue(element, visualAlertConf.fieldInternalName) || "";

        const textValueToDisplay = computeTextToDisplay(
          textValueForCurrentLocale,
          fieldValue,
          visualAlertConf.displayFieldValue,
        );

        const visualAlert: VisualAlertProps = {
          ...visualAlertConf,
          text: textValueToDisplay,
        };

        return visualAlert;
      }) || [];
  return visualAlerts;
}

/**
 *
 *                                                                                      \
 * --------------------------------------------------------------------------------------\
 *     --- EXPIRED_SINCE_TARGETTED_DATE ---- today ---- EXPIRE_WITHIN_TARGETTED_DATE ---- >
 * --------------------------------------------------------------------------------------/
 *                                                                                      /
 *
 * @param element the media
 * @param fieldInternalName the internal name to get value from
 * @param dateTargetMode to check if a date is expired and since when, or if a date expire in the next [...]
 * @param dateTargetNumber number of days, months etc
 * @param dateTargetUnit date unit (days, month etc)
 * @returns whether or not a Virtual Alert has to be displayed according to the date parameters
 */
export function isDateEligibleToDisplayVisualAlert(
  element: KpkMedia,
  fieldInternalName: string,
  dateTargetMode: DateTargetMode | undefined,
  dateTargetNumber: number | undefined,
  dateTargetUnit: DateTargetUnit | undefined,
): boolean {
  if (!dateTargetMode || !dateTargetNumber || !dateTargetUnit) {
    return false;
  }

  const fieldValue = getFieldValue(element, fieldInternalName);

  if (!fieldValue) {
    return false;
  }

  const fieldValueAsDate = new Date(`${fieldValue}`);
  const fieldValueAsDateJs = dayjs(fieldValueAsDate);
  const todayDate = new Date();
  const todayDateJs = dayjs(todayDate);

  // this is an enum from conf, no risks to have this not
  let dayJsUnitTechnicalKey: UnitTypeShort = "d";
  if (dateTargetUnit === DateTargetUnit.Months) {
    dayJsUnitTechnicalKey = "M";
  } else if (dateTargetUnit === DateTargetUnit.Years) {
    dayJsUnitTechnicalKey = "y";
  }

  switch (dateTargetMode) {
    case DateTargetMode.ExpiredSince:
      const targettedExpiredDateJs = todayDateJs.subtract(dateTargetNumber, dayJsUnitTechnicalKey);
      return targettedExpiredDateJs.isAfter(fieldValueAsDateJs);
    case DateTargetMode.ExpiredSinceLessThan:
      const diffBetweenTodayAndFieldDateValue = todayDateJs.diff(
        fieldValueAsDateJs,
        dayJsUnitTechnicalKey,
      );
      return (
        diffBetweenTodayAndFieldDateValue > 0 &&
        diffBetweenTodayAndFieldDateValue < dateTargetNumber
      );
    case DateTargetMode.ExpireWithin:
      const targetteExpireWithinDateJs = todayDateJs.add(dateTargetNumber, dayJsUnitTechnicalKey);
      const value =
        todayDateJs.isBefore(fieldValueAsDateJs) &&
        targetteExpireWithinDateJs.isAfter(fieldValueAsDateJs);
      return value;
    default:
      return false;
  }
}

/**
 *
 * @param element the media
 * @param subStatusId sub status id to requried to display the media
 * @returns whether or not a Virtual Alert has to be displayed according to the sub status
 * @returns
 */
export function isSubStatusEligibleToDisplayVisualAlert(
  element: KpkMedia,
  subStatusId: number | undefined,
): boolean {
  return element?.subStatusId === subStatusId;
}

/**
 *
 * @param visualAlertText visualAlertText in current locale configured
 * @param fieldValue the value of the field
 * @param displayFieldValue if true the field value will be added next to the text
 * @returns the text value to display in the Visual Alert (for date field, the value will be formatted)
 */
function computeTextToDisplay(
  visualAlertText: string,
  fieldValue: string,
  displayFieldValue: boolean | undefined,
): string {
  if (displayFieldValue && fieldValue) {
    // if the field value is a date, format it
    if (Date.parse(fieldValue?.toString() || "")) {
      const fieldValueAsDate = new Date(fieldValue?.toString() || "");
      const fieldValueAsDateFormatted = dayjs(fieldValueAsDate).format("LL");
      visualAlertText += ` (${fieldValueAsDateFormatted})`;
    } else {
      visualAlertText += ` (${fieldValue})`;
    }
  }

  return visualAlertText;
}

function getFieldValue(element: KpkMedia, fieldInternalName: string): string | undefined {
  return element.fields?.find((f) => f.id === fieldInternalName)?.value?.toString();
}
