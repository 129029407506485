import { BasketKey, DataViewKey } from "@keepeek/commons";
import { selectorFamily } from "recoil";

import { SORT_LIST_DEFAULT_FIELDS } from "../../config/hooks";
import { configSectionPageSearchSelector } from "../../config/selectors";
import { dataViewSort } from "../atoms";

export const dataViewSortSelector = selectorFamily<string, DataViewKey | BasketKey>({
  key: "DataViewSortSelector",
  get:
    (key) =>
    ({ get }) => {
      const { fields: configFields } = get(configSectionPageSearchSelector)?.sort ?? {};
      const defaultConfigField = configFields?.find((f) => f.default);
      const defaultField = SORT_LIST_DEFAULT_FIELDS.find((f) => f.default);
      const sort = get(dataViewSort(key));
      if (sort) {
        return sort;
      } else if (defaultConfigField) {
        return `${defaultConfigField.field}-${defaultConfigField.direction}`;
      } else {
        return `${defaultField?.field}-${defaultField?.direction}`;
      }
    },
  set:
    (key) =>
    ({ set }, newValue: string) => {
      set(dataViewSort(key), newValue);
    },
});
