import { useEffect, useMemo } from "react";

import {
  anticipatedRefreshState,
  KCConsumerStatus,
  keycloakInstanceAtom,
  useAuth,
  useAuthInit,
  useAuthRefresh,
  useStatsConnectionHit,
} from "@keepeek/commons";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { PagesPath } from "../../../containers/App/utils";
import { useInitConfig } from "../../config/hooks/init";
import { frontEditState } from "../../frontEdit/atoms/frontEdit";
import { importModalOpenState } from "../../import/atoms";
import { useAuthProps } from "./useAuthProps";
import { useKeycloakConfig } from "./useKeycloakConfig";

type UseAuthProviderProps = {
  pathname: string;
  unauthorizedCallback?: () => void;
};

export const useAuthProvider = ({
  pathname,
  unauthorizedCallback = () => {},
}: UseAuthProviderProps) => {
  const { keycloakConfig, loading: loadingKeycloakConfig } = useKeycloakConfig();
  const keycloakInstance = useRecoilValue(keycloakInstanceAtom);
  useAuthInit(keycloakConfig, { disableSilentCheckSso: true });
  const { loading: loadingConfig, initConfig } = useInitConfig();
  const setAnticipatedRefresh = useSetRecoilState(anticipatedRefreshState);
  const { refresh } = useAuthRefresh();
  const {
    loading: loadingAuth,
    isAuthenticated: { isAuthenticated, loading: loadingPourLePlaiser },
  } = useAuth(useAuthProps());

  const isSecuredPath = useMemo(() => {
    if (
      [PagesPath.ELEMENT_PAGE, PagesPath.HOME_PAGE, PagesPath.SEARCH_PAGE].includes(
        pathname as PagesPath,
      ) ||
      (pathname.startsWith(PagesPath.ADMIN) && !pathname.includes(PagesPath.ONBOARDING))
    ) {
      return true;
    }
    return false;
  }, [pathname]);

  const loading: boolean = useMemo(
    () =>
      (loadingKeycloakConfig ||
        loadingConfig ||
        !keycloakConfig ||
        loadingAuth ||
        loadingPourLePlaiser) &&
      !isAuthenticated,
    [
      loadingKeycloakConfig,
      loadingConfig,
      keycloakConfig,
      loadingAuth,
      loadingPourLePlaiser,
      isAuthenticated,
    ],
  );

  const status: KCConsumerStatus = useMemo(() => {
    if (isSecuredPath) {
      if (isAuthenticated) {
        return KCConsumerStatus.Authorized;
      }
      if (loading) {
        return KCConsumerStatus.Loading;
      }
      return KCConsumerStatus.Unauthorized;
    }
    return KCConsumerStatus.Authorized;
  }, [isAuthenticated, isSecuredPath, loading]);

  useEffect(() => {
    if (status === KCConsumerStatus.Unauthorized) {
      setAnticipatedRefresh(false);
      unauthorizedCallback();
    }
  }, [status, unauthorizedCallback, setAnticipatedRefresh]);

  const isImporting = useRecoilValue(importModalOpenState);
  const isInFrontEditMode = useRecoilValue(frontEditState);

  useEffect(() => {
    if (
      (pathname.startsWith(PagesPath.ADMIN) && !pathname.includes(PagesPath.ONBOARDING)) ||
      isInFrontEditMode ||
      isImporting
    ) {
      refresh();
      setAnticipatedRefresh(true);
    } else {
      setAnticipatedRefresh(false);
    }
  }, [pathname, setAnticipatedRefresh, isInFrontEditMode, isImporting, refresh]);

  const { sendConnectionHit } = useStatsConnectionHit({ ip: initConfig?.clientIp });

  useEffect(() => {
    // If we have a sessionId try to send the connection hit
    // The sendConnectionHit will resolve automatically if we need to send it or not
    if (keycloakInstance?.sessionId) {
      sendConnectionHit();
    }
  }, [sendConnectionHit, keycloakInstance?.sessionId]);

  return { loading, status, isSecuredPath };
};
