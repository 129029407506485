import { KpkApiForm, KpkApiFormFieldType } from "@keepeek/api-client";
import {
  BasketKey,
  DataViewKey,
  elementQuerySelector,
  FetcherMode,
  fetcherModeAtom,
  formQuerySelector,
  formsQuerySelector,
  KpkMedia,
} from "@keepeek/commons";
import uniqBy from "lodash/uniqBy";
import { selectorFamily } from "recoil";

import { getFieldsToDisplay, getFieldToDisplay, getMetamodelType } from "../../lib/field-utils";
import logger from "../../lib/logger-utils";
import { serializeComplexeParamsForRecoil } from "../../lib/recoil-utils";
import { Field } from "../../models/configuration/definitions/field";
import { Fields } from "../../models/configuration/definitions/fields";
import {
  FieldsSections,
  FieldsSectionsItemSchema,
  Metamodel as MetamodelFieldsSections,
  MetamodelTypeObject as MetamodelTypeObjectFieldsSections,
  Type,
} from "../../models/configuration/definitions/fieldsSections";
import { Language, SectionSchema } from "../../models/configuration/pages/element";
import { backContextState } from "../backContext/atoms";
import { currentBasketIdState } from "../basket/atoms/list";
import { activeBusinessFiltersFromURLSelector } from "../businessFilter/selectors/activeFilters";
import { createBasketBusinessFilter } from "../businessFilter/utils/create/createBasketBusinessFilter";
import {
  configSectionComponentBusinessFilterSelector,
  configSectionPageElementSelector,
} from "../config/selectors";
import { dataViewPage, dataViewSize } from "../dataView/atoms";
import { dataViewElementsSelector } from "../dataView/selectors/elements";
import { dataViewSortSelector } from "../dataView/selectors/sort";

export type ElementFieldsSelectorType = {
  fieldsSectionsToDisplay: FieldsSections;
  headerFunctionalFields: Fields;
  headerTechnicalFields: Fields;
  headerSideField: Field;
};

export type ElementAndFieldsToDisplaySelectorType = {
  fields: {
    fieldsSectionsToDisplay: FieldsSectionsItemSchema[];
    headerFunctionalFields: string[];
    headerTechnicalFields: string[];
    headerSideField: string;
  };
  element: KpkMedia | undefined;
};

export const defaultReturnElementAndFields: ElementAndFieldsToDisplaySelectorType = {
  fields: {
    fieldsSectionsToDisplay: [],
    headerFunctionalFields: [],
    headerTechnicalFields: [],
    headerSideField: "",
  },
  element: undefined,
};

export const elementAndFieldsToDisplaySelector = selectorFamily<
  ElementAndFieldsToDisplaySelectorType,
  { language: Language; elementId: KpkMedia["id"] | undefined }
>({
  key: "ElementAndFieldsToDisplaySelector",
  get:
    ({ language, elementId }) =>
    ({ get }) => {
      if (!elementId) {
        return defaultReturnElementAndFields;
      }
      const element = get(
        elementQuerySelector({
          elementId,
          embeddedFolders: true,
        }),
      );

      if (!element) {
        return defaultReturnElementAndFields;
      }

      const {
        fieldsSectionsToDisplay = {},
        headerFunctionalFields = {},
        headerTechnicalFields = {},
        headerSideField = {},
        sections,
      } = get(configSectionPageElementSelector) || {};

      const forms = get(formsQuerySelector);
      let fieldsSectionsToDisplayComputed: FieldsSectionsItemSchema[] = [];
      if (fieldsSectionsToDisplay.backofficeConfiguration) {
        fieldsSectionsToDisplayComputed = buildFieldsSections(
          get(formQuerySelector({ id: element.formId })),
          get(fetcherModeAtom),
        );
      } else {
        fieldsSectionsToDisplayComputed =
          getFieldsSectionsToDisplay(
            fieldsSectionsToDisplay as FieldsSections,
            element,
            forms,
            language,
            sections,
          ) || [];
      }

      const fields = {
        fieldsSectionsToDisplay: fieldsSectionsToDisplayComputed,
        headerFunctionalFields: getFieldsToDisplay(headerFunctionalFields, element, forms) || [],
        headerTechnicalFields: getFieldsToDisplay(headerTechnicalFields, element, forms) || [],
        headerSideField: getFieldToDisplay(headerSideField, element, forms) || "",
      };

      logger.debug(
        `Fields for media ${element?.id} (form = ${element?.formId}, type = ${element?.mediaType}) `,
        fields,
        element,
      );

      return { fields, element };
    },
});

export const previousElementIdSelector = selectorFamily<number | null, KpkMedia["id"] | undefined>({
  key: "PreviousElementIdSelector",
  get: (elementId) =>
    function ({ get }) {
      const context = get(backContextState);
      const dataViewKey = context.key ?? DataViewKey.SEARCH;
      const currentBasketId = get(currentBasketIdState(BasketKey.PANEL));
      let filtersBasket;
      if (currentBasketId && dataViewKey === DataViewKey.BASKET) {
        filtersBasket = [createBasketBusinessFilter(currentBasketId)];
      }
      const elements = get(
        dataViewElementsSelector(
          serializeComplexeParamsForRecoil({
            key: dataViewKey,
            size: get(dataViewSize(dataViewKey)),
            page: get(dataViewPage(dataViewKey)),
            sort: get(dataViewSortSelector(dataViewKey)),
            filtersConfiguration:
              get(configSectionComponentBusinessFilterSelector)?.filters || null,
            // this only work because we're not resetting the activeBusinessFiltersFromURLSelector on another page than the "search" page
            // The search page include useLBFInit that handle the set/reset of filtesr
            // Be consious, that it can be "fragile" to use activeBusinessFiltersFromURLSelector as-is
            // we should maybe store a version of the active filters for this case
            filters: filtersBasket ?? get(activeBusinessFiltersFromURLSelector),
          }),
        ),
      );
      if (elements.length > 0 && elementId) {
        const index = elements.findIndex((media) => elementId === media.id);

        if (index > 0) {
          return elements[index - 1].id;
        }
      }

      return null;
    },
});

export const nextElementIdSelector = selectorFamily<number | null, KpkMedia["id"] | undefined>({
  key: "NextElementIdSelector",
  get: (elementId) =>
    function ({ get }) {
      const context = get(backContextState);
      const dataViewKey = context.key ?? DataViewKey.SEARCH;
      const currentBasketId = get(currentBasketIdState(BasketKey.PANEL));
      let filtersBasket;
      if (currentBasketId && dataViewKey === DataViewKey.BASKET) {
        filtersBasket = [createBasketBusinessFilter(currentBasketId)];
      }
      const elements = get(
        dataViewElementsSelector(
          serializeComplexeParamsForRecoil({
            key: dataViewKey,
            size: get(dataViewSize(dataViewKey)),
            page: get(dataViewPage(dataViewKey)),
            sort: get(dataViewSortSelector(dataViewKey)),
            filtersConfiguration:
              get(configSectionComponentBusinessFilterSelector)?.filters || null,
            // this only work because we're not resetting the activeBusinessFiltersFromURLSelector on another page than the "search" page
            // The search page include useLBFInit that handle the set/reset of filtesr
            // Be consious, that it can be "fragile" to use activeBusinessFiltersFromURLSelector as-is
            // we should maybe store a version of the active filters for this case
            filters: filtersBasket ?? get(activeBusinessFiltersFromURLSelector),
          }),
        ),
      );

      if (elements.length > 0 && elementId) {
        const index = elements.findIndex((media) => elementId === media.id);

        if (index < elements.length - 1) {
          return elements[index + 1].id;
        }
      }

      return null;
    },
});

const buildFieldsSections = (
  form: KpkApiForm | null,
  fetcherMode: FetcherMode,
): FieldsSectionsItemSchema[] => {
  if (!form) {
    return [];
  }
  return (
    form._embedded?.field.flatMap((field) => {
      if (
        !field.id ||
        !field.title ||
        (fetcherMode === FetcherMode.LOGGED && !field.portalDisplay)
      ) {
        return [];
      }
      return [
        {
          type: field.type === KpkApiFormFieldType.FieldSet ? Type.Section : Type.Field,
          value: field.type === KpkApiFormFieldType.FieldSet ? field.title : field.id,
        },
      ];
    }) || []
  );
};

export function getFieldsSectionsToDisplay(
  fieldsSectionsConfiguration: FieldsSections,
  element: KpkMedia,
  forms: KpkApiForm[] | null,
  language: Language,
  sections: SectionSchema[] | undefined,
): FieldsSectionsItemSchema[] | undefined {
  const ret: FieldsSectionsItemSchema[] = [];
  if (!fieldsSectionsConfiguration || !element) {
    return ret;
  }

  if (fieldsSectionsConfiguration.metamodel) {
    const conf: MetamodelFieldsSections | undefined = fieldsSectionsConfiguration.metamodel?.find(
      (conf) => conf.metamodelId.includes(element.formId),
    );

    if (conf) {
      conf.fieldsSections?.forEach((fieldSection) =>
        ret.push(addI18nSection(language, sections, fieldSection)),
      );
      return uniqBy(ret, (ite) => `${ite.value}-${ite.type}`);
    }
  }

  if (fieldsSectionsConfiguration.metamodelType) {
    const metamodelType = getMetamodelType(element, forms);
    if (metamodelType) {
      const conf: MetamodelTypeObjectFieldsSections | undefined =
        fieldsSectionsConfiguration.metamodelType.find((conf) =>
          conf.metamodelType.includes(metamodelType),
        );

      if (conf) {
        conf.fieldsSections?.forEach((fieldSection) =>
          ret.push(addI18nSection(language, sections, fieldSection)),
        );
        return uniqBy(ret, (ite) => `${ite.value}-${ite.type}`);
      }
    }
  }

  fieldsSectionsConfiguration.default?.forEach((conf) =>
    ret.push(addI18nSection(language, sections, conf)),
  );
  return uniqBy(ret, (ite) => `${ite.value}-${ite.type}`);
}

function addI18nSection(
  language: Language,
  sections: SectionSchema[] | undefined,
  fieldsSectionsItem: FieldsSectionsItemSchema,
): FieldsSectionsItemSchema {
  if (fieldsSectionsItem.type === Type.Section) {
    const section =
      sections &&
      sections
        .find((s) => s.id === fieldsSectionsItem.value)
        ?.translation?.find((t) => t.language === language);
    return {
      type: Type.Section,
      value: section?.value || fieldsSectionsItem.value,
    };
  } else {
    return {
      type: Type.Field,
      value: fieldsSectionsItem.value,
    };
  }
}
