import { KpkBasket } from "@keepeek/commons";
import { selectorFamily } from "recoil";

import logger from "../../../lib/logger-utils";

export const shareBasketsSelector = selectorFamily<[], KpkBasket["id"][]>({
  key: "ShareBasketsSelector",
  get: (basketIds) => () => {
    logger.debug(
      "shareBasketsSelector - currently not supported, we should move shareBasket here",
      basketIds,
    );
    return [];
  },
});
