import React, { FC, memo } from "react";

import { DataViewKey, FunctionOverrideKey, KpkMedia, MediaField } from "@keepeek/commons";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { isArray } from "lodash";
import { useRouter } from "next/router";
import { useRecoilValue } from "recoil";

import FormatTechnicalFields from "../../../../components/common/FormatTechnicalFields";
import { CONFIGURATION_SECTION_PATH } from "../../../../components/layout/adminMenu";
import { ElementSummary } from "../../../../components/refront-components-overrides";
import { ActionValue } from "../../../../components/search/Dataview/Actions";
import VisualAlerts from "../../../../components/search/Dataview/VisualAlerts";
import { getVisualAlertsDataByPosition } from "../../../../components/search/Dataview/VisualAlerts/utils";
import { getCustomerOverrideFunction } from "../../../../lib/overrides";
import { Dataview } from "../../../../models/configuration/components/dataview";
import { Position } from "../../../../models/configuration/definitions/visualAlertsSchema";
import { ELEMENT_SUMMARY_ID_PREFIX } from "../../../../providers/backContext/constants";
import { useConfiguration } from "../../../../providers/config/hooks";
import { configSectionPageSearchSelector } from "../../../../providers/config/selectors";
import { useDataViewElement } from "../../../../providers/dataViewElement/hooks";
import FormatElementId from "../../../FormatElementId";
import ElementActions from "./ElementActions";
import ElementLinks from "./ElementLinks";
import ElementsMetaField from "./ElementsMetaField";
import IconOverMedia from "./IconOverMedia";

const PREFIX = "MemoizedElementSummary";
const classes = {
  root: `${PREFIX}-root`,
  visualAlertBottomLeft: `${PREFIX}-visualAlertBottomLeft`,
  visualAlertBottomRight: `${PREFIX}-visualAlertBottomRight`,
  bottomLeft: `${PREFIX}-bottomLeft`,
};

const StyledElementSummary = styled(ElementSummary)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    "& > div:last-child": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
  },
  [`& .${classes.visualAlertBottomLeft}`]: {
    alignItems: "flex-start",
  },
  [`& .${classes.visualAlertBottomRight}`]: {
    alignItems: "flex-end",
  },
  [`& .${classes.bottomLeft}`]: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    font: "14px 'Open Sans',Tahoma,Arial,Verdana,sans-serif",
    padding: theme.spacing(0.3),
  },
}));

type MemoizedElementSummary = {
  id: KpkMedia["id"];
  isShare: boolean;
  shareTokenDownloadAuthorized: boolean;
};

const MemoizedElementSummary: FC<React.PropsWithChildren<MemoizedElementSummary>> = function ({
  id,
  isShare,
  shareTokenDownloadAuthorized,
}) {
  const {
    element,
    isSelected,
    functionalFieldsToDisplay: functionalFieldsToDisplayBeforeCustomerHook,
    technicalFieldsToDisplay,
    loadingFields,
    selectionMode,
    click,
    doubleClick,
    actionClick,
    majClick,
    associationClick,
  } = useDataViewElement(DataViewKey.SEARCH, id);

  function handleCardClick(e: React.MouseEvent<HTMLElement, MouseEvent>): void {
    if (selectionMode) {
      // Detect keyboard shortcut
      // Shift pressed during the click
      if (e.shiftKey) {
        majClick();
      } else {
        click();
      }
    }
  }

  const dataViewConfig = useConfiguration<Dataview>(CONFIGURATION_SECTION_PATH.COMPONENTS_DATAVIEW);
  const searchConfig = useRecoilValue(configSectionPageSearchSelector);

  const { locale: currentLocale } = useRouter();

  // In case the id doesn't match an element in the store
  if (!element) {
    return null;
  }

  const visualAlertsDataByPosition = getVisualAlertsDataByPosition(
    searchConfig?.visualAlerts,
    element,
    currentLocale,
    "[DATAVIEW]",
  );

  const functionalFieldsToDisplay =
    getCustomerOverrideFunction<FunctionOverrideKey.FunctionalFieldsToDisplayQueryOut>(
      FunctionOverrideKey.FunctionalFieldsToDisplayQueryOut,
      {
        element: element,
        functionalFieldsToDisplayBeforeCustomerHook: functionalFieldsToDisplayBeforeCustomerHook,
      },
    ) || functionalFieldsToDisplayBeforeCustomerHook;

  return (
    <StyledElementSummary
      id={`${ELEMENT_SUMMARY_ID_PREFIX}${id}`}
      className={classes.root}
      element={element}
      isSelected={isSelected}
      onSelect={() => !selectionMode && click()}
      onNavigate={() => actionClick(ActionValue.OPEN_ELEMENT)}
      actions={() => <ElementActions element={element} onActionClick={actionClick} />}
      links={
        <ElementLinks
          actionClick={actionClick}
          associationClick={associationClick}
          element={element}
        />
      }
      allowSelection={!isShare || (isShare && shareTokenDownloadAuthorized)}
      selectionMode={selectionMode}
      cardDoubleClicked={doubleClick}
      cardClick={handleCardClick}
      iconOverMedia={<IconOverMedia element={element} />}
      topLeftElement={
        visualAlertsDataByPosition.has(Position.TopLeft) ? (
          <VisualAlerts
            visualAlertsPosition={Position.TopLeft}
            visualAlertsData={visualAlertsDataByPosition.get(Position.TopLeft) || []}
            element={element}
          />
        ) : null
      }
      topRightElement={
        visualAlertsDataByPosition.has(Position.TopRight) ? (
          <VisualAlerts
            visualAlertsPosition={Position.TopRight}
            visualAlertsData={visualAlertsDataByPosition.get(Position.TopRight) || []}
            element={element}
          />
        ) : null
      }
      bottomRightElement={element?.fields?.map((f) => {
        if (
          (element?.mediaType.startsWith("video/") || element?.mediaType.startsWith("audio/")) &&
          f.id === MediaField.DURATION_IN_SECONDS.toString()
        ) {
          const duration = Number(isArray(f.value) ? f.value[0] : f.value);
          const formatDuration = dayjs.duration(duration, "seconds").format("H:mm:ss");
          return (
            <Typography
              key={f.id}
              variant="caption"
              className={classes.bottomLeft}
              title={formatDuration}
            >
              {formatDuration}
            </Typography>
          );
        }
      })}
      loadingContent={loadingFields}
      imageSize={dataViewConfig?.elementImageSize ? "contain" : "cover"}
    >
      {searchConfig?.elementId?.identifier && (
        <FormatElementId
          elementId={element.id}
          elementIdRules={searchConfig?.elementId}
          dataViewKey={DataViewKey.SEARCH}
        />
      )}
      <ElementsMetaField fields={functionalFieldsToDisplay} media={element} />
      <FormatTechnicalFields
        element={element}
        technicalFieldsToDisplay={technicalFieldsToDisplay}
      />

      {/* Visual alerts at the bottom of the card */}
      {[Position.BottomLeft, Position.BottomRight].map((currentBottomPosition) => {
        if (visualAlertsDataByPosition.has(currentBottomPosition)) {
          const bottomVisualAlertClass: string =
            currentBottomPosition === Position.BottomLeft
              ? classes.visualAlertBottomLeft
              : classes.visualAlertBottomRight;

          return (
            <VisualAlerts
              key={currentBottomPosition?.toString()}
              className={bottomVisualAlertClass}
              visualAlertsPosition={currentBottomPosition}
              visualAlertsData={visualAlertsDataByPosition.get(currentBottomPosition) || []}
              element={element}
            />
          );
        }
      })}
    </StyledElementSummary>
  );
};

export default memo(MemoizedElementSummary);
