import logger from "./logger-utils";

/**
 * Copy value in clipboard
 *
 * @param valueAsText clipboard value as text
 * @param valueAsHtml clipboard value as HTML, only works in Chrome, need to provide text value for others browsers
 */
export function copy(valueAsText: string, valueAsHtml?: string): void {
  if (navigator.clipboard) {
    // @ts-ignore : ClipboardItem and navigator.clipboard.write exists in Chrome
    if (valueAsHtml && typeof ClipboardItem === "function" && navigator.clipboard.write) {
      const type = "text/html";
      const blob = new Blob([valueAsHtml], { type });
      // @ts-ignore : ClipboardItem exists in Chrome
      const data = [new ClipboardItem({ [type]: blob })];

      // @ts-ignore : navigator.clipboard.write exists in Chrome
      navigator.clipboard.write(data).then(
        function () {
          logger.debug("Value copied in clipboard as HTML");
        },
        function () {
          logger.debug("Cannot copy value in clipboard as HTML");
          copyAsText(valueAsText);
        },
      );
    } else {
      copyAsText(valueAsText);
    }
  } else {
    logger.error("Error copying in clipboard");
  }
}

/**
 * Copy value as text in clipboard
 *
 * @param valueAsText clipboard value as text
 */
function copyAsText(valueAsText: string) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(valueAsText).then(
      () => {
        logger.debug("Value copied in clipboard as text");
      },
      () => {
        logger.error("Cannot copy value in clipboard as text");
      },
    );
  }
}
