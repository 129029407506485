import { useRecoilState, useRecoilValueLoadable } from "recoil";

import { shareManagerSelectionsState } from "../atoms/selection";
import { ShareManagerSelection, ShareManagerSelectionResolved } from "../models";
import { shareManagerSelectionsSelector } from "../selectors/shareManagerSelectionsSelector";
import { shareManagerSelectionIdQuery } from "../selectors/shareManagerUnitaryPublicShareSelectors";
import { useShareManagerOpen } from "./useShareManagerOpen";

export type UseShareManagerSelections = {
  handleSelections: (selections: ShareManagerSelection[]) => void;
  addToSelections: (selection: ShareManagerSelection) => void;
  emptySelections: () => void;
  loading: boolean;
  selectionsResolved: ShareManagerSelectionResolved[];
  selectionId: string | null;
  loadingSelectionId: boolean;
};

export const useShareManagerSelections = (): UseShareManagerSelections => {
  const { open, close } = useShareManagerOpen();
  const [selections, setSelections] = useRecoilState(shareManagerSelectionsState);

  const selectionsResolvedQuery = useRecoilValueLoadable(
    shareManagerSelectionsSelector(selections),
  );

  const loading = selectionsResolvedQuery.state === "loading";

  const selectionsResolved =
    selectionsResolvedQuery.state === "hasValue" ? selectionsResolvedQuery.contents : [];

  const selectionIdQuery = useRecoilValueLoadable(shareManagerSelectionIdQuery);

  const loadingSelectionId = selectionIdQuery.state === "loading";

  const selectionId =
    selectionIdQuery.state === "hasValue" ? selectionIdQuery.contents.selectionId : null;

  const handleSelections: UseShareManagerSelections["handleSelections"] = (selections) => {
    setSelections(selections);
    if (selections.length > 0) {
      open();
    } else {
      close();
    }
  };

  const addToSelections: UseShareManagerSelections["addToSelections"] = (selection) => {
    setSelections((prev) => {
      const computed = [...prev, selection];
      if (computed.length > 0) {
        open();
      } else {
        close();
      }
      return computed;
    });
  };

  const emptySelections: UseShareManagerSelections["emptySelections"] = () => {
    setSelections([]);
    close();
  };

  return {
    handleSelections,
    addToSelections,
    emptySelections,
    loading,
    selectionsResolved,
    selectionId,
    loadingSelectionId,
  };
};
