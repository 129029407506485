import { KpkApiMediaDownloadLevel, KpkApiPublicShareExportDefinition } from "@keepeek/api-client";
import { KpkBasket, KpkMedia } from "@keepeek/commons";

export enum ShareManagerSelectionType {
  UNITARY_PUBLIC_SHARE = "UNITARY_PUBLIC_SHARE",
  BASKET = "BASKET",
}

export type ShareManagerSelection = {
  elements: {
    id: KpkMedia["id"] | KpkBasket["id"];
    title: KpkMedia["title"]["value"];
    mediaType: KpkMedia["mediaType"];
  }[];
  type: ShareManagerSelectionType;
};

export type ShareManagerSelectionResolved = {
  type: ShareManagerSelectionType;
  // Resolved into element or basket
  // TODO: Basket is not yet supported so it will be undefined[]
  resolved: ShareManagerUnitaryPublicShareEmbed[] | undefined[];
};

export type ShareManagerUnitaryPublicShareEmbed = {
  elementId: KpkMedia["id"];
  detailLink?: string;
  link?: string;
  embed?: string;
  status: "ACTIVATED" | "DEACTIVATED";
  deactivationDate?: Date;
  mediaType: KpkMedia["mediaType"];
  title: KpkMedia["title"]["value"];
  exportDefinition: KpkApiPublicShareExportDefinition;
  downloadLevel: KpkApiMediaDownloadLevel;
};
