import { KpkApiMediaDownloadLevel, KpkApiPublicShareExportDefinition } from "@keepeek/api-client";
import { atom } from "recoil";

export const shareUnitaryPublicShareSizeState = atom<string>({
  key: "ShareUnitaryPublicShareSizeState",
  default: "AUTO",
});

export const shareUnitaryPublicShareSizeHeightState = atom<number>({
  key: "ShareUnitaryPublicShareSizeHeightState",
  default: 600,
});

export const shareUnitaryPublicShareSizeWidthState = atom<number>({
  key: "ShareUnitaryPublicShareSizeWidthState",
  default: 800,
});

export const shareUnitaryPublicShareDeactivationDateState = atom<Date | undefined>({
  key: "ShareUnitaryPublicShareDeactivationDateState",
  default: undefined,
});

export const shareUnitaryPublicShareDownloadLevelState = atom<KpkApiMediaDownloadLevel>({
  key: "ShareUnitaryPublicShareDeactivationDateActiveState",
  default: KpkApiMediaDownloadLevel.None,
});

export const selectedUnitaryPublicShareExportDefinitionState =
  atom<KpkApiPublicShareExportDefinition>({
    key: "SelectedUnitaryPublicShareExportDefinitionState",
    default: KpkApiPublicShareExportDefinition.HD,
  });

export const updateUnitaryPublicSharedState = atom<number>({
  key: "UpdateUnitaryPublicSharedState",
  default: 0,
});
