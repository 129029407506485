import { BusinessFilter, BusinessFilterNature, KpkFilterType } from "@keepeek/commons";

import { FqType } from "../../../../lib/search/search-media";
import { FQ_PARAMETER } from "../../constants";
import { LightBusinessFilter, LightKpkFilterValue } from "../../types/lfb";

/**
 * allows to convert business filters into light business filter
 */
export function convertToLightBusinessFilter(
  businessFilters: BusinessFilter[],
): LightBusinessFilter[] {
  return businessFilters.map((bf) => {
    let values: LightKpkFilterValue[];
    switch (bf.nature) {
      case BusinessFilterNature.FACET_MULTI:
      case BusinessFilterNature.FACET:
        // for FACET nature, only the selected facet is kept in values
        values = bf.filter.values
          .filter((v) => v.selected)
          .map((v) => {
            let valueToRebounce = v.id;
            if (v.mediaSearchLink && bf.filter.id && bf.filter.type === KpkFilterType.RadioFacet) {
              // Get label and synonym in mediaSearch link fq parameter
              const parameterValueFqs: string[] = new URLSearchParams(v.mediaSearchLink).getAll(
                FQ_PARAMETER,
              );
              if (parameterValueFqs) {
                const fieldNameWithoutLocale =
                  bf.filter.id.split(".").length === 2 ? bf.filter.id.split(".")[0] : bf.filter.id;
                const parameterValueFq = parameterValueFqs.find((fq) =>
                  fq.startsWith(`${FqType.Facet + fieldNameWithoutLocale}.`),
                );
                if (parameterValueFq && parameterValueFq.split(":").length === 3) {
                  valueToRebounce = parameterValueFq.split(":")[2];
                }
              }
            }
            if (v.path) {
              valueToRebounce = v.path;
            }
            return {
              i: bf.nature === BusinessFilterNature.FACET ? valueToRebounce : v.id,
              l: v.label,
              t: v.title,
              s: v.selected,
            };
          });
        break;
      default:
        // for OTHER nature type, we keep ALL the values
        values = bf.filter.values.map((v) => {
          return {
            i: v.id,
            l: v.label,
            t: v.title,
            s: v.selected,
          };
        });
    }
    return {
      n: bf.nature,
      f: {
        i:
          bf.nature === BusinessFilterNature.FACET_MULTI
            ? bf.filter.id.split(".")[0]
            : bf.filter.id,
        l:
          bf.nature === BusinessFilterNature.FACET || bf.nature === BusinessFilterNature.FACET_MULTI
            ? bf.filter.label
            : undefined,
        t: bf.filter.type,
        m: bf.filter.modifiers?.find((m) => m.selected)?.type || undefined,
        v: values,
        f: bf.filter.internalName,
      },
      h: bf.hide,
    };
  });
}
