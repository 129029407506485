import React, { FC, ReactNode } from "react";

import { DataViewKey, KpkMedia } from "@keepeek/commons";
import { FolderLink, KTable, withSuspense } from "@keepeek/refront-components";
import { Fab, TableCell, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { KIcon } from "../../../components/common/KIcon";
import { activeBusinessFiltersFromURLSelector } from "../../../providers/businessFilter/selectors/activeFilters";
import { useDataView } from "../../../providers/dataView/hooks/useDataView";
import { useKeyboardSelection } from "../../../providers/dataView/hooks/useKeyboardSelection";

const PREFIX = "ListDataView";

const classes = {
  action: `${PREFIX}-action`,
};

const StyledKTable = styled(KTable)(() => ({
  [`& .${classes.action}`]: {
    transitionProperty: "opacity",
    transitionDuration: "0.3s",
    opacity: 0.2,
  },
}));

// TODO : J'ai surement fait ça de manière assez naïve mais c'est pour illustrer la façon de faire que je propose :)
const ListDataView: FC<React.PropsWithChildren<unknown>> = function ListDataview() {
  const { t } = useTranslation();
  const filters = useRecoilValue(activeBusinessFiltersFromURLSelector);
  const { elements } = useDataView({ key: DataViewKey.SEARCH, filters });
  useKeyboardSelection(DataViewKey.SEARCH, elements);

  // TODO doit être dynamique en fonction de la data ou d'une prop.
  const columns = ["Titre", "Date de création", "Dossier", ""];
  const getRowData: (media: KpkMedia) => ReactNode[] = (element: KpkMedia) => [
    element.title.value,
    element.fields?.[0]?.value,
    // eslint-disable-next-line react/jsx-key
    <FolderLink>{element.fields?.[1]?.value}</FolderLink>,
    <Fab
      className={clsx(classes.action, "ListDataView-body-action")}
      size="small"
      color="primary"
      aria-label={t("dataview.download-button.label") as string}
      title={t("dataview.download-button.label") as string}
      key={element.id.toString()}
      href={element.previewUrl}
      target="_blank"
      rel="noopener noreferrer"
      download
    >
      <KIcon>cloud_download</KIcon>
    </Fab>,
  ];

  return (
    <StyledKTable
      className="ListDataView"
      columnsComponent={columns.map((c) => (
        <TableCell className="ListDataView-head-cell" key={c}>
          {c}
        </TableCell>
      ))}
    >
      {elements.map((element: KpkMedia) => (
        <TableRow key={element.id} className="ListDataView-body-row">
          {getRowData(element).map((cell) => (
            <TableCell className="ListDataView-body-cell" key={`${cell}`}>
              {cell}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </StyledKTable>
  );
};

export default withSuspense(ListDataView);
