import React from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { Box, Chip, useTheme } from "@mui/material";
import clsx from "clsx";

import { SpacerWidget } from "../../../../../models/configuration/components/spacerWidget";

const WidgetCardSelectorSpacer = function ({
  testId = "WidgetCardSelectorSpacer",
  className,
  spacerWidgetHeight,
}: SpacerWidget & BaseComponentProps) {
  const theme = useTheme();
  return (
    <Box
      className={clsx("WidgetCardSelectorSpacer", className)}
      data-testid={testId}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        flexShrink: 1,
      }}
    >
      <Box
        style={{
          backgroundColor: theme.palette.primary.main,
          height: theme.spacing(5),
          width: "80%",
          borderRadius: "0 0 .25rem .25rem",
        }}
      />
      <Box
        style={{
          backgroundColor: theme.palette.grey[400],
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: theme.spacing(1),
          flexGrow: 1,
        }}
      >
        {!!spacerWidgetHeight?.XS && <Chip label={`XS:${spacerWidgetHeight.XS}`} size="small" />}
        {!!spacerWidgetHeight?.SM && <Chip label={`SM:${spacerWidgetHeight.SM}`} size="small" />}
        {!!spacerWidgetHeight?.MD && <Chip label={`MD:${spacerWidgetHeight.MD}`} size="small" />}
        {!!spacerWidgetHeight?.LG && <Chip label={`LG:${spacerWidgetHeight.LG}`} size="small" />}
        {!!spacerWidgetHeight?.XL && <Chip label={`XL:${spacerWidgetHeight.XL}`} size="small" />}
      </Box>
      <Box
        style={{
          backgroundColor: theme.palette.primary.main,
          height: theme.spacing(5),
          width: "80%",
          borderRadius: ".25rem .25rem 0 0",
          flexShrink: 1,
        }}
      />
    </Box>
  );
};

export default WidgetCardSelectorSpacer;
