import { KpkApiForm, KpkApiSheetField } from "@keepeek/api-client";
import {
  EmbeddedMediaField,
  KpkMedia,
  MediaField,
  MediaLinksField,
  MetaField,
} from "@keepeek/commons";
import uniq from "lodash/uniq";
import { getI18n } from "react-i18next";

import {
  Field,
  Metamodel,
  MetamodelTypeEnum,
  MetamodelTypeObject,
} from "../models/configuration/definitions/field";
import {
  Fields,
  Metamodel as MetamodelFields,
  MetamodelTypeObject as MetamodelTypeObjectFields,
} from "../models/configuration/definitions/fields";
import { FieldType, VisualAlertsSchema } from "../models/configuration/pages/search";
import { KAdminFields } from "../models/configuration/types/kAdminFields";
import { KAdminMediaFields } from "../models/configuration/types/kAdminMediaFields";

/**
 * Mandatory MediaLinksField.
 */
export const mandatoryLinksField: MediaLinksField[] = [
  MediaLinksField.FORM,
  MediaLinksField.PREVIEW,
  MediaLinksField.WHR,
  MediaLinksField.XLARGE,
];

export interface KpkAllFields {
  id?: string;
  title?: string;
  type: string;
}

/**
 * Mandatory metafields.
 * Used for example when we retrieve an element to obtain at least these metafields
 */
export const mandatoryMetaField: MetaField[] = [
  MetaField.TITLE,
  MetaField.ORIGINAL_FILE_NAME,
  MetaField.DURATION_IN_SECONDS,
  MetaField.MEDIA_TYPE,
];

/**
 * Internal technical fields.
 * Technical field necessary for the proper functioning of the application
 */
export const internalTechnicalFields: MediaField[] = [
  MediaField.ID,
  MediaField.HEIGHT,
  MediaField.WIDTH,
];

/**
 * Return field to display depending on media.
 *
 * @param fieldConfiguration full field configuration
 * @param media media
 * @forms all api forms
 */
export function getFieldToDisplay(
  fieldConfiguration: Field,
  media: KpkMedia,
  forms: KpkApiForm[] | null,
): string | undefined {
  if (!fieldConfiguration || !media) {
    return "";
  }

  if (fieldConfiguration.metamodel) {
    const metamodelConfiguration: Metamodel | undefined = fieldConfiguration.metamodel.find(
      (metamodelConfiguration) => metamodelConfiguration.metamodelId.includes(media.formId),
    );

    if (metamodelConfiguration) {
      return metamodelConfiguration.field;
    }
  }

  if (fieldConfiguration.metamodelType) {
    const metamodelType = getMetamodelType(media, forms);
    if (metamodelType) {
      const metamodelTypeConfiguration: MetamodelTypeObject | undefined =
        fieldConfiguration.metamodelType.find((metamodelTypeConfiguration) =>
          metamodelTypeConfiguration.metamodelType.includes(metamodelType),
        );

      if (metamodelTypeConfiguration) {
        return metamodelTypeConfiguration.field;
      }
    }
  }

  return fieldConfiguration.default;
}

export function getMetamodelType(
  media: KpkMedia,
  forms: KpkApiForm[] | null,
): MetamodelTypeEnum | null {
  if (!media || !forms || forms.length === 0) {
    return null;
  }
  const form = forms.find((f) => f.id === media.formId);
  if (!form) {
    return null;
  }
  return form.type as unknown as MetamodelTypeEnum;
}

/**
 * Return fields to display depending on media.
 *
 * @param fieldsConfiguration full field configuration
 * @param media media
 * @forms all api forms
 */
export function getFieldsToDisplay(
  fieldsConfiguration: Fields,
  media: KpkMedia,
  forms: KpkApiForm[] | null,
): string[] | undefined {
  if (!fieldsConfiguration || !media) {
    return [];
  }

  if (fieldsConfiguration.metamodel) {
    const metamodelConfiguration: MetamodelFields | undefined = fieldsConfiguration.metamodel.find(
      (metamodelConfiguration) => metamodelConfiguration.metamodelId.includes(media.formId),
    );

    if (metamodelConfiguration) {
      return uniq(metamodelConfiguration.fields);
    }
  }

  if (fieldsConfiguration.metamodelType) {
    const metamodelType = getMetamodelType(media, forms);

    if (metamodelType) {
      const conf: MetamodelTypeObjectFields | undefined = fieldsConfiguration.metamodelType.find(
        (conf) => conf.metamodelType.includes(metamodelType),
      );

      if (conf) {
        return uniq(conf.fields);
      }
    }
  }

  return uniq(fieldsConfiguration.default);
}

/**
 * Return flat fields list to request API with all possible fields.
 * For now, there is no other way than sending all fields to API because we cannot
 * guess media form and type search will return.
 *
 * @param fieldsConfiguration full field configuration
 */
export function getFieldsForApi(fieldsConfiguration: Fields, basket?: boolean): string[] {
  if (!fieldsConfiguration) {
    return [];
  }

  let fieldsForApi: string[] = [];
  if (fieldsConfiguration.metamodel) {
    fieldsConfiguration.metamodel.forEach((fields) => {
      fields.fields.forEach((fieldName) => {
        if (fieldName !== MediaField.FOLDER_ID || !basket) {
          if (fieldName === MediaField.FOLDER_ID) {
            fieldsForApi = fieldsForApi.concat(EmbeddedMediaField.FOLDERS);
          } else {
            fieldsForApi = fieldsForApi.concat(fieldName);
          }
        }
      });
    });
  }

  if (fieldsConfiguration.metamodelType) {
    fieldsConfiguration.metamodelType.forEach((fields) => {
      fields.fields.forEach((f) => {
        if (f !== MediaField.FOLDER_ID || !basket) {
          if (f === MediaField.FOLDER_ID) {
            fieldsForApi = fieldsForApi.concat(EmbeddedMediaField.FOLDERS);
          } else {
            fieldsForApi = fieldsForApi.concat(f);
          }
        }
      });
    });
  }
  if (fieldsConfiguration.default) {
    fieldsConfiguration.default.forEach((f) => {
      if (f !== MediaField.FOLDER_ID || !basket) {
        if (f === MediaField.FOLDER_ID) {
          fieldsForApi = fieldsForApi.concat(EmbeddedMediaField.FOLDERS);
        } else {
          fieldsForApi = fieldsForApi.concat(f);
        }
      }
    });
  }

  return fieldsForApi;
}

/**
 * Return flat fields list to request API with all possible fields needed by visual alerts calcul
 *
 * @param visualAlerts visual alerts configuration
 */
export function getVisualAlertsFieldsForApi(visualAlerts: VisualAlertsSchema[]): string[] {
  const fieldsForApi: string[] = [];
  // get fields needed by visual alerts conditions
  const fields = visualAlerts
    .filter((visualAlert) => visualAlert.fieldInternalName)
    .map((visualAlert) => visualAlert.fieldInternalName.trim());
  fieldsForApi.push(...fields);
  // retrieve sub status if at least one visual alert is based on it
  const isSubStatusNeeded = visualAlerts.some(
    (visualAlert) => visualAlert.fieldType === FieldType.SubStatus,
  );
  if (isSubStatusNeeded) {
    fieldsForApi.push(MediaLinksField.SUB_STATUS);
  }
  return fieldsForApi;
}

export const getTranslatedAndSortedMediaFields = (): KAdminMediaFields[] => {
  const res: KAdminMediaFields[] = [];
  Object.values(MediaField).forEach((field) => {
    res.push({
      id: field,
      name: getI18n().t(`mediafield_${field}`),
    });
  });
  return res.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });
};

export function convertToFieldList(
  sheetFields: KpkApiSheetField[] | undefined,
  metaFields: KAdminMediaFields[],
): KAdminFields[] {
  const res: KAdminFields[] = [];
  sheetFields?.map((sheetField) => {
    res.push({
      id: sheetField.id,
      name: sheetField.title,
      type: getI18n().t("metaField"),
    });
  });
  metaFields.map((metaField) => {
    res.push({
      id: metaField.id,
      name: metaField.name,
      type: getI18n().t("mediaField"),
    });
  });
  return res;
}
