import { FC } from "react";

import { KpkFolder } from "@keepeek/commons";
import { withSuspense } from "@keepeek/refront-components";
import { ButtonBase, Icon } from "@mui/material";
import { Skeleton, Typography } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { useFilter } from "../../../providers/businessFilter/hooks/useFilter";
import { convertBusinessFilterToKpkFilter } from "../../../providers/businessFilter/utils/converters/convertBusinessFilterWithKpkFilter";
import { createFolderBusinessFilter } from "../../../providers/businessFilter/utils/create/createFolderBusinessFilter";
import { classes } from "./styles";

const FormatTechnicalFolder: FC<{ folder: KpkFolder }> = function ({ folder }) {
  const { t } = useTranslation();
  const businessFilter = createFolderBusinessFilter(
    folder.id,
    folder?.title ?? "",
    t("business-filter.advanced.folder.label"),
  );
  const { update } = useFilter({
    businessFilter,
  });
  return (
    <Typography component="span">
      <ButtonBase
        focusRipple
        sx={{
          textDecoration: "underline",
          padding: 0,
          marginTop: (theme) => theme.spacing(0.5),
          marginLeft: (theme) => theme.spacing(0.5),
          borderRadius: (theme) => theme.shape.borderRadius,
        }}
        className={clsx(classes.field, classes.mediaType)}
        onClick={() => {
          update({ filter: convertBusinessFilterToKpkFilter(businessFilter), resetFilters: true });
        }}
      >
        <Typography variant="caption" title={folder.title} className={clsx(classes.field)}>
          <Icon
            sx={{ fontSize: "0.6rem", marginRight: (theme) => theme.spacing(1) }}
            baseClassName="fas"
            className="fa-folder"
          />
          {folder.title}
        </Typography>
      </ButtonBase>
    </Typography>
  );
};

export default withSuspense(FormatTechnicalFolder, { LoadingComponent: <Skeleton /> });
