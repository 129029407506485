import { getI18n } from "react-i18next";

/**
 * Units
 */
export enum FileSizeUnit {
  Ko = "KO",
  Mo = "MO",
  Go = "GO",
}

/**
 * Normalized file size
 */
export type FileSize = {
  size: string;
  unit: FileSizeUnit;
  unitLabel: string;
};

/**
 * Get normalized file size from octets size
 */
export function getNormalizeFileSize(sizeOctets: number): FileSize {
  if (sizeOctets >= 1000 * 1000 * 1000) {
    return {
      size: (sizeOctets / 1000 / 1000 / 1000).toFixed(2),
      unit: FileSizeUnit.Go,
      unitLabel: getI18n().t("file-size.gb"),
    };
  }
  if (sizeOctets >= 1000 * 1000) {
    return {
      size: (sizeOctets / 1000 / 1000).toFixed(2),
      unit: FileSizeUnit.Mo,
      unitLabel: getI18n().t("file-size.mb"),
    };
  }
  return {
    size: (sizeOctets / 1000).toFixed(2),
    unit: FileSizeUnit.Ko,
    unitLabel: getI18n().t("file-size.kb"),
  };
}
