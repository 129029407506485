import { atom } from "recoil";

import { localStorageEffect } from "../../lib/recoil-persist";
import { initBackContextState } from "./constants";
import { BackContext } from "./types";

export const backContextState = atom<BackContext>({
  key: "BackContext",
  default: initBackContextState,
  effects: [localStorageEffect("BackContext")],
});
