import React, { FC, ReactNode } from "react";

import { useTheme } from "@mui/material";
import NextNProgress from "nextjs-progressbar";

import { useInitOverride } from "../../providers/admin/config/useInitOverride";
import { useInitConfig } from "../../providers/config/hooks/init";
import { useClearSelectionRules } from "../../providers/dataView/hooks/useClearSelectionRules";
import { useFetchers } from "../../providers/fetcher/hooks";
import { useCurrentLocale } from "../../providers/i18n/hooks";
import { useMatomo } from "../../providers/matomo/hooks";
import { usePageRouterEvents } from "../../providers/page/hooks/usePageRouterEvents";
import { useSentry } from "../../providers/tools/sentry/hooks";
import Didomi from "./Didomi";
import GoogleAnalytics from "./GoogleAnalytics";
import StatsListener from "./StatsListener";

type ConsumerProps = { children: ReactNode };

// You can use all hooks here since every providers has been set/loaded from the component above
const Consumer: FC<React.PropsWithChildren<ConsumerProps>> = function ({ children }) {
  // register router events in recoil
  usePageRouterEvents();
  // init Admin config
  useInitConfig();
  // Consume theme
  const theme = useTheme();
  // init Sentry
  useSentry();
  // Manage providers fetchers update while browsing between logged and share mode
  useFetchers();
  // init current locale
  useCurrentLocale();
  // Rules for clearing a selection in dataView
  useClearSelectionRules();
  // Inject override Json Config Section to refront-customers
  useInitOverride();
  // Init Matomo
  useMatomo();

  return (
    <>
      <StatsListener />
      <NextNProgress color={theme.palette.primary.main} />
      <Didomi />
      <GoogleAnalytics />
      {children}
    </>
  );
};

export default Consumer;
