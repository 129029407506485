import React, { FC, useRef } from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress } from "@mui/material";
import { Form } from "@rjsf/mui";
import { RJSFValidationError } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { JSONSchema7 } from "json-schema";
import { useTranslation } from "react-i18next";

import {
  customFields,
  customWidgets,
  i18nJsonSchema,
  transformErrors,
} from "../../lib/admin/react-json-schema";
import { CustomArrayFieldTemplate } from "../../lib/admin/react-json-schema/CustomArrayFieldTemplate";
import { CurrentEditKey } from "../../providers/frontEdit/atoms/frontEditCurrentEditKeys";
import useFrontEditCurrentEdit from "../../providers/frontEdit/hooks/useFrontEditCurrentEdit";
import { getPathFromSectionDataKey } from "./utils";

export type FrontEditEditionFormProps = {
  displayButton?: boolean;
  keys: CurrentEditKey[];
  forceEditMode?: boolean;
} & BaseComponentProps;

const formContext = {
  isFrontEdit: true,
};

const FrontEditEditionForm: FC<FrontEditEditionFormProps> = function ({
  keys,
  displayButton = true,
  forceEditMode = false,
}) {
  const { sectionsData, handleSubmit, handleChangeAndSubmit, handleChange } =
    useFrontEditCurrentEdit(keys, { forceEditMode });
  const { t } = useTranslation();
  // list of RJSF submit buttons used to trigger forms validation
  const formSubmitButtonsRef = useRef<HTMLButtonElement[]>([]);
  // list of RJSF form errors
  const formErrorsRef = useRef<RJSFValidationError[][]>([]);

  if (sectionsData === null) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {sectionsData.map((sectionData, i) => (
        <Form
          key={getPathFromSectionDataKey(sectionData.key)}
          schema={i18nJsonSchema(sectionData.jsonSchema, t) as JSONSchema7}
          formData={sectionData.configurationData}
          uiSchema={sectionData.jsonUiSchema}
          onChange={(e) => {
            if (displayButton) {
              handleChange(e, sectionData);
            } else {
              handleChangeAndSubmit(e, sectionData);
            }
          }}
          widgets={customWidgets}
          fields={customFields}
          omitExtraData
          templates={{
            ArrayFieldTemplate: CustomArrayFieldTemplate,
          }}
          transformErrors={transformErrors}
          formContext={formContext}
          onError={(errors: RJSFValidationError[]) => {
            formErrorsRef.current[i] = errors;
          }}
          onSubmit={() => {
            // remove errors for current form because of reaching onSubmit
            formErrorsRef.current[i] = [];

            // Save if there is no error at all, it will use onSubmit of last validated form
            if (
              formErrorsRef.current.length === sectionsData.length &&
              formErrorsRef.current.findIndex((err) => err.length > 0) === -1
            ) {
              handleSubmit();
            }
          }}
          // disable global errors, no readble labels are generated, does not make sense in case of multiple forms
          showErrorList={false}
          validator={validator}
        >
          <button
            ref={(el) => el && (formSubmitButtonsRef.current[i] = el)}
            type="submit"
            style={{ display: "none" }}
          />
        </Form>
      ))}
      {displayButton && (
        <Box sx={{ display: "flex", justifyContent: "center", color: "blue" }}>
          <LoadingButton
            variant={"contained"}
            sx={{ width: "50%" }}
            onClick={() => {
              // trigger forms validation, if form is valid, it will trigger RJSF onSubmit callabck
              formErrorsRef.current = []; // reset form errors
              formSubmitButtonsRef.current.forEach((element) => {
                element.click();
              });
            }}
          >
            {t("action.validate")}
          </LoadingButton>
        </Box>
      )}
    </>
  );
};

export default FrontEditEditionForm;
