import React, { FC } from "react";

import { EditableSanitizeHtmlProps, SanitizeHtml } from "@keepeek/refront-components";
import { Icon } from "@mui/material";
import { Box } from "@mui/system";
import { getI18n, useTranslation } from "react-i18next";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import sanitizeHtml from "sanitize-html";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { Labels, TranslationSchema } from "../../../models/configuration/labels";
import { useConfiguration } from "../../../providers/config/hooks";
import { frontEditState } from "../../../providers/frontEdit/atoms/frontEdit";
import useFrontEditModal from "../../../providers/frontEdit/hooks/useFrontEditModal";
import { getCurrentContentEditsSelector } from "../../../providers/frontEdit/selectors/currentContentEdits";
import { getOriginalTranslationByLanguage } from "../../../providers/frontEdit/selectors/originalTranslations";
import { getNamespaceLabelKey } from "../utils";
import StyledEditableSanitizeHtml, { classes } from "./index.styled";

/**
 * Component to make labels editable on front edit
 *
 * @param labelKey translation key
 * @param labelOptions options to add variables on translation ex: {count: 32}
 * @param namespace namespace of the label key
 * @param sanitizeOptions if you wish to allow tags, CSS classes, attributes on the rendered html element
 */
const EditableSanitizeHtml: FC<React.PropsWithChildren<EditableSanitizeHtmlProps>> = function ({
  labelKey,
  labelOptions,
  namespace,
  sanitizeOptions,
}) {
  const { t } = useTranslation(namespace || "");
  const { setIsOpen, setCurrentEditKeys } = useFrontEditModal();
  const configSection = useConfiguration<Labels[]>(CONFIGURATION_SECTION_PATH.LABELS);

  const frontEdit = useRecoilValue(frontEditState);

  const currentContentEdit = useRecoilValue(
    getCurrentContentEditsSelector({
      endpoint: `${CONFIGURATION_SECTION_PATH.LABELS}/${getNamespaceLabelKey(
        labelKey,
        namespace || "",
      )}`,
    }),
  );

  const locale = getI18n().language;

  const labelLocale: TranslationSchema | undefined = configSection
    ?.find((l) => l.key === labelKey)
    ?.translation?.find((t) => t.language.toLowerCase() === locale.toLowerCase());

  const originalTranslationQuery = useRecoilValueLoadable(
    getOriginalTranslationByLanguage({
      lng: locale || "fr",
      namespace,
    }),
  );

  const originalTranslation =
    originalTranslationQuery.state === "hasValue" ? originalTranslationQuery.contents : "";

  if (!frontEdit) {
    let html = t(labelKey, labelOptions as object) as string;
    if (labelLocale && labelLocale.empty) {
      html = "";
    }
    return (
      <SanitizeHtml
        html={html}
        sanitizeOptions={{
          allowedTags: sanitizeHtml.defaults.allowedTags.concat(["center"]),
          allowedAttributes: {
            ...sanitizeHtml.defaults.allowedAttributes,
            div: ["style"],
            p: ["style"],
          },
          ...sanitizeOptions,
        }}
      />
    );
  }

  const frontEditLabelConfiguration = currentContentEdit?.data || undefined;

  // If there is a front edit configuration and is not just empty HTML, display it as pre-visualisation
  let translatedLabelToDisplay = t(labelKey, labelOptions as object);
  let empty: boolean | undefined = labelLocale && labelLocale.empty;
  if (frontEditLabelConfiguration) {
    const frontEditLabelConfByLocale = frontEditLabelConfiguration.translation.find(
      (t) => t.language.toLowerCase() === locale?.toLowerCase(),
    );
    if (frontEditLabelConfByLocale) {
      const labelValue = frontEditLabelConfByLocale.value;
      if (
        frontEditLabelConfByLocale?.value === undefined ||
        frontEditLabelConfByLocale?.value?.replace(/(<([^>]+)>)/gi, "").trim().length === 0
      ) {
        translatedLabelToDisplay = t(originalTranslation[labelKey], labelOptions as object);
      } else {
        translatedLabelToDisplay = t(labelValue, labelOptions as object);
      }
      empty = frontEditLabelConfByLocale.empty;
    }
  }

  return (
    <StyledEditableSanitizeHtml
      onClick={(e) => {
        setCurrentEditKeys([
          {
            configSection: CONFIGURATION_SECTION_PATH.LABELS,
            namespaceLabelKey: getNamespaceLabelKey(labelKey, namespace || ""),
          },
        ]);
        setIsOpen(true);
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pointerEvents: "auto",
          ...(empty && { textDecoration: "line-through" }),
        }}
      >
        <SanitizeHtml
          html={translatedLabelToDisplay}
          sanitizeOptions={{
            allowedTags: sanitizeHtml.defaults.allowedTags.concat(["center"]),
            allowedAttributes: {
              ...sanitizeHtml.defaults.allowedAttributes,
              div: ["style"],
              p: ["style"],
            },
            ...sanitizeOptions,
          }}
        />
        <span className={classes.editionIcon}>
          <Icon
            baseClassName="fas"
            className="fa-square-pen"
            sx={{ fontSize: "25px", color: "#116ddf" }}
          />
        </span>
      </Box>
    </StyledEditableSanitizeHtml>
  );
};

export default EditableSanitizeHtml;
