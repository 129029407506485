import React, { FC } from "react";

import { KpkMedia } from "@keepeek/commons";
import { BaseComponentProps } from "@keepeek/refront-components";
import { Box, styled } from "@mui/material";
import clsx from "clsx";

import { Position } from "../../../../models/configuration/definitions/visualAlertsSchema";
import VisualAlert, { VisualAlertProps } from "../VisualAlert";

export type VisualAlertsProps = {
  visualAlertsData: VisualAlertProps[];
  visualAlertsPosition: Position;
  element?: KpkMedia;
} & BaseComponentProps;

const PREFIX = "VisualAlerts";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledVisualAlerts = styled(Box)<{ visualAlertsPosition: Position }>(
  ({ visualAlertsPosition }) => ({
    display: "flex",
    flexDirection: "column",
    // visual alerts on the left side of the card / player have to be aligned on the left, otherwise on the right
    alignItems: [Position.TopLeft, Position.BottomLeft].includes(visualAlertsPosition)
      ? "flex-start"
      : "flex-end",
  }),
);

/**
 * Component containing all "Visual Alerts" for a media
 */
const VisualAlerts: FC<React.PropsWithChildren<VisualAlertsProps>> = function VisualAlerts({
  className,
  visualAlertsData,
  visualAlertsPosition,
  element,
}) {
  if (!visualAlertsData) {
    return null;
  }

  return (
    <StyledVisualAlerts
      className={clsx("VisualAlerts", classes.root, className)}
      visualAlertsPosition={visualAlertsPosition}
    >
      {visualAlertsData.map((visualAlertData, index) => (
        <VisualAlert key={index} {...visualAlertData} element={element} />
      ))}
    </StyledVisualAlerts>
  );
};

export default VisualAlerts;
