import React from "react";

import { BaseComponentProps, SanitizeHtml } from "@keepeek/refront-components";
import { Box, useTheme } from "@mui/material";
import clsx from "clsx";

import { EditoWidget } from "../../../../../models/configuration/components/editoWidget";
import { useCurrentLocale } from "../../../../../providers/i18n/hooks";

const WidgetCardSelectorEdito = function ({
  testId = "WidgetCardSelectorEdito",
  className,
  backgroundColor,
  content,
}: EditoWidget & BaseComponentProps) {
  const theme = useTheme();
  const locale = useCurrentLocale();
  const localizedContent = !!content && content.find((c) => c.language === locale)?.value;
  return (
    <Box
      className={clsx("WidgetCardSelectorEdito", className)}
      data-testid={testId}
      style={{
        backgroundColor: backgroundColor,
        padding: theme.spacing(1),
      }}
    >
      {!!localizedContent && <SanitizeHtml html={localizedContent} />}
    </Box>
  );
};

export default WidgetCardSelectorEdito;
