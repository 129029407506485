import React, { FC } from "react";

import { useShareToken, KpkMedia, useCurrentUser } from "@keepeek/commons";
import {
  BaseComponentProps,
  DOWNLOAD_MANAGER_FORMAT_BY,
  KButton,
  useThemeColors,
} from "@keepeek/refront-components";
import { Box, useTheme } from "@mui/material";
import clsx from "clsx";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { KIcon } from "../../../components/common/KIcon";
import DetailDownloadButton from "../../../components/detail/DetailDownloadButton";
import ShowAttachmentButton from "../../../components/detail/ShowAttachmentButton";
import { ActionValue } from "../../../components/search/Dataview/Actions";
import logger from "../../../lib/logger-utils";
import { useAttachments } from "../../../providers/attachment/hooks/attachments";
import { addToBasketSelection } from "../../../providers/basket/atoms/addTo";
import { configSectionPageElementSelector } from "../../../providers/config/selectors";
import { downloadManagerPreferenceFormatByState } from "../../../providers/downloadManager/atoms/preferences";
import { downloadManagerSelectionSelector } from "../../../providers/downloadManager/selectors/selections";
import { useShareManagerSelections } from "../../../providers/shareManager/hooks/useShareManagerSelections";
import { ShareManagerSelectionType } from "../../../providers/shareManager/models";
import EditableSanitizeHtml from "../../FrontEdit/EditableSanitizeHtml";

export const MEDIA_TYPE_RICH_ELEMENT = "application/vnd.keepeek.rich";
export const RICH_TYPE_META_MODEL_ID = "RICH";

export type MediaDetailHeaderActionsProps = {
  kpkMedia: KpkMedia;
  showAttachments: boolean;
  showAddToBasket: boolean;
  showUnitaryPublicShare: boolean;
} & BaseComponentProps;

const MediaDetailHeaderActions: FC<React.PropsWithChildren<MediaDetailHeaderActionsProps>> =
  function MetadataInfo({
    className,
    kpkMedia,
    showAttachments,
    showAddToBasket,
    showUnitaryPublicShare,
  }) {
    const theme = useTheme();
    const { t } = useTranslation();
    const { convertColor } = useThemeColors();
    const { actionButtons } = useRecoilValue(configSectionPageElementSelector) ?? {
      actionButtons: undefined,
    };
    const paletteColor = {
      main: convertColor(actionButtons?.main) || "",
      light: "",
      contrastText: "",
      dark: "",
    };

    const { hasPublicShare } = useCurrentUser();

    const setSelectionToDownloadManager = useSetRecoilState(downloadManagerSelectionSelector);
    const setPreferenceFormat = useSetRecoilState(downloadManagerPreferenceFormatByState);
    const router = useRouter();
    const { isShare, shareTokenDownloadAuthorized } = useShareToken(
      router.pathname,
      router.query.tokenId,
    );
    const { attachments } = useAttachments({ elementId: kpkMedia.id });

    const { handleSelections } = useShareManagerSelections();

    const setSelectionToAdd = useSetRecoilState(addToBasketSelection);

    const handleActionClick = (action: ActionValue) => {
      switch (action) {
        case ActionValue.DOWNLOAD:
          setSelectionToDownloadManager([kpkMedia.id]);
          setPreferenceFormat(DOWNLOAD_MANAGER_FORMAT_BY.TYPE);
          break;
        case ActionValue.ADD_TO_BASKET:
          setSelectionToAdd([kpkMedia.id]);
          break;
        case ActionValue.SHOW_ATTACHMENT:
          setSelectionToDownloadManager([kpkMedia.id]);
          setPreferenceFormat(DOWNLOAD_MANAGER_FORMAT_BY.OTHER);
          break;
        case ActionValue.SHARE:
          handleSelections([
            {
              elements: [
                { id: kpkMedia.id, title: kpkMedia.title.value, mediaType: kpkMedia.mediaType },
              ],
              type: ShareManagerSelectionType.UNITARY_PUBLIC_SHARE,
            },
          ]);
          break;
        default:
          logger.error(`action ${action} not implemented`);
      }
    };

    return (
      <Box
        className={clsx("MediaDetailHeaderActions", className)}
        sx={{
          display: "flex",
          padding: 1,
          height: "100%",
          alignItems: "center",
        }}
      >
        {kpkMedia.mediaType !== MEDIA_TYPE_RICH_ELEMENT &&
          (!isShare || (isShare && shareTokenDownloadAuthorized)) && (
            <DetailDownloadButton
              paletteColor={paletteColor}
              onClick={() => handleActionClick(ActionValue.DOWNLOAD)}
              buttonName={ActionValue.DOWNLOAD}
              // Useful for some overrides
              element={kpkMedia}
            />
          )}
        {showAttachments && attachments && attachments.length > 0 && (
          <ShowAttachmentButton
            onClick={() => handleActionClick(ActionValue.SHOW_ATTACHMENT)}
            attachmentsCount={attachments.length}
            buttonName={ActionValue.SHOW_ATTACHMENT}
          />
        )}
        {showAddToBasket && (
          <KButton
            size="large"
            sx={{
              height: "100%",
              marginLeft: theme.spacing(1),
            }}
            paletteColor={paletteColor}
            variant="outlined"
            onClick={() => handleActionClick(ActionValue.ADD_TO_BASKET)}
            aria-label={t("media-detail.action.add-to-basket.label") as string}
            buttonName={ActionValue.ADD_TO_BASKET}
            title={t("media-detail.action.add-to-basket.label") as string}
          >
            <KIcon fontSize="small">shopping_basket_icon</KIcon>&nbsp;
            <EditableSanitizeHtml labelKey={"media-detail.action.add-to-basket.label"} />
          </KButton>
        )}
        {showUnitaryPublicShare && hasPublicShare && (
          <KButton
            sx={{
              marginLeft: theme.spacing(1),
              height: "100%",
            }}
            size="large"
            paletteColor={paletteColor}
            variant="outlined"
            onClick={() => handleActionClick(ActionValue.SHARE)}
            buttonName={ActionValue.SHARE}
            title={t("media-detail.action.unitary-public-share.label") as string}
          >
            <KIcon fontSize={"small"} baseClassName="fa" className="fa-share" />
          </KButton>
        )}
      </Box>
    );
  };

export default MediaDetailHeaderActions;
