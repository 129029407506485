import React, { FC } from "react";

import { KpkMedia } from "@keepeek/commons";
import { KButton, KButtonProps } from "@keepeek/refront-components";
import { ComponentOverrideKey } from "@keepeek/refront-customers";
import { useTranslation } from "react-i18next";

import EditableSanitizeHtml from "../../containers/FrontEdit/EditableSanitizeHtml";
import { getCustomerOverrideComponent } from "../../lib/overrides";
import { KIcon } from "../common/KIcon";

type DetailDownloadButtonProps = {
  // element was added to allow overrides for some customers
  element?: KpkMedia;
} & KButtonProps;

const DetailDownloadButton: FC<React.PropsWithChildren<DetailDownloadButtonProps>> = function ({
  element: _element,
  ...rest
}) {
  const { t } = useTranslation();

  return (
    <KButton
      size="large"
      sx={{
        height: "100%",
      }}
      variant="outlined"
      aria-label={t("media-detail.action.download.label") as string}
      title={t("media-detail.action.download.label") as string}
      {...rest}
    >
      <KIcon fontSize="small">cloud_download</KIcon>&nbsp;
      <EditableSanitizeHtml labelKey={"media-detail.action.download.label"} />
    </KButton>
  );
};

export default getCustomerOverrideComponent(
  ComponentOverrideKey.DetailDownloadButton,
  DetailDownloadButton,
);
