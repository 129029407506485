import { FC } from "react";

import { KpkFolder } from "@keepeek/commons";
import { Box, ButtonBase, Icon, Typography } from "@mui/material";
import clsx from "clsx";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { PagesPath } from "../../../containers/App/utils";
import { useActiveFilters } from "../../../providers/businessFilter/hooks/useActiveFilters";
import { createFolderBusinessFilter } from "../../../providers/businessFilter/utils/create/createFolderBusinessFilter";
import { downloadManagerOpenState } from "../../../providers/downloadManager/atoms/selections";
import FormatTechnicalFolder from "./FormatTechnicalFolder";
import { classes } from "./styles";

const FormatTechnicalFolders: FC<{ folders?: KpkFolder[] }> = function ({ folders }) {
  const router = useRouter();
  const { setActiveFilters } = useActiveFilters();
  const openDownloadAsk = useRecoilValue(downloadManagerOpenState);
  const { t } = useTranslation();

  if (!folders) {
    return <></>;
  }

  if (router.pathname === PagesPath.ELEMENT_PAGE) {
    return (
      <Box>
        {folders.map((folder) => (
          <FormatTechnicalFolder folder={folder} key={folder.id} />
        ))}
      </Box>
    );
  } else {
    const firstFolder = folders[0];
    if (!firstFolder) {
      return <></>;
    }

    let folderTitle = firstFolder.title;

    if (!folderTitle) {
      return <></>;
    }

    if (!openDownloadAsk) {
      const limitToDisplay = 25;
      if (folderTitle && folderTitle.length > limitToDisplay) {
        folderTitle = `${folderTitle.substring(0, limitToDisplay)}...`;
      }
      return (
        <Typography component="div">
          <ButtonBase
            sx={{
              textDecoration: "underline",
              padding: 0,
              marginTop: (theme) => theme.spacing(0.5),
              borderRadius: (theme) => theme.shape.borderRadius,
            }}
            className={clsx(classes.field, classes.mediaType)}
            onClick={() => {
              setActiveFilters([
                createFolderBusinessFilter(
                  firstFolder.id,
                  firstFolder.title ?? "",
                  t("business-filter.advanced.folder.label"),
                ),
              ]);
            }}
          >
            <Typography
              variant="caption"
              title={firstFolder?.title}
              className={clsx(classes.field)}
            >
              <Icon
                sx={{ fontSize: "0.6rem", marginRight: (theme) => theme.spacing(1) }}
                baseClassName="fas"
                className="fa-folder"
              />
              {folderTitle}
            </Typography>
          </ButtonBase>
        </Typography>
      );
    } else {
      return (
        <div>
          <Typography
            variant="caption"
            className={clsx(classes.field, classes.mediaType)}
            sx={{ borderRadius: (theme) => theme.shape.borderRadius }}
          >
            <Icon
              sx={{ fontSize: "0.6rem", marginRight: (theme) => theme.spacing(1) }}
              baseClassName="fas"
              className="fa-folder"
            />
            {folderTitle}
          </Typography>
        </div>
      );
    }
  }
};

export default FormatTechnicalFolders;
