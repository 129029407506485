import React, { useEffect, useMemo } from "react";

import { FRONT_EDIT_SIDE_BAR_TAB, FrontEditPage } from "@keepeek/refront-components";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValueLoadable } from "recoil";

import { PageName, PagesPath } from "../../../containers/App/utils";
import { usePage } from "../../page/hooks/usePage";
import { frontEditState } from "../atoms/frontEdit";
import {
  frontEditCurrentRouterPageState,
  frontEditSideBarContextState,
} from "../atoms/frontEditNavigation";
import { getElementPageMediaSelector } from "../selectors/elementPageMedia";
import useFrontEditContentEditable from "./useFrontEditContentEditable";

export type UseFrontEditContext = {
  frontEdit: boolean;
  setFrontEdit: (frontEdit: boolean) => void;
  editablePages: FrontEditPage[];
  sideBarContext: FRONT_EDIT_SIDE_BAR_TAB;
  handleTabChange: (event: React.SyntheticEvent, value: FRONT_EDIT_SIDE_BAR_TAB) => void;
  currentRouterPage: string;
  handlePageChange: (page: string) => void;
};

export default function useFrontEditContext(): UseFrontEditContext {
  const [frontEdit, setFrontEdit] = useRecoilState(frontEditState);
  const { pageName } = usePage();

  const elementPageMediaLoadable = useRecoilValueLoadable(getElementPageMediaSelector);
  const elementPageMedia: number | undefined =
    elementPageMediaLoadable.state === "hasValue" ? elementPageMediaLoadable.contents : undefined;

  const [sideBarContext, setSideBarContext] = useRecoilState(frontEditSideBarContextState);
  const [currentRouterPage, setCurrentRouterPage] = useRecoilState(frontEditCurrentRouterPageState);

  const router = useRouter();

  const { sectionsEditable } = useFrontEditContentEditable(pageName);

  const handleTabChange = (_: React.SyntheticEvent, value: FRONT_EDIT_SIDE_BAR_TAB) => {
    setSideBarContext(value);
  };

  const { t } = useTranslation();

  const editablePages: FrontEditPage[] = useMemo(() => {
    return [
      {
        key: PageName.LANDING_PAGE,
        label: t("front-edit.selection-modal.landing.label"),
        pagePath: PagesPath.LANDING_PAGE,
        sections: pageName === PageName.LANDING_PAGE ? sectionsEditable : undefined,
      },
      {
        key: PageName.HOME_PAGE,
        label: t("front-edit.selection-modal.home.label") as string,
        pagePath: PagesPath.HOME_PAGE,
        sections: pageName === PageName.HOME_PAGE ? sectionsEditable : undefined,
      },
      {
        key: PageName.SEARCH_PAGE,
        label: t("front-edit.selection-modal.search.label"),
        pagePath: PagesPath.SEARCH_PAGE,
        sections: pageName === PageName.SEARCH_PAGE ? sectionsEditable : undefined,
      },
      {
        key: PageName.ELEMENT_PAGE,
        label: t("front-edit.selection-modal.element.label"),
        pagePath: `${PagesPath.ELEMENT_PAGE}?id=${elementPageMedia}`,
        sections: pageName === PageName.ELEMENT_PAGE ? sectionsEditable : undefined,
      },
    ];
  }, [elementPageMedia, pageName, sectionsEditable, t]);

  const handlePageChange = (key: string) => {
    const selectedPage = editablePages.find((page) => page.key === key);
    if (selectedPage && sideBarContext === FRONT_EDIT_SIDE_BAR_TAB.PAGES) {
      router.push(selectedPage.pagePath);
    }
  };

  useEffect(() => {
    const currentPath = router?.pathname || PagesPath.HOME_PAGE;
    // The element page has always params (ex: id=1005), so we compare the two paths with an "includes" to match path even with params
    const currentPage = editablePages.find((page) => page.pagePath.includes(currentPath));
    if (currentPage) {
      setCurrentRouterPage(currentPage.key);
    }
  }, [editablePages, router?.pathname, setCurrentRouterPage]);

  return {
    frontEdit,
    setFrontEdit,
    editablePages,
    sideBarContext,
    handleTabChange,
    currentRouterPage,
    handlePageChange,
  };
}
