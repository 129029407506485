import { KpkApiAdvancedSearchCriteria } from "@keepeek/api-client";
import { FetcherMode, isAuthenticatedSelector } from "@keepeek/commons";
import { selectorFamily } from "recoil";

import { getAllAdvancedSearchCriterias } from "../../../lib/advanced-search-utils";
import { getAxiosClientInstance } from "../../../lib/axios/axios-utils";

export type AdvancedSearchCriteriasFetcher = {
  getAdvancedSearchCriterias: () => Promise<KpkApiAdvancedSearchCriteria[] | undefined>;
};

export const advancedSearchCriteriasFetcherSelector = selectorFamily<
  AdvancedSearchCriteriasFetcher,
  FetcherMode
>({
  key: "AdvancedSearchCriteriasFetcherSelector",
  get:
    (mode) =>
    ({ get }) => {
      switch (mode) {
        case FetcherMode.SHARE:
          return {
            getAdvancedSearchCriterias: () => {
              return new Promise((resolve) => {
                resolve([]);
              });
            },
          };
        default:
          const isAuthenticated = get(isAuthenticatedSelector);
          return {
            getAdvancedSearchCriterias: () => {
              if (!isAuthenticated) {
                return Promise.resolve(undefined);
              }
              return getAllAdvancedSearchCriterias(getAxiosClientInstance());
            },
          };
      }
    },
});
