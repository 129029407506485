import { UrlObject } from "url";

import { FetcherMode, fetcherTokenIdAtom, KpkMedia } from "@keepeek/commons";
import { selectorFamily } from "recoil";

import { ELEMENT_SUMMARY_ID_PREFIX } from "../../backContext/constants";
import { BackContext } from "../../backContext/types";
import { activeRawURLFiltersFromURLSelector } from "../../businessFilter/selectors/activeFilters";

export type DataviewElementFetcher = {
  getElementBackContext: (elementId: KpkMedia["id"], query?: UrlObject["query"]) => BackContext;
};

export const dataviewElementFetcherSelector = selectorFamily<DataviewElementFetcher, FetcherMode>({
  key: "DataviewElementFetcherSelector",
  get:
    (mode) =>
    ({ get }) => {
      const tokenId = get(fetcherTokenIdAtom);
      const rawURLFilters = get(activeRawURLFiltersFromURLSelector);
      switch (mode) {
        case FetcherMode.SHARE:
          return {
            getElementBackContext: (elementId: KpkMedia["id"], query?: UrlObject["query"]) => {
              return {
                sourcePage: {
                  pathname: `/share/${tokenId}`,
                  query,
                  hash: `${ELEMENT_SUMMARY_ID_PREFIX}${elementId}`,
                },
                targetPage: { pathname: `/share/${tokenId}/element/${elementId}` },
              };
            },
          };
        default:
          return {
            getElementBackContext: (elementId: KpkMedia["id"], query?: UrlObject["query"]) => {
              return {
                sourcePage: {
                  pathname: `/search`,
                  // If we receive a query, push the query, if not try to use the saved one
                  query: query || rawURLFilters,
                  hash: `${ELEMENT_SUMMARY_ID_PREFIX}${elementId}`,
                },
                targetPage: { pathname: `/element`, query: { id: elementId } },
              };
            },
          };
      }
    },
});
