import { KpkApiFacet } from "@keepeek/api-client";
import { BasketKey, DataViewKey, KpkMedia, References } from "@keepeek/commons";
import { selectorFamily } from "recoil";

import { serializeComplexeParamsForRecoil } from "../../../lib/recoil-utils";
import { activeBusinessFiltersFromURLSelector } from "../../businessFilter/selectors/activeFilters";
import { configSectionComponentBusinessFilterSelector } from "../../config/selectors";
import { dataViewPage, dataViewSize } from "../atoms";
import { DataViewElementsSelector } from "../types";
import { dataViewSelector } from "./elements";
import { dataViewSortSelector } from "./sort";

/**
 * Get everything for a dataView automatically
 * No manual resolution, everyhting on auto, just pass a key
 *
 * DEPRECATED: we use other provider context directly into the selectors instead of giving it by props
 *
 * TODO: remove key: DataViewKey | BasketKey for a more generic approach
 * Use only the input, so we will have more chance to use the same selector twice
 * @author JBO
 *
 */
export const DEPRECATED_dataViewAutoSelector = selectorFamily<
  {
    elements: KpkMedia[];
    totalCount: number;
    facets: KpkApiFacet[];
    references: References;
  },
  DataViewKey | BasketKey
>({
  key: "DataViewAutoSelector",
  get:
    (key) =>
    ({ get }) => {
      const filtersConfiguration =
        get(configSectionComponentBusinessFilterSelector)?.filters || null;
      const filters = get(activeBusinessFiltersFromURLSelector);
      const size = get(dataViewSize(key));
      const sort = get(dataViewSortSelector(key));
      const page = get(dataViewPage(key));

      const params: DataViewElementsSelector = {
        key,
        filters,
        size,
        sort,
        page,
        filtersConfiguration,
      };

      return get(dataViewSelector(serializeComplexeParamsForRecoil(params)));
    },
});
