import { useState } from "react";

import { Box } from "@mui/material";
import { ArrayFieldTemplateItemType } from "@rjsf/utils";

import CustomArrayFieldTemplateItem from "./CustomArrayFieldTemplateItem";

type CustomArrayFieldTemplateItemsProps = {
  items: ArrayFieldTemplateItemType[];
  name: string;
};

const CustomArrayFieldTemplateItems = function ({
  items = [],
  name,
}: CustomArrayFieldTemplateItemsProps) {
  const [hovered, setHovered] = useState<number | undefined>(undefined);

  if (items.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        padding: 1,
        marginLeft: -1,
        marginRight: -1,
        flexWrap: "wrap",
        flexDirection: "column",
        width: "100%",
      }}
      className="CustomArrayFieldTemplateList"
    >
      {items.map((item) => (
        <CustomArrayFieldTemplateItem
          key={item.key}
          item={item}
          hovered={item.index === hovered}
          setHovered={setHovered}
          name={name}
        />
      ))}
    </Box>
  );
};

export default CustomArrayFieldTemplateItems;
