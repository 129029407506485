import { BasketKey, DataViewKey, KpkMedia } from "@keepeek/commons";
import { selectorFamily } from "recoil";

import { selectedElements } from "../atoms";

export const selectedElementsFromKeysSelector = selectorFamily<
  KpkMedia["id"][],
  (DataViewKey | BasketKey)[]
>({
  key: "SelectedElementsFromKeySelector",
  get:
    (keys) =>
    ({ get }) =>
      keys.flatMap((key) => get(selectedElements(key))),
  set:
    (keys) =>
    ({ set }, newValue) =>
      keys.forEach((key) => set(selectedElements(key), newValue)),
});
