import {
  ActiveFilters as _ActiveFilters,
  ActiveFiltersProps,
  ElementCount as _ElementCount,
  ElementCountProps,
  ElementSummary as _ElementSummary,
  ElementSummaryProps,
  FilterCheckbox as _FilterCheckbox,
  FilterCheckboxProps,
  FilterDate as _FilterDate,
  FilterDateProps,
  FilterDuration as _FilterDuration,
  FilterDurationProps,
  FilterRadio as _FilterRadio,
  FilterRadioProps,
  FilterText as _FilterText,
  FilterTextProps,
  KGrid as _KGrid,
  KGridProps,
} from "@keepeek/refront-components";
import { ComponentOverrideKey } from "@keepeek/refront-customers";

import { getCustomerOverrideComponent } from "../lib/overrides";

export const ActiveFilters = getCustomerOverrideComponent<ActiveFiltersProps>(
  ComponentOverrideKey.ActiveFilters,
  _ActiveFilters,
);

export const ElementCount = getCustomerOverrideComponent<ElementCountProps>(
  ComponentOverrideKey.ElementCount,
  _ElementCount,
);

export const KGrid = getCustomerOverrideComponent<KGridProps>(ComponentOverrideKey.KGrid, _KGrid);

export const ElementSummary = getCustomerOverrideComponent<ElementSummaryProps>(
  ComponentOverrideKey.ElementSummary,
  _ElementSummary,
);

export const FilterCheckbox = getCustomerOverrideComponent<FilterCheckboxProps>(
  ComponentOverrideKey.FilterCheckbox,
  _FilterCheckbox,
);

export const FilterRadio = getCustomerOverrideComponent<FilterRadioProps>(
  ComponentOverrideKey.FilterRadio,
  _FilterRadio,
);

export const FilterDate = getCustomerOverrideComponent<FilterDateProps>(
  ComponentOverrideKey.FilterDate,
  _FilterDate,
);

export const FilterText = getCustomerOverrideComponent<FilterTextProps>(
  ComponentOverrideKey.FilterText,
  _FilterText,
);

export const FilterDuration = getCustomerOverrideComponent<FilterDurationProps>(
  ComponentOverrideKey.FilterDuration,
  _FilterDuration,
);
