import { BusinessFilter, IsJsonString } from "@keepeek/commons";
import LZString from "lz-string";

import { LightBusinessFilter } from "../../types/lfb";
import { convertToLightBusinessFilter } from "./convertToLBF";

/**
 * allows you to convert business filters into light business filters and to encode them with LZString
 */
export function encodeToLightBusinessFilters(filters: BusinessFilter[]): string | null {
  if (filters) {
    return LZString.compressToEncodedURIComponent(
      JSON.stringify(convertToLightBusinessFilter(filters)),
    );
  } else {
    return null;
  }
}

/**
 * allows to decode business filters / light business filters from a character string using LZString
 */
export function decodeFilters<T extends BusinessFilter[] | LightBusinessFilter[]>(
  compressedBusinessFilter: string,
): T | null {
  if (compressedBusinessFilter) {
    const result = LZString.decompressFromEncodedURIComponent(
      decodeURIComponent(compressedBusinessFilter),
    );
    if (result && IsJsonString(result)) {
      return JSON.parse(result);
    }
  }
  return null;
}
