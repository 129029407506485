import { BasketKey, KpkBasket, KpkMedia } from "@keepeek/commons";
import { atomFamily } from "recoil";

import { localStorageEffect } from "../../../lib/recoil-persist";

export const basketListState = atomFamily<KpkBasket[], BasketKey>({
  key: "BasketListState",
  default: [],
  effects: (key) => [localStorageEffect(`BasketListState-${key}`)],
});

export const basketListCallIdState = atomFamily<number, BasketKey>({
  key: "BasketListCallIdState",
  default: 0,
});

export const basketElementsState = atomFamily<KpkMedia[], KpkBasket["id"]>({
  key: "BasketElementsState",
  default: [],
  effects: (key) => [localStorageEffect(`BasketElementsState-${key}`)],
});

export const basketCountState = atomFamily<number, KpkBasket["id"]>({
  key: "BasketCountState",
  default: 0,
  effects: (key) => [localStorageEffect(`BasketCountState-${key}`)],
});

export const currentBasketIdState = atomFamily<KpkBasket["id"] | undefined, BasketKey>({
  key: "CurrentBasketIdState",
  default: undefined,
  effects: (key) => [localStorageEffect(`CurrentBasketIdState-${key}`)],
});
