import { useMemo } from "react";

import { isServerSide, parseStringParameter, removeHash } from "@keepeek/commons";
import { useRouter } from "next/router";

import { PagesPath } from "../../../containers/App/utils";
import { verifyPath } from "../utils";

/**
 * Keycloak hook to compute redirect URI for login and logout
 */
export function useAuthRedirectUri(): {
  redirectPath: string;
  redirectUri: string;
} {
  const { locale, pathname, asPath, query } = useRouter();

  // Check if user come from a HTTP redirection on token error. It can occurs if unauthenticated user try to access a
  // page requiring an authentication.
  const pathRedirect = useMemo(
    () => verifyPath(parseStringParameter(query.pathRedirect)),
    [query.pathRedirect],
  );

  const redirectPath: string = useMemo(() => {
    if (isServerSide()) {
      return PagesPath.LANDING_PAGE;
    }
    if (pathRedirect) {
      return pathRedirect;
    }
    if (pathname === PagesPath.LANDING_PAGE) {
      return PagesPath.REDIRECT;
    }
    return asPath;
  }, [asPath, pathRedirect, pathname]);

  const redirectUri: string = useMemo(() => {
    if (isServerSide()) {
      return PagesPath.LANDING_PAGE;
    }
    if (pathRedirect) {
      return `/${locale}/redirect?path=${redirectPath}`;
    }
    if (pathname === "/") {
      // Redirect to "redirect" after authentication
      // let Redirect do the KC resolution and redirect to home after a successfull auth
      return `/${locale}/${redirectPath}`;
    }
    if (pathname === "/redirect") {
      // Avoid adding multiple /redirect in this case
      return `/${locale}/${redirectPath}`;
    }
    // Keep requested path
    return `/${locale}/redirect?path=${redirectPath}`;
  }, [locale, pathRedirect, pathname, redirectPath]);

  // Remove hash because Keycloak JavaScript adapter adds information while cheking authentication state and returning back
  // to ReFront: http://localhost:3000/redirect?path=/home#error=login_required&state=c2d7448c-b887-4e6b-8814-469590aea4f2
  // Redirect path should not contains those information or Keycloak adapter won't initialize correctly. onAuthError and
  // onInitError Keycloak events are trigerred and SSRKeycloakProvider will get stucked on LoadingComponent.

  return {
    redirectPath: removeHash(redirectPath),
    redirectUri: removeHash(redirectUri),
  };
}
