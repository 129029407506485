import { DataViewKey, FetcherMode, fetcherModeAtom } from "@keepeek/commons";
import { selector } from "recoil";

import { serializeComplexeParamsForRecoil } from "../../../lib/recoil-utils";
import { activeBusinessFiltersFromURLSelector } from "../../businessFilter/selectors/activeFilters";
import { configSectionComponentBusinessFilterSelector } from "../../config/selectors";
import { dataViewPage, dataViewSize } from "../../dataView/atoms";
import { dataViewElementsSelector } from "../../dataView/selectors/elements";
import { dataViewSortSelector } from "../../dataView/selectors/sort";
import { frontEditState } from "../atoms/frontEdit";

export const getElementPageMediaSelector = selector<number | undefined>({
  key: "GetElementPageMediaSelector",
  get: ({ get }) => {
    const fetcherMode = get(fetcherModeAtom);
    const frontEdit = get(frontEditState);
    if (fetcherMode === FetcherMode.LOGGED && frontEdit) {
      const elements = get(
        dataViewElementsSelector(
          serializeComplexeParamsForRecoil({
            key: DataViewKey.SEARCH,
            size: get(dataViewSize(DataViewKey.SEARCH)),
            page: get(dataViewPage(DataViewKey.SEARCH)),
            sort: get(dataViewSortSelector(DataViewKey.SEARCH)),
            filtersConfiguration:
              get(configSectionComponentBusinessFilterSelector)?.filters || null,
            // this only work because we're not resetting the activeBusinessFiltersFromURLSelector on another page than the "search" page
            // The search page include useLBFInit that handle the set/reset of filtesr
            // Be consious, that it can be "fragile" to use activeBusinessFiltersFromURLSelector as-is
            // we should maybe store a version of the active filters for this case
            filters: get(activeBusinessFiltersFromURLSelector),
          }),
        ),
      );
      if (elements[0]) {
        return elements[0].id;
      }
    }
    return undefined;
  },
});
