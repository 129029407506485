import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { JsonConfig } from "../../../lib/commonPagePropsUtil";
import { getAdminConfigSectionData } from "../../../lib/config/customer-config-utils";
import logger from "../../../lib/logger-utils";
import { EditoWidget } from "../../../models/configuration/components/editoWidget";
import { Type } from "../../../models/configuration/definitions/widget";

import { EditoWidgetProps } from ".";

export function getEditoWidgetConfiguration(
  customerSectionConfiguration: JsonConfig[],
  widgetId: string,
): EditoWidgetProps | undefined {
  const editoWidgets = getAdminConfigSectionData<EditoWidget[]>(
    customerSectionConfiguration,
    CONFIGURATION_SECTION_PATH.COMPONENTS_EDITO_WIDGET,
  );

  if (editoWidgets?.length === undefined || editoWidgets.length === 0) {
    logger.info(`getEditoWidgetConfiguration - editoWidgets is empty or undefined`);
    return;
  }

  const editoWidget = editoWidgets?.find((e) => e.id === widgetId);

  if (!editoWidget) {
    logger.info(`getEditoWidgetConfiguration - can't find edito widget id ${widgetId}`);
    return;
  }
  // For SSR, do not return any object undefined cause it can't be used in JSON
  let configuration: EditoWidgetProps = { widgetId: editoWidget.id, type: Type.Edito };
  const backgroundColor = editoWidget?.backgroundColor;
  const content = editoWidget?.content;
  const editoWidgetMaxWidthContent = editoWidget?.editoWidgetMaxWidthContent;
  const editoWidgetPaddingY = editoWidget?.editoWidgetPaddingY;
  const editoWidgetPaddingX = editoWidget?.editoWidgetPaddingX;
  // Include only when defined
  if (backgroundColor) {
    configuration = { ...configuration, backgroundColor };
  }
  if (content) {
    configuration = { ...configuration, content };
  }
  if (editoWidgetMaxWidthContent) {
    configuration = { ...configuration, editoWidgetMaxWidthContent };
  }
  if (editoWidgetPaddingY) {
    configuration = { ...configuration, editoWidgetPaddingY };
  }
  if (editoWidgetPaddingX) {
    configuration = { ...configuration, editoWidgetPaddingX };
  }
  return configuration;
}
