import { styled, Box } from "@mui/material";

export const PREFIX = "FormatTechnicalFields";

export const classes = {
  root: `${PREFIX}-root`,
  field: `${PREFIX}-field`,
  mediaType: `${PREFIX}-mediaType`,
};

export const StyledFormatTechnicalFields = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    marginTop: theme.spacing(0.5),
    display: "flex",
    flexWrap: "wrap",
  },

  [`& .${classes.field}`]: {
    fontWeight: "bold",
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.2),

    "& small": {
      fontWeight: "normal",
      paddingLeft: theme.spacing(0.1),
    },
  },

  [`& .${classes.mediaType}`]: {
    background: theme.palette.grey[200],
    padding: theme.spacing(0.25, 0.5),
    textTransform: "uppercase",
  },
}));
