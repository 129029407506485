import { removeUndefinedDeep } from "@keepeek/commons";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { JsonConfig } from "../../../lib/commonPagePropsUtil";
import { getAdminConfigSectionData } from "../../../lib/config/customer-config-utils";
import logger from "../../../lib/logger-utils";
import { AssociationsWidget } from "../../../models/configuration/components/associationsWidget";
import { Type } from "../../../models/configuration/definitions/widget";

import { AssociationsWidgetProps } from ".";

export function getAssociationsWidgetConfiguration(
  customerSectionConfiguration: JsonConfig[],
  widgetId: AssociationsWidget["id"],
): AssociationsWidgetProps | undefined {
  const associationsWidgets = getAdminConfigSectionData<AssociationsWidget[]>(
    customerSectionConfiguration,
    CONFIGURATION_SECTION_PATH.COMPONENTS_ASSOCIATIONS_WIDGET,
  );

  if (associationsWidgets?.length === undefined || associationsWidgets.length === 0) {
    logger.info(`getAssociationsWidgetConfiguration - associationsWidgets is empty or undefined`);
    return;
  }

  const associationsWidget: AssociationsWidget | undefined = associationsWidgets?.find(
    (khw) => khw.id === widgetId,
  );

  if (!associationsWidget) {
    logger.info(
      `getAssociationsWidgetConfiguration - can't find associationsWidgets id ${widgetId}`,
    );
    return;
  }

  // For SSR, do not return any object undefined cause it can't be used in JSON
  const config: AssociationsWidgetProps = {
    widgetId: associationsWidget.id,
    ...associationsWidget,
    type: Type.Associations,
  };
  // Simply spread associationsWidget then remove id from it
  delete config.id;

  return removeUndefinedDeep(config);
}
