import {
  createMediaAttachmentUri,
  createPublicShareMediaAttachmentUri,
  getMediaAttachments,
  getPublicShareMediaAttachments,
  KpkApiDownloadLink,
  KpkApiGetMediaAttachmentParams,
  KpkApiGetMediaAttachmentsEmbeddedMapping,
  KpkApiGetMediaAttachmentsParams,
  KpkApiPagedResult,
} from "@keepeek/api-client";
import {
  buildParamsForShare,
  FetcherMode,
  fetcherTokenIdAtom,
  shareTokenAxiosHeadersSelector,
  isAuthenticatedSelector,
} from "@keepeek/commons";
import { AxiosResponse } from "axios";
import { selectorFamily } from "recoil";

import { getAxiosClientInstance } from "../../../lib/axios/axios-utils";

export type AttachmentFetcher = {
  getMediaAttachments: (
    params: KpkApiGetMediaAttachmentsParams,
  ) =>
    | Promise<AxiosResponse<KpkApiPagedResult<KpkApiGetMediaAttachmentsEmbeddedMapping>>>
    | Promise<undefined>;
  createMediaAttachmentUri: (
    params: KpkApiGetMediaAttachmentParams,
  ) => Promise<AxiosResponse<KpkApiDownloadLink>> | Promise<undefined>;
};

export const attachmentFetcherSelector = selectorFamily<AttachmentFetcher, FetcherMode>({
  key: "AttachmentFetcherSelector",
  get:
    (mode) =>
    ({ get }) => {
      switch (mode) {
        case FetcherMode.SHARE:
          const tokenId = get(fetcherTokenIdAtom);
          const headers = get(shareTokenAxiosHeadersSelector);

          return {
            getMediaAttachments: (params) => {
              return getPublicShareMediaAttachments(
                getAxiosClientInstance(),
                buildParamsForShare(params, headers, tokenId),
              );
            },
            createMediaAttachmentUri: (params) => {
              return createPublicShareMediaAttachmentUri(
                getAxiosClientInstance(),
                buildParamsForShare(params, headers, tokenId),
              );
            },
          };
        default:
          const isAuthenticated = get(isAuthenticatedSelector);
          return {
            getMediaAttachments: (params) => {
              if (!isAuthenticated) {
                return Promise.resolve(undefined);
              }
              return getMediaAttachments(getAxiosClientInstance(), params);
            },
            createMediaAttachmentUri: (params) => {
              if (!isAuthenticated) {
                return Promise.resolve(undefined);
              }
              return createMediaAttachmentUri(getAxiosClientInstance(), params);
            },
          };
      }
    },
});
