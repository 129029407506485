import {
  getMedia,
  getMediaSharePublic,
  KpkApiMediaDownloadLevel,
  KpkApiPublicShareExportDefinition,
  KpkApiPublicShareStatus,
  postPublicShareSelection,
  postPublicShareSelectionMediasCollection,
} from "@keepeek/api-client";
import {
  buildParamsForShare,
  currentUserQuery,
  fetcherTokenIdAtom,
  getAxiosInstanceAtom,
  KpkMedia,
  shareTokenAxiosHeadersSelector,
} from "@keepeek/commons";
import { selector, selectorFamily } from "recoil";

import { getAxiosClientInstance } from "../../../lib/axios/axios-utils";
import logger from "../../../lib/logger-utils";
import {
  shareUnitaryPublicShareSizeHeightState,
  shareUnitaryPublicShareSizeState,
  shareUnitaryPublicShareSizeWidthState,
  updateUnitaryPublicSharedState,
} from "../atoms/unitaryPublicShare";
import { ShareManagerUnitaryPublicShareEmbed } from "../models";
import { shareManagerSelectionsIdsSelector } from "./shareManagerSelectionsSelector";

export const mediaApiPublicShareSelector = selectorFamily<
  {
    detailLink?: string;
    link?: string;
    status: KpkApiPublicShareStatus;
    deactivationDate?: Date;
    exportDefinition: KpkApiPublicShareExportDefinition;
    downloadLevel: KpkApiMediaDownloadLevel;
  },
  { id: KpkMedia["id"]; mediaType: KpkMedia["mediaType"] }
>({
  key: "MediaApiPublicShareSelector",
  get:
    (element) =>
    async ({ get }) => {
      const axiosInstance = get(getAxiosInstanceAtom);
      if (!axiosInstance) {
        return {
          link: undefined,
          status: KpkApiPublicShareStatus.Deactivated,
          exportDefinition: KpkApiPublicShareExportDefinition.None,
          downloadLevel: KpkApiMediaDownloadLevel.None,
        };
      }
      // Update the query
      get(updateUnitaryPublicSharedState);

      const mediaSharePublic = await getMediaSharePublic(axiosInstance(), { mediaId: element.id });
      const media = await getMedia(axiosInstance(), { mediaId: element.id });
      const downloadLevel = media.data.downloadLevel as KpkApiMediaDownloadLevel;
      const status: KpkApiPublicShareStatus =
        mediaSharePublic.data.status || KpkApiPublicShareStatus.Deactivated;
      const link =
        mediaSharePublic?.data?._links && status === KpkApiPublicShareStatus.Activated
          ? mediaSharePublic?.data?._links["kpk:link"].href
          : undefined;
      const detailLink =
        mediaSharePublic?.data?._links && status === KpkApiPublicShareStatus.Activated
          ? mediaSharePublic?.data?._links["kpk:detail"].href
          : undefined;
      const deactivationDate =
        mediaSharePublic?.data?._links && status === KpkApiPublicShareStatus.Activated
          ? mediaSharePublic?.data?.deactivationDate
          : undefined;
      const exportDefinition: KpkApiPublicShareExportDefinition =
        mediaSharePublic?.data?.exportDefinition || KpkApiPublicShareExportDefinition.HD;

      return {
        detailLink,
        link,
        status,
        deactivationDate,
        exportDefinition,
        downloadLevel,
      };
    },
});

export const shareUnitaryPublicShareEmbedSelector = selectorFamily<
  ShareManagerUnitaryPublicShareEmbed["embed"] | undefined,
  { id: KpkMedia["id"]; mediaType: KpkMedia["mediaType"] }
>({
  key: "ShareUnitaryPublicShareEmbedSelector",
  get:
    (element) =>
    ({ get }) => {
      const sizeType = get(shareUnitaryPublicShareSizeState);
      const sizeHeight = get(shareUnitaryPublicShareSizeHeightState);
      const sizeWidth = get(shareUnitaryPublicShareSizeWidthState);

      const mediaPublicShare = get(mediaApiPublicShareSelector(element));
      if (mediaPublicShare) {
        if (sizeType === "AUTO") {
          return `<object data="${mediaPublicShare.link ?? ""}" type="${element.mediaType}" />`;
        }
        return `<object data="${
          mediaPublicShare.link ?? ""
        }" width="${sizeWidth}" height="${sizeHeight}" type="${element.mediaType}"/>`;
      }
      return undefined;
    },
});

export const shareUnitaryPublicShareSelector = selectorFamily<
  ShareManagerUnitaryPublicShareEmbed,
  { id: KpkMedia["id"]; title: KpkMedia["title"]["value"]; mediaType: KpkMedia["mediaType"] }
>({
  key: "ShareUnitaryPublicShareSelector",
  get:
    (element) =>
    ({ get }) => {
      const { detailLink, link, status, deactivationDate, exportDefinition, downloadLevel } = get(
        mediaApiPublicShareSelector(element),
      );
      const embed = get(shareUnitaryPublicShareEmbedSelector(element));

      return {
        elementId: element.id,
        detailLink,
        link,
        status,
        embed,
        mediaType: element.mediaType,
        deactivationDate,
        title: element.title,
        exportDefinition,
        downloadLevel,
      };
    },
});

export const shareUnitaryPublicSharesSelector = selectorFamily<
  ShareManagerUnitaryPublicShareEmbed[],
  { id: KpkMedia["id"]; title: KpkMedia["title"]["value"]; mediaType: KpkMedia["mediaType"] }[]
>({
  key: "ShareElementsSelector",
  get:
    (elements) =>
    ({ get }) =>
      elements.map((element) => get(shareUnitaryPublicShareSelector(element))),
});

export const shareManagerSelectionIdQuery = selector<{
  selectionId: string | null;
}>({
  key: "shareManagerSelectionIdQuery",
  get: async ({ get }) => {
    const elementIds = get(shareManagerSelectionsIdsSelector);
    const userId = get(currentUserQuery).id;
    const tokenId = get(fetcherTokenIdAtom);
    const headers = get(shareTokenAxiosHeadersSelector);

    if (elementIds.length > 0 && tokenId && headers) {
      try {
        // Create a selection
        const postSelectionQuery = await postPublicShareSelection(
          getAxiosClientInstance(),
          buildParamsForShare({ userId }, headers, tokenId),
        );
        if (!postSelectionQuery) {
          throw new Error("cannot post selection without authenticated user");
        }
        const {
          data: { id: selectionId },
        } = postSelectionQuery;

        // Add element(s) to the selection
        await postPublicShareSelectionMediasCollection(
          getAxiosClientInstance(),
          buildParamsForShare(
            {
              userId,
              selectionId,
              mediaIds: elementIds,
            },
            headers,
            tokenId,
          ),
        );
        return { selectionId };
      } catch (error) {
        logger.error(`shareManagerSelectionIdQuery: ${error}`);
      }
    }
    return {
      selectionId: null,
    };
  },
});
