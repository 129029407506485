import { useRecoilState, useSetRecoilState } from "recoil";

import { shareManagerIsOpenState } from "../atoms/open";
import { updateUnitaryPublicSharedState } from "../atoms/unitaryPublicShare";

export type UseShareManagerOpen = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

export const useShareManagerOpen = (): UseShareManagerOpen => {
  const [isOpen, setIsOpen] = useRecoilState(shareManagerIsOpenState);

  const setUpdateUnitaryPublicShared = useSetRecoilState(updateUnitaryPublicSharedState);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
    setUpdateUnitaryPublicShared((prev) => prev + 1);
  };

  return {
    isOpen,
    open,
    close,
  };
};
