import React, { FC } from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ResourceType } from "../../utils";

const ExtensionList: FC<
  React.PropsWithChildren<{
    resourceType: ResourceType;
  }>
> = function ({ resourceType }) {
  const { t } = useTranslation();
  return (
    <>
      {resourceType !== ResourceType.UNKNOWN && (
        <Box>{t(`admin:resource.upload.file.recommendation.${resourceType}`)}</Box>
      )}
      {resourceType !== ResourceType.VIDEO && (
        <Box>{t(`admin:resource.upload.file.extensions.${resourceType}`)}</Box>
      )}
    </>
  );
};
export default ExtensionList;
