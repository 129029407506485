import { Theme } from "@mui/material";

export const useThemeTemplates = (adminTheme: Theme) => {
  const themeTemplates = {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          // -- GLOBAL OVERRIDES --
          // CSS Overrides that impact all templates and doesn't need to specify a specific css class

          // Commented for now because it cause visual regression on existing refront
          /* ".KButton:hover": {
            backgroundColor: adminTheme.palette.action.hover
              ? `${adminTheme.palette.action.hover} !important`
              : "inherit",
          }, */

          // -- MENU COMPONENTS --

          // Menu color primary
          ".menu-primary": {
            backgroundColor: adminTheme.palette.primary.main,
            padding: adminTheme.spacing(1, 0),
          },
          ".menu-primary .EditoWidget ul": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
            padding: "0 1rem",
            listStyle: "none",
            textTransform: "uppercase",
            color: adminTheme.palette.common.white,
          },
          ".menu-primary .EditoWidget ul a": {
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          },

          // Menu color white
          ".menu-white": {
            backgroundColor: adminTheme.palette.common.white,
            padding: adminTheme.spacing(1, 0),
            borderTop: `1px solid ${adminTheme.palette.grey[300]}`,
            borderBottom: `1px solid ${adminTheme.palette.grey[300]}`,
          },
          ".menu-white .EditoWidget ul": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
            padding: "0 1rem",
            listStyle: "none",
            textTransform: "uppercase",
            color: adminTheme.palette.common.black,
          },
          ".menu-white .EditoWidget ul a": {
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          },

          // Menu color primary light
          ".menu-primary-light": {
            backgroundColor: adminTheme.palette.primary.light,
            padding: adminTheme.spacing(1, 0),
          },
          ".menu-primary-light .EditoWidget ul": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
            padding: "0 1rem",
            listStyle: "none",
            textTransform: "uppercase",
            color: adminTheme.palette.common.black,
          },
          ".menu-primary-light .EditoWidget ul a": {
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          },

          // -- SEARCH WIDGETS --
          // Widget with color
          ".search-color": {
            "& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot": {
              paddingTop: 0,
              paddingBottom: 0,
            },
            "& fieldset": {
              borderColor: adminTheme.palette.primary.main,
            },
            "& .KSplitButton": {
              paddingTop: adminTheme.spacing(1),
              paddingBottom: adminTheme.spacing(1),
              "& button": {
                color: adminTheme.palette.primary.main,
                textTransform: "capitalize",
                fontWeight: "bold",
                fontSize: adminTheme.spacing(2),
              },
            },
            "& .KSearchInput-serchTextField .MuiOutlinedInput-input.MuiAutocomplete-input": {
              padding: adminTheme.spacing(2, 0.5, 2, 0.75),
              borderColor: adminTheme.palette.primary.main,
            },
            "& .KSearchInput .MuiInputAdornment-root button": {
              backgroundColor: adminTheme.palette.primary.main,
              color: "#FFFFFF",
              borderColor: adminTheme.palette.primary.main,
              "&:hover": {
                backgroundColor: adminTheme.palette.action.hover,
                borderColor: adminTheme.palette.action.hover,
              },
            },
          },
          // Widget with no color
          ".search-no-color": {
            "& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot": {
              paddingTop: 0,
              paddingBottom: 0,
            },
            "& fieldset": {
              borderColor: adminTheme.palette.primary.main,
            },
            "& .KSplitButton": {
              paddingTop: adminTheme.spacing(1),
              paddingBottom: adminTheme.spacing(1),
              "& button": {
                color: adminTheme.palette.primary.main,
                textTransform: "capitalize",
                fontWeight: "bold",
                fontSize: adminTheme.spacing(2),
              },
            },
            "& .KSearchInput-serchTextField .MuiOutlinedInput-input.MuiAutocomplete-input": {
              padding: adminTheme.spacing(2, 0.5, 2, 0.75),
              borderColor: adminTheme.palette.primary.main,
            },
            "& .KSearchInput .MuiInputAdornment-root button": {
              border: "none",
              "&:hover": {
                backgroundColor: adminTheme.palette.action.hover,
              },
            },
          },
        },
      },
    },
  };

  return {
    themeTemplates,
  };
};
