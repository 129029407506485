import { fetcherModeAtom, KpkAttachment, KpkMedia } from "@keepeek/commons";
import { selectorFamily } from "recoil";

import logger from "../../../lib/logger-utils";
import { attachmentFetcherSelector } from "../../fetcher/selectors/attachment";

export type KpkAttachmentUri = {
  uri?: string | null;
  id?: number;
};

export const attachmentUriQuerySelector = selectorFamily<
  KpkAttachmentUri | undefined,
  { elementId?: KpkMedia["id"]; attachmentId?: KpkAttachment["id"] }
>({
  key: "AttachmentUriQuerySelector",
  get:
    ({ elementId, attachmentId }) =>
    async ({ get }) => {
      if (elementId && attachmentId) {
        const { createMediaAttachmentUri } = get(attachmentFetcherSelector(get(fetcherModeAtom)));
        const call = await createMediaAttachmentUri({
          mediaId: elementId,
          attachmentId,
        });
        if (!call) {
          return undefined;
        }
        const { data } = call;

        return { uri: data.uri || null, id: attachmentId };
      }
      if (!elementId) {
        logger.debug(`attachmentUriQuerySelector: elementId undefined`);
      }
      if (!attachmentId) {
        logger.debug(`attachmentUriQuerySelector: attachmentId undefined`);
      }
    },
});

export const attachmentsUriSelector = selectorFamily<
  (KpkAttachmentUri | undefined)[] | undefined,
  { elementId?: KpkMedia["id"]; attachmentId?: KpkAttachment["id"] }[] | undefined
>({
  key: "AttachmentsUriSelector",
  get:
    (iterator) =>
    ({ get }) => {
      if (iterator) {
        return iterator.map((i) => get(attachmentUriQuerySelector(i)));
      }
    },
});
