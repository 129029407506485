import React, { FC } from "react";

import { DataViewKey, KpkMedia } from "@keepeek/commons";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRecoilValueLoadable } from "recoil";

import logger from "../../lib/logger-utils";
import { Element } from "../../models/configuration/pages/element";
import { dataViewElement } from "../../providers/dataView/selectors/element";
import { useElement } from "../../providers/element/hooks";

export type FormatElementIdProps = {
  elementIdRules: Element["elementId"];
  elementId: KpkMedia["id"];
  dataViewKey?: DataViewKey;
};

const FormatElementId: FC<FormatElementIdProps> = function ({
  elementIdRules,
  elementId,
  dataViewKey = DataViewKey.SEARCH,
}) {
  const { t } = useTranslation();
  // Select the right element from the store
  const elementQuery = useRecoilValueLoadable(dataViewElement({ key: dataViewKey, id: elementId }));
  const { element: elementFromDetail } = useElement();
  let element: undefined | KpkMedia;
  if (elementFromDetail?.id === elementId) {
    element = elementFromDetail;
  } else {
    element = elementQuery.state === "hasValue" ? elementQuery.contents : undefined;
  }

  // Don't show if the rules don't ask for it
  if (!elementIdRules?.identifier || !elementId || !element) {
    return null;
  }

  // Create identifier and extension
  let identifier: string | undefined | number;
  let extension: string | undefined | number;
  switch (elementIdRules.identifier) {
    // Be permisive for the name
    case "id":
    case "ID":
    case "iD":
    case "Id":
      identifier = element.id;
      extension = t("format-element-id.identifier-extension.id.label") as string;
      break;

    default:
      logger.warn("FormatElementId: identifier not yet supported");
      break;
  }

  // Don't show if we have no formatter yet
  if (identifier === undefined) {
    return null;
  }

  // Print result
  return (
    <Typography variant="overline" component="span" title={`${extension}`}>
      <strong>{identifier}</strong>
      {elementIdRules.extension && <small>{` ${extension}`}</small>}
    </Typography>
  );
};

export default FormatElementId;
