import { useEffect } from "react";

import { useRouter } from "next/router";
import { useRecoilState } from "recoil";

import { backContextState } from "./atoms";
import { initBackContextState } from "./constants";
import { BackContext } from "./types";

type UseBackContext = {
  goToPageWithBackContext: (backContext: BackContext) => void;
  goBackToSearch: () => void;
};

export const useBackContext = (): UseBackContext => {
  const [context, setContext] = useRecoilState(backContextState);
  const router = useRouter();

  useEffect(() => {
    if (router.pathname === context.sourcePage?.pathname) {
      // flush the context when we're back on the search page
      setContext(initBackContextState);
    }
    // Want it on page change only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  const goToPageWithBackContext = (backContext: BackContext) => {
    // set the context when we're leaving for a page
    setContext(backContext);
    router.push({
      pathname: backContext.targetPage?.pathname,
      query: backContext.targetPage?.query,
      hash: backContext.targetPage?.hash,
    });
  };

  const goBackToSearch = () => {
    // In case we have no sourcePage to go back
    // Fallback to router.back() just to be sure to provide feedback to the user
    if (!context.sourcePage) {
      return router.back();
    }
    // Take the context
    const { pathname, query, hash } = context.sourcePage;
    // Flush the context
    setContext(initBackContextState);
    // navigate with context to the search page
    router.push({ pathname, query, hash });
  };

  return { goToPageWithBackContext, goBackToSearch };
};
