import {
  getAdvancedSearchCriterias,
  KpkApiAdvancedSearchCriteria,
  KpkApiGetAdvancedSearchCriteriasEmbeddedMapping,
  KpkApiPagedResult,
} from "@keepeek/api-client";
import { AxiosInstance } from "axios";

const sizeToFetch = 500;

export async function getAllAdvancedSearchCriterias(
  axiosInstance: AxiosInstance,
): Promise<KpkApiAdvancedSearchCriteria[]> {
  let page = 1;
  const data = await fetchData(axiosInstance, page);
  let ret: KpkApiAdvancedSearchCriteria[] = data._embedded?.criteria || [];
  const totalCount = data.totalCount || 0;
  if (totalCount > 0) {
    while (ret.length < totalCount) {
      page++;
      const tmp = await fetchData(axiosInstance, page);
      if (!tmp._embedded || !tmp._embedded.criteria || tmp._embedded.criteria.length === 0) {
        break;
      }
      ret = [...ret, ...(tmp._embedded?.criteria || [])];
    }
  }
  return ret;
}

async function fetchData(
  axiosInstance: AxiosInstance,
  page: number,
): Promise<KpkApiPagedResult<KpkApiGetAdvancedSearchCriteriasEmbeddedMapping>> {
  const result = await getAdvancedSearchCriterias(axiosInstance, { size: sizeToFetch, page });
  return result.data;
}
