import { useMemo } from "react";

import { BusinessFilter, KpkFilter, KpkFilterType, KpkFilterValue } from "@keepeek/commons";
import { hasActiveValues } from "@keepeek/refront-components";
import * as Sentry from "@sentry/nextjs";
import { uniqBy } from "lodash";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { SentryBreadcrumbCategory } from "../../../lib/sentry/breadcrumbs";
import { fixedFacetsState } from "../atoms/fixedFacets";
import { activeFilterValuesFromURLSelector } from "../selectors/activeFilters";
import {
  convertBusinessFilterToKpkFilter,
  convertKpkFilterToBusinessFilter,
} from "../utils/converters/convertBusinessFilterWithKpkFilter";
import { useActiveFilters } from "./useActiveFilters";

export const useFilter = ({
  businessFilter,
  multipleValues = false,
  noFacetsCount = false,
}: {
  businessFilter: BusinessFilter;
  multipleValues?: boolean;
  noFacetsCount?: boolean;
}) => {
  const { activeFilters, setActiveFilters } = useActiveFilters();
  const activeValues = useRecoilValue(
    activeFilterValuesFromURLSelector({
      filterId: businessFilter.filter.id,
      nature: businessFilter.nature,
    }),
  );
  const setFixedFacets = useSetRecoilState(fixedFacetsState);

  const update = ({
    filter,
    resetFilters = false,
  }: {
    filter: KpkFilter;
    resetFilters?: boolean;
  }) => {
    // If we have no more active value, remove the filter from LBF
    if (!hasActiveValues(filter)) {
      removeFilter();
      // Remove filter from fixed facets multi if it was in it
      setFixedFacets((prev) => {
        const index = prev.findIndex((p) => p.filter.id === kpkFilter.id);
        if (index > -1) {
          prev.splice(index, 1);
        }
        return prev;
      });
    } else {
      // if we accept multiple value send the filter as-is, it will pile up
      if (multipleValues) {
        addFilter({ filter, resetFilters });
        // Set the facet-multi filter in fixed facets to keep a fixed state of the filter
        setFixedFacets((prev) => uniqBy([...prev, businessFilter], (b) => b.filter.id));
      } else {
        // TODO: If not, remove the other active values and only add the new one
        // 1) Make a diff
        // 2) replace all except the new one with businessFilter
        // Add
        addFilter({ filter, resetFilters });
      }
    }
  };

  /**
   * Add this filter as-is into lbf.
   * Replace the same filters with the new one in case it is already in
   * @author JBO
   *
   * @param {{ filter: KpkFilter; resetFilters?: boolean }} { filter, resetFilters = false }
   */
  const addFilter = ({
    filter,
    resetFilters = false,
  }: {
    filter: KpkFilter;
    resetFilters?: boolean;
  }) => {
    const newBusinessFiltersToLBF: BusinessFilter[] = [];
    const currentBusinessFilter = convertKpkFilterToBusinessFilter(filter);
    if (!resetFilters) {
      if (
        activeFilters.some(
          (af) =>
            af.filter.id === currentBusinessFilter.filter.id &&
            af.nature === currentBusinessFilter.nature,
        )
      ) {
        newBusinessFiltersToLBF.push(
          ...activeFilters.map((af) =>
            af.filter.id === currentBusinessFilter.filter.id ? currentBusinessFilter : af,
          ),
        );
      } else {
        newBusinessFiltersToLBF.push(...activeFilters);
        newBusinessFiltersToLBF.push(currentBusinessFilter);
      }
    } else {
      newBusinessFiltersToLBF.push(currentBusinessFilter);
    }
    Sentry.addBreadcrumb({
      category: SentryBreadcrumbCategory.USER_INTERACTION,
      message: `[useFilter] useFilter add filter IDs: ${newBusinessFiltersToLBF
        .map((f) => f.filter.id)
        .join(" ")}`,
    });
    setActiveFilters(newBusinessFiltersToLBF);
  };

  /**
   * Remove this business filters from lbf
   * @author JBO
   */
  const removeFilter = () => {
    Sentry.addBreadcrumb({
      category: SentryBreadcrumbCategory.USER_INTERACTION,
      message: `[useFilter] useFilter remove filter ID: ${businessFilter.filter.id}`,
    });
    setActiveFilters(
      activeFilters.filter(
        (af) => !(af.filter.id === businessFilter.filter.id && af.nature === businessFilter.nature),
      ),
    );
  };

  /**
   * Reset all filters to no active values
   * @author JBO
   */
  const resetFilters = () => {
    Sentry.addBreadcrumb({
      category: SentryBreadcrumbCategory.USER_INTERACTION,
      message: `[useFilter] useFilter reset filters`,
    });
    setActiveFilters([]);
  };

  const kpkFilter = useMemo(() => {
    const filter = convertBusinessFilterToKpkFilter(businessFilter);
    const values: KpkFilterValue[] = filter.values.map((v) => {
      const value = activeValues.find((av) => av.id === v.id) || v;
      /**
       * If we do not want to show the facet result count
       * Return the value but with the label and the title as the ID
       */
      if (
        noFacetsCount &&
        (filter.type === KpkFilterType.RadioFacet || filter.type === KpkFilterType.Checkbox)
      ) {
        if (typeof value.id === "number") {
          const labelWithoutCount = value.label.split(" (")[0];
          return { ...value, label: labelWithoutCount, title: labelWithoutCount };
        }
        return { ...value, label: value.id, title: value.id };
      }
      return value;
    });
    const mergedWithActiveValues: KpkFilter = {
      ...filter,
      values,
    };
    return mergedWithActiveValues;
  }, [activeValues, businessFilter, noFacetsCount]);

  const hasActiveValuesIn = useMemo(() => hasActiveValues(kpkFilter), [kpkFilter]);

  return {
    update,
    addFilter,
    removeFilter,
    resetFilters,
    kpkFilter,
    hasActiveValues: hasActiveValuesIn,
    activeFilters,
  };
};
