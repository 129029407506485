import { FC } from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { ButtonBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import clsx from "clsx";

import { AssociationsWidget } from "../../../../../models/configuration/components/associationsWidget";
import { CustomWidget } from "../../../../../models/configuration/components/customWidget";
import { EditoWidget } from "../../../../../models/configuration/components/editoWidget";
import { FooterWidget } from "../../../../../models/configuration/components/footerWidget";
import { KHeaderWidget } from "../../../../../models/configuration/components/kHeaderWidget";
import { KImageWidget } from "../../../../../models/configuration/components/kImageWidget";
import { KVideoWidget } from "../../../../../models/configuration/components/kVideoWidget";
import { Mosaic } from "../../../../../models/configuration/components/mosaic";
import { SearchWidget } from "../../../../../models/configuration/components/searchWidget";
import { SpacerWidget } from "../../../../../models/configuration/components/spacerWidget";
import { TabsWidget } from "../../../../../models/configuration/components/tabsWidget";
import { Type } from "../../../../../models/configuration/definitions/widget";
import logger from "../../../../logger-utils";
import WidgetCardSelectorAssociation from "./WidgetCardSelectorAssociations";
import WidgetCardSelectorAttachments from "./WidgetCardSelectorAttachments";
import WidgetCardSelectorCustom from "./WidgetCardSelectorCustom";
import WidgetCardSelectorEdito from "./WidgetCardSelectorEdito";
import WidgetCardSelectorFooter from "./WidgetCardSelectorFooter";
import WidgetCardSelectorHeader from "./WidgetCardSelectorHeader";
import WidgetCardSelectorImage from "./WidgetCardSelectorImage";
import WidgetCardSelectorMosaic from "./WidgetCardSelectorMosaic";
import WidgetCardSelectorSearch from "./WidgetCardSelectorSearch";
import WidgetCardSelectorSheet from "./WidgetCardSelectorSheet";
import WidgetCardSelectorSpacer from "./WidgetCardSelectorSpacer";
import WidgetCardSelectorTabs from "./WidgetCardSelectorTabs";
import WidgetCardSelectorVideo from "./WidgetCardSelectorVideo";

const PREFIX = "WidgetCardSelector";

const classes = {
  root: `${PREFIX}-root`,
  innerBox: `${PREFIX}-innerBox`,
};

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  [`&.${classes.root}`]: {
    height: theme.spacing(20),
    width: theme.spacing(20),
    borderColor: theme.palette.primary.main,
    border: "1px solid",
    borderRadius: theme.shape.borderRadius,
    position: "relative",
    backgroundColor: "white",
  },

  [`& .${classes.innerBox}`]: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
  },
}));

export type WidgetCardSelectorType = {
  type: Type;
  id: string;
  ui?:
    | AssociationsWidget
    | EditoWidget
    | FooterWidget
    | KHeaderWidget
    | KImageWidget
    | Mosaic
    | SearchWidget
    | SpacerWidget
    | CustomWidget
    | TabsWidget
    | KVideoWidget;
};

type WidgetCardSelectorProps = {
  onClick: () => void;
} & WidgetCardSelectorType &
  BaseComponentProps;

const WidgetCardSelector: FC<React.PropsWithChildren<WidgetCardSelectorProps>> = function ({
  type,
  ui,
  onClick,
  className,
  testId = "WidgetCardSelector",
}) {
  // Simple UI Representation of the widget
  const content = () => {
    switch (type) {
      case Type.Associations:
        return (
          <WidgetCardSelectorAssociation
            className={classes.innerBox}
            {...(ui as AssociationsWidget)}
          />
        );
      case Type.Attachments:
        return <WidgetCardSelectorAttachments className={classes.innerBox} {...ui} />;
      case Type.Edito:
        return <WidgetCardSelectorEdito className={classes.innerBox} {...(ui as EditoWidget)} />;
      case Type.Footer:
        return <WidgetCardSelectorFooter className={classes.innerBox} {...(ui as FooterWidget)} />;
      case Type.Header:
        return <WidgetCardSelectorHeader className={classes.innerBox} {...(ui as KHeaderWidget)} />;
      case Type.Image:
        return <WidgetCardSelectorImage className={classes.innerBox} {...(ui as KImageWidget)} />;
      case Type.Mosaic:
        return <WidgetCardSelectorMosaic className={classes.innerBox} {...(ui as Mosaic)} />;
      case Type.Search:
        return <WidgetCardSelectorSearch className={classes.innerBox} {...(ui as SearchWidget)} />;
      case Type.Sheet:
        return <WidgetCardSelectorSheet className={classes.innerBox} {...ui} />;
      case Type.Spacer:
        return <WidgetCardSelectorSpacer className={classes.innerBox} {...(ui as SpacerWidget)} />;
      case Type.Custom:
        return <WidgetCardSelectorCustom className={classes.innerBox} {...(ui as CustomWidget)} />;
      case Type.Tabs:
        return <WidgetCardSelectorTabs className={classes.innerBox} {...(ui as TabsWidget)} />;
      case Type.Video:
        return <WidgetCardSelectorVideo className={classes.innerBox} {...(ui as KVideoWidget)} />;
      default:
        logger.debug("WidgetCardSelector: widget type not supported yet");
        return null;
    }
  };

  return (
    <StyledButtonBase
      className={clsx("WidgetCardSelector", `WidgetCardSelector-${type}`, className, classes.root)}
      data-testid={testId}
      onClick={onClick}
    >
      {content()}
    </StyledButtonBase>
  );
};

export default WidgetCardSelector;
