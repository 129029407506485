import { KpkAttachment, KpkMedia } from "@keepeek/commons";

import {
  UseCopyAttachments,
  useCopyAttachments,
  UseCopyAttachment,
  useCopyAttachment,
} from "./copy";
import {
  useDownloadAttachment,
  UseDownloadAttachment,
  UseDownloadAttachments,
  useDownloadAttachments,
} from "./download";

export type UseAttachments = {} & UseDownloadAttachments & UseCopyAttachments;

type UseAttachmentsProps = {
  elementId: KpkMedia["id"] | undefined;
};

export const useAttachments = ({ elementId }: UseAttachmentsProps): UseAttachments => {
  const downloadAttachments = useDownloadAttachments({
    elementId,
  });
  const copyAttachments = useCopyAttachments({ elementId });

  // Redefine loading state, even if it's pulling the same deps in both hooks
  // So we're safe if the deps change in later dev
  const loadingAttachments =
    copyAttachments.loadingAttachments || downloadAttachments.loadingAttachments;
  const loading = copyAttachments.loading || downloadAttachments.loading;

  return {
    ...copyAttachments,
    ...downloadAttachments,
    loadingAttachments,
    loading,
  };
};

export type UseAttachment = {} & UseCopyAttachment & UseDownloadAttachment;

type UseAttachmentProps = {
  elementId: KpkMedia["id"];
  attachmentId: KpkAttachment["id"];
};

export const useAttachment = ({ elementId, attachmentId }: UseAttachmentProps): UseAttachment => {
  const downloadAttachment = useDownloadAttachment({ elementId, attachmentId });
  const copyAttachment = useCopyAttachment({ elementId, attachmentId });

  // Redefine loading state, even if it's pulling the same deps in both hooks
  // So we're safe if the deps change in later dev
  const loading = copyAttachment.loading || downloadAttachment.loading;
  const loadingAttachment = copyAttachment.loadingAttachment;

  return {
    ...copyAttachment,
    ...downloadAttachment,
    loading,
    loadingAttachment,
  };
};
