import { useEffect, useState } from "react";

import { elementFetcherSelector, fetcherModeAtom, KpkMedia, useShare } from "@keepeek/commons";
import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";

import { MediaDetailMode } from "../../components/detail/modes/MediaDetailMode";
import { CONFIGURATION_SECTION_PATH } from "../../components/layout/adminMenu";
import logger from "../../lib/logger-utils";
import { parseNumericParameter } from "../../lib/query-util";
import { SentryBreadcrumbCategory } from "../../lib/sentry/breadcrumbs";
import { ShareManager } from "../../models/configuration/components/shareManager";
import { Language, Mode } from "../../models/configuration/pages/element";
import { useConfiguration } from "../config/hooks";
import { configSectionPageElementSelector } from "../config/selectors";
import {
  defaultReturnElementAndFields,
  elementAndFieldsToDisplaySelector,
  ElementAndFieldsToDisplaySelectorType,
  nextElementIdSelector,
  previousElementIdSelector,
} from "./selectors";

type UseElement = {
  elementId: KpkMedia["id"] | undefined;
  element: KpkMedia | undefined;
  fields: ElementAndFieldsToDisplaySelectorType["fields"] | undefined;
  loading: boolean;
  mode: MediaDetailMode;
  showAttachments: boolean;
  showAddToBasket: boolean;
  showUnitaryPublicShare: boolean;
  previewDimensions: {
    height: undefined | number;
    width: undefined | number;
    ratio: undefined | number;
  };
};

export const useElement = (): UseElement => {
  const router = useRouter();
  const { isShare } = useShare(router.pathname, router.query.tokenId);
  const elementId =
    parseNumericParameter(router.query.id || router.query.sharedMediaId) || undefined;

  const queryElementAndFields = useRecoilValueLoadable(
    elementAndFieldsToDisplaySelector({ language: router.locale as Language, elementId }),
  );
  const configSectionElement = useRecoilValue(configSectionPageElementSelector);
  const { mode = Mode.Fixed, showAttachments = false } = configSectionElement || {};

  const [previewDimensions, setPreviewDimensions] = useState<UseElement["previewDimensions"]>({
    height: undefined,
    width: undefined,
    ratio: undefined,
  });

  const loading = queryElementAndFields.state === "loading";

  const error = queryElementAndFields.state === "hasError";

  const { element, fields } =
    queryElementAndFields.state === "hasValue"
      ? queryElementAndFields.contents
      : defaultReturnElementAndFields;

  const calculateDimensions = (e: Event) => {
    if (e.target) {
      const target = e.target as HTMLImageElement;
      setPreviewDimensions({
        height: target.height,
        width: target.width,
        ratio: target.width / target.height,
      });
    }
  };

  useEffect(() => {
    const img = new Image();
    img.addEventListener("load", calculateDimensions);
    if (element?.previewUrl) {
      img.src = element?.previewUrl;
    }
    return () => {
      img.removeEventListener("load", calculateDimensions);
    };
  }, [element]);

  useEffect(() => {
    if (!loading && !element && error) {
      logger.error(queryElementAndFields.contents);
      router.push("/404");
    }
  }, [element, error, loading, queryElementAndFields.contents, router]);

  const shareManagerConfig = useConfiguration<ShareManager>(
    CONFIGURATION_SECTION_PATH.COMPONENTS_SHARE_MANAGER,
  );

  const showUnitaryPublicShare =
    !isShare && (shareManagerConfig?.unitaryPublicShare?.active ?? false);

  return {
    elementId,
    element,
    fields,
    mode,
    showAttachments,
    showAddToBasket: !isShare,
    showUnitaryPublicShare,
    loading,
    previewDimensions,
  };
};

export const usePrevNextElement = (): {
  previousMediaId: number | null;
  nextMediaId: number | null;
  onPreviousElementClick: () => void;
  onNextElementClick: () => void;
} => {
  const router = useRouter();
  const { elementId } = useElement();

  const mode = useRecoilValue(fetcherModeAtom);
  const elementFetcherSelectorQuery = useRecoilValueLoadable(
    elementFetcherSelector({
      fetcherMode: mode,
      embeddedFolders: true,
    }),
  );

  const previousMediaQuery = useRecoilValueLoadable<number | null>(
    previousElementIdSelector(elementId),
  );
  const nextMediaQuery = useRecoilValueLoadable<number | null>(nextElementIdSelector(elementId));

  const previousMediaId =
    previousMediaQuery.state === "hasValue" ? previousMediaQuery.contents : null;
  const nextMediaId = nextMediaQuery.state === "hasValue" ? nextMediaQuery.contents : null;

  function onPreviousElementClick() {
    if (elementFetcherSelectorQuery.state === "hasValue") {
      const { getElementPath } = elementFetcherSelectorQuery.contents;
      Sentry.addBreadcrumb({
        category: SentryBreadcrumbCategory.USER_INTERACTION,
        message: `usePrevNextElement - prev: ${previousMediaId}`,
      });
      previousMediaId && router.push(getElementPath(previousMediaId));
    }
  }

  function onNextElementClick() {
    if (elementFetcherSelectorQuery.state === "hasValue") {
      const { getElementPath } = elementFetcherSelectorQuery.contents;
      Sentry.addBreadcrumb({
        category: SentryBreadcrumbCategory.USER_INTERACTION,
        message: `usePrevNextElement - next: ${nextMediaId}`,
      });
      nextMediaId && router.push(getElementPath(nextMediaId));
    }
  }

  return {
    previousMediaId,
    nextMediaId,
    onPreviousElementClick,
    onNextElementClick,
  };
};
