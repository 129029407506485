import {
  customerCommonOverrideAtom,
  customSelectorInOut,
  elementQuerySelector,
  FetcherMode,
  fetcherModeAtom,
  FunctionOverrideKey,
  KpkAttachment,
  KpkMedia,
} from "@keepeek/commons";
import { selectorFamily } from "recoil";

import { convertToKpkAttachments } from "../../../lib/attachments/attachments-utils";
import { configSectionComponentDownloadManagerSelector } from "../../config/selectors";
import { attachmentFetcherSelector } from "../../fetcher/selectors/attachment";

export const attachmentsQuerySelector = selectorFamily<
  KpkAttachment[] | undefined,
  { locale: string | undefined; elementId?: KpkMedia["id"] }
>({
  key: "AttachmentsQuerySelector",
  get:
    ({ locale, elementId }) =>
    async ({ get }) => {
      if (!locale) {
        console.debug("attachmentsQuerySelector current locale atom is not valued");
        return;
      }
      const { enableOtherFormats } = get(configSectionComponentDownloadManagerSelector) ?? {
        enableOtherFormats: undefined,
      };

      if (!enableOtherFormats || !elementId) {
        return undefined;
      }
      // Check attachmentCounts property, if absent user is not granted to attachments
      const element = get(
        elementQuerySelector({
          elementId,
          embeddedFolders: true,
        }),
      );
      const fetcherMode = get(fetcherModeAtom);

      if (!element?.attachmentCounts && !(fetcherMode === FetcherMode.SHARE)) {
        return undefined;
      }

      const { getMediaAttachments } = get(attachmentFetcherSelector(fetcherMode));
      const call = await getMediaAttachments({
        mediaId: elementId,
        // RF-1133: size to 100 to cover enough usecase
        // IF we need to pump this number up, maybe we should be questionning the purpose of this much attachments
        size: 100,
        axiosRequestConfig: { handleErrorLocally: true },
      });
      if (!call) {
        return undefined;
      }
      const { data } = call;
      const kpkAttachments = convertToKpkAttachments(data._embedded?.attachment, locale, true);

      const { attachments } = customSelectorInOut(
        FunctionOverrideKey.AttachmentsSelectorOut,
        {
          attachments: kpkAttachments,
        },
        get(customerCommonOverrideAtom),
      );

      return attachments;
    },
});

export const attachmentSelector = selectorFamily<
  KpkAttachment | undefined,
  { locale: string | undefined; attachmentId?: KpkAttachment["id"]; elementId?: KpkMedia["id"] }
>({
  key: "AttachmentSelector",
  get:
    ({ locale, attachmentId, elementId }) =>
    ({ get }) => {
      const attachments = get(attachmentsQuerySelector({ locale, elementId }));
      return attachments?.find((a) => a.id === attachmentId);
    },
});
