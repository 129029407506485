import { useEffect } from "react";

import { BasketKey, BusinessFilter, DataViewKey } from "@keepeek/commons";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { serializeComplexeParamsForRecoil } from "../../../lib/recoil-utils";
import { configSectionComponentBusinessFilterSelector } from "../../config/selectors";
import { dataViewPage, dataViewSize, shouldUpdateAdvancedSearchId } from "../atoms";
import { dataViewAdvancedSearchId } from "../selectors/create";
import { dataViewSelector } from "../selectors/elements";
import { dataViewSortSelector } from "../selectors/sort";
import { DataViewElementsSelector } from "../types";
import { advancedSearchIsExpired } from "../utils";

export const useDataView = ({
  key,
  filters,
}: {
  key: DataViewKey | BasketKey;
  filters: BusinessFilter[] | null;
}) => {
  const shouldUpdateCreateQuery = useSetRecoilState(shouldUpdateAdvancedSearchId(key));

  const size = useRecoilValue(dataViewSize(key));
  const [page, setPage] = useRecoilState(dataViewPage(key));
  const sort = useRecoilValue(dataViewSortSelector(key));
  const filtersConfig =
    useRecoilValue(configSectionComponentBusinessFilterSelector)?.filters || null;
  const createQuery = useRecoilValue(
    dataViewAdvancedSearchId(
      serializeComplexeParamsForRecoil({
        key,
        filters,
      }),
    ),
  );

  const elementsParams: DataViewElementsSelector = {
    key,
    size,
    page,
    sort,
    filtersConfiguration: filtersConfig,
    filters,
  };

  const { elements, totalCount } = useRecoilValue(
    dataViewSelector(serializeComplexeParamsForRecoil(elementsParams)),
  );

  if (createQuery && advancedSearchIsExpired(createQuery.expirationDate)) {
    shouldUpdateCreateQuery((currVal) => (currVal += 1));
  }

  /**
   * This useEffect take care of the page reset if we're
   * still on a page that does not show any elements
   */
  useEffect(() => {
    if (totalCount > 0 && elements.length === 0 && page > 1) {
      setPage(1);
    }
  }, [elements.length, page, setPage, totalCount]);

  return {
    elements,
  };
};
