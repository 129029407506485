import { BusinessFilter, BusinessFilterNature, KpkFilter } from "@keepeek/commons";

export function convertBusinessFilterToKpkFilter(businessFilter: BusinessFilter): KpkFilter {
  return {
    ...businessFilter.filter,
    id: `${businessFilter.nature}-${businessFilter.filter.id}`,
  };
}

export function convertKpkFilterToBusinessFilter(kpkFilter: KpkFilter): BusinessFilter {
  const nature = kpkFilter.id.split("-")[0] as BusinessFilterNature;
  return {
    nature: nature,
    filter: {
      ...kpkFilter,
      id: kpkFilter.id.substr(nature.length + 1),
    },
  };
}
