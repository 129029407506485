import { useEffect } from "react";

import { BasketKey, DataViewKey, removeUndefinedDeep } from "@keepeek/commons";
import { useRouter } from "next/router";
import { useRecoilState, useSetRecoilState } from "recoil";

import { PagesPath } from "../../../containers/App/utils";
import { shouldClearSelectedElementsByKeysAtom } from "../atoms";
import { selectedElementsFromKeysSelector } from "../selectors/selected";

export const useClearSelectionRules = () => {
  const router = useRouter();
  const [shouldClearSelectedElementsByKeys, setShouldClearSelectedElementsByKeys] = useRecoilState(
    shouldClearSelectedElementsByKeysAtom,
  );
  const setSelectElementsFromKeys = useSetRecoilState(
    selectedElementsFromKeysSelector(shouldClearSelectedElementsByKeys),
  );

  useEffect(() => {
    const currentPage = router.pathname;
    // On every page change check rules and set should clear
    // iterate over keys
    const keys = Object.values({ ...DataViewKey, ...BasketKey });
    const shouldClearKeys = removeUndefinedDeep(
      keys.map((key) => {
        switch (key) {
          case DataViewKey.SEARCH:
            if (
              !(currentPage === PagesPath.SEARCH_PAGE || currentPage === PagesPath.ELEMENT_PAGE)
            ) {
              return key;
            }
            return undefined;
          case DataViewKey.BASKET:
          default:
            return undefined;
        }
      }),
    );
    if (shouldClearKeys.length > 0) {
      setShouldClearSelectedElementsByKeys(shouldClearKeys);
    }
  }, [router.pathname, setShouldClearSelectedElementsByKeys]);

  useEffect(() => {
    // If we have to clear, clear the selecteds elements by keys
    // And reset keys table
    if (shouldClearSelectedElementsByKeys.length > 0) {
      setSelectElementsFromKeys([]);
      setShouldClearSelectedElementsByKeys([]);
    }
  }, [
    setSelectElementsFromKeys,
    setShouldClearSelectedElementsByKeys,
    shouldClearSelectedElementsByKeys.length,
  ]);
};
