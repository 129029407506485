import { TFunction } from "i18next";

import {
  WidgetAdminLabelKeys,
  CONFIGURATION_SECTION_PATH,
} from "../../../../components/layout/adminMenu";
import { Type } from "../../../../models/configuration/definitions/widget";
import logger from "../../../logger-utils";

export const convertWidgetConfigSectionToWidgetType = (
  configSection: CONFIGURATION_SECTION_PATH,
): Type | undefined => {
  switch (configSection) {
    case CONFIGURATION_SECTION_PATH.COMPONENTS_ASSOCIATIONS_WIDGET:
      return Type.Associations;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_EDITO_WIDGET:
      return Type.Edito;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_FOOTER_WIDGET:
      return Type.Footer;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_K_HEADER_WIDGET:
      return Type.Header;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_K_IMAGE_WIDGET:
      return Type.Image;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_MOSAIC:
      return Type.Mosaic;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_SEARCH_WIDGET:
      return Type.Search;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_SPACER_WIDGET:
      return Type.Spacer;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_CUSTOM_WIDGET:
      return Type.Custom;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_TABS_WIDGET:
      return Type.Tabs;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_K_VIDEO_WIDGET:
      return Type.Video;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_SHEET_WIDGET:
      return Type.Sheet;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_ATTACHMENTS_WIDGET:
      return Type.Attachments;
    default:
      logger.debug(
        `convertWidgetConfigSectionToWidgetType: ${configSection} type not supported yet`,
      );
      return undefined;
  }
};

export const convertWidgetConfigSectionToWidgetTitle = (
  configSection: CONFIGURATION_SECTION_PATH,
  t: TFunction,
): string | undefined => {
  switch (configSection) {
    case CONFIGURATION_SECTION_PATH.COMPONENTS_ASSOCIATIONS_WIDGET:
      return t(WidgetAdminLabelKeys.Associations) as string;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_EDITO_WIDGET:
      return t(WidgetAdminLabelKeys.Edito) as string;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_FOOTER_WIDGET:
      return t(WidgetAdminLabelKeys.Footer) as string;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_K_HEADER_WIDGET:
      return t(WidgetAdminLabelKeys.Header) as string;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_K_IMAGE_WIDGET:
      return t(WidgetAdminLabelKeys.Image) as string;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_MOSAIC:
      return t(WidgetAdminLabelKeys.Mosaic) as string;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_SEARCH_WIDGET:
      return t(WidgetAdminLabelKeys.Search) as string;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_SPACER_WIDGET:
      return t(WidgetAdminLabelKeys.Spacer) as string;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_CUSTOM_WIDGET:
      return t(WidgetAdminLabelKeys.Custom) as string;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_TABS_WIDGET:
      return t(WidgetAdminLabelKeys.Tabs) as string;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_K_VIDEO_WIDGET:
      return t(WidgetAdminLabelKeys.Video) as string;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_SHEET_WIDGET:
      return t(WidgetAdminLabelKeys.Sheet) as string;
    case CONFIGURATION_SECTION_PATH.COMPONENTS_ATTACHMENTS_WIDGET:
      return t(WidgetAdminLabelKeys.Attachments) as string;
    default:
      logger.debug(
        `convertWidgetConfigSectionToWidgetTitle: ${configSection} type not supported yet`,
      );
      return undefined;
  }
};

export const dummyWidgetAttachments = {
  configSection: CONFIGURATION_SECTION_PATH.COMPONENTS_ATTACHMENTS_WIDGET,
  jsonData: [{ id: "attachments" }],
};

export const dummyWidgetSheet = {
  configSection: CONFIGURATION_SECTION_PATH.COMPONENTS_SHEET_WIDGET,
  jsonData: [{ id: "sheet" }],
};

export const dummyWidgetCustom = {
  configSection: CONFIGURATION_SECTION_PATH.COMPONENTS_CUSTOM_WIDGET,
  jsonData: [{ id: "custom" }],
};
