import { SerializableParam } from "recoil";

/**
 * Allows you to convert a non-serializable complex object (and not usable by recoil) into a recoil compatible object
 * by implementing "toJSON" function.
 *
 * cf: https://recoiljs.org/docs/api-reference/utils/selectorFamily/#parameter-type
 */
export function serializeComplexeParamsForRecoil<T>(params: T): T & SerializableParam {
  return {
    ...params,
    toJSON: () => {
      return JSON.stringify(params);
    },
  };
}
