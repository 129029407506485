import { isClientSide, IsJsonString } from "@keepeek/commons";
import { AtomEffect, DefaultValue } from "recoil";

export const localStorageEffect =
  (key: string): AtomEffect<any> =>
  ({ setSelf, onSet }) => {
    if (isClientSide()) {
      const savedValue = localStorage.getItem(key);

      if (savedValue !== null && IsJsonString(savedValue)) {
        setSelf(JSON.parse(savedValue));
      }

      onSet((newValue: any, _, isReset) => {
        if (newValue instanceof DefaultValue || isReset) {
          localStorage.removeItem(key);
        } else {
          localStorage.setItem(key, JSON.stringify(newValue));
        }
      });
    }
  };

export const sessionStorageEffect =
  (key: string): AtomEffect<any> =>
  ({ setSelf, onSet }) => {
    if (isClientSide()) {
      const savedValue = sessionStorage.getItem(key);

      if (savedValue !== null && IsJsonString(savedValue)) {
        setSelf(JSON.parse(savedValue));
      }

      onSet((newValue: any, _, isReset) => {
        if (newValue instanceof DefaultValue || isReset) {
          sessionStorage.removeItem(key);
        } else {
          sessionStorage.setItem(key, JSON.stringify(newValue));
        }
      });
    }
  };
