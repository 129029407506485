import { useEffect } from "react";

import { useRouter } from "next/router";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";

import { PagesPath } from "../../../containers/App/utils";
import { initConfigApiAtom, initConfigRefresherAtom } from "../atoms/init";
import { configApiSelector } from "../selectors/init";
import { InitConfigApi } from "../utils";

export const useInitConfig = (): {
  loading: boolean;
  initConfig: InitConfigApi | undefined;
  refreshConfig: () => void;
} => {
  const configInitQuery = useRecoilValueLoadable(configApiSelector);
  const [initConfig, setInitConfig] = useRecoilState(initConfigApiAtom);
  const refresh = useSetRecoilState(initConfigRefresherAtom);
  const loading = configInitQuery.state === "loading";

  const router = useRouter();

  const refreshConfig = () => {
    refresh((prev) => prev + 1);
  };

  useEffect(() => {
    if (configInitQuery.state === "hasValue") {
      setInitConfig(configInitQuery.contents);
    }
  }, [configInitQuery.contents, configInitQuery.state, setInitConfig]);

  // This trigger the onboarding process
  // First time configuring the ReFront
  useEffect(() => {
    if (
      !loading &&
      configInitQuery.state === "hasValue" &&
      initConfig?.config?.jsonConfig.length === 0 &&
      configInitQuery.contents?.config?.jsonConfig.length === 0 &&
      router.pathname === "/"
    ) {
      router.push(PagesPath.ONBOARDING);
    }
  }, [configInitQuery, initConfig, loading, router]);

  return {
    loading,
    initConfig,
    refreshConfig,
  };
};
