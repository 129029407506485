export interface BusinessFilter {
    filters?: BusinessFilterFilter[];
}

export interface BusinessFilterFilter {
    hideFacetOccurrences?:      boolean;
    name?:                      string;
    orderDirectionFacetValues?: OrderDirectionFacetValues;
    orderTypeFacetValues?:      OrderTypeFacetValues;
    type?:                      Type;
    filters?:                   FilterFilter[];
    title?:                     TranslationSchema[];
}

export interface FilterFilter {
    hideFacetOccurrences?:      boolean;
    name?:                      string;
    orderDirectionFacetValues?: OrderDirectionFacetValues;
    orderTypeFacetValues?:      OrderTypeFacetValues;
    type?:                      Type;
}

export enum OrderDirectionFacetValues {
    Asc = "ASC",
    Desc = "DESC",
}

export enum OrderTypeFacetValues {
    Alphabetic = "alphabetic",
    Occurrences = "occurrences",
}

export enum Type {
    Advanced = "advanced",
    Facet = "facet",
    FacetMulti = "facet-multi",
}

export interface TranslationSchema {
    empty?:      boolean;
    language:    Language;
    ressources?: any;
    value?:      string;
}

export enum Language {
    De = "de",
    En = "en",
    Es = "es",
    Fr = "fr",
    It = "it",
}
