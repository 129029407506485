import React, { FC } from "react";

import { KpkMedia, useShareToken } from "@keepeek/commons";
import { Box, Link, Typography } from "@mui/material";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import { CONFIGURATION_SECTION_PATH } from "../../../../components/layout/adminMenu";
import { ActionValue } from "../../../../components/search/Dataview/Actions";
import { Search } from "../../../../models/configuration/pages/search";
import { Inherit, Share } from "../../../../models/configuration/pages/share";
import { useConfiguration } from "../../../../providers/config/hooks";

export type ElementLinksProps = {
  actionClick: (action: ActionValue) => void;
  associationClick: () => void;
  element: KpkMedia;
};

const ElementLinks: FC<React.PropsWithChildren<ElementLinksProps>> = function ({
  actionClick,
  associationClick,
  element,
}) {
  const { t } = useTranslation();

  const downloadManagerConf: any = useConfiguration(
    CONFIGURATION_SECTION_PATH.COMPONENTS_DOWNLOAD_MANAGER,
  );
  const enableOtherFormats = downloadManagerConf?.enableOtherFormats;
  const pagesSearchConf = useConfiguration<Search>(CONFIGURATION_SECTION_PATH.PAGES_SEARCH);
  const pagesShareConf = useConfiguration<Share & Search>(CONFIGURATION_SECTION_PATH.PAGES_SHARE);
  const { pathname, query } = useRouter();
  const { isShare } = useShareToken(pathname, query.tokenId);
  const cardLinksPageSearchConf = pagesSearchConf?.cardLinks;

  const showSearchConf = pagesShareConf?.inherit === Inherit.Inherit || !isShare;
  const hasMediaLink = element.mediaLinkCount !== 0 && element.mediaLinkCount !== undefined;
  const hasAttachments =
    element.attachmentCounts !== undefined &&
    element.attachmentCounts?.online !== undefined &&
    element.attachmentCounts?.online !== 0;
  if (
    (!hasMediaLink && !hasAttachments) ||
    !cardLinksPageSearchConf ||
    (showSearchConf &&
      !cardLinksPageSearchConf?.mediaLinks &&
      !cardLinksPageSearchConf?.attachments) ||
    (!showSearchConf &&
      !pagesShareConf?.cardLinks?.mediaLinks &&
      !pagesShareConf?.cardLinks?.attachments)
  ) {
    return null;
  }

  const displayMediaLinksButton =
    hasMediaLink &&
    ((showSearchConf && cardLinksPageSearchConf && cardLinksPageSearchConf.mediaLinks) ||
      (!showSearchConf && pagesShareConf?.cardLinks?.mediaLinks));

  const displayAttachmentsButton =
    hasAttachments &&
    cardLinksPageSearchConf &&
    ((showSearchConf && cardLinksPageSearchConf && cardLinksPageSearchConf.attachments) ||
      (!showSearchConf && pagesShareConf?.cardLinks?.attachments)) &&
    enableOtherFormats;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        background: (theme) => theme.palette.grey[100],
        padding: (theme) => theme.spacing(0.5, 2),
        borderTop: (theme) => `1px solid ${theme.palette.grey[200]}`,
      }}
    >
      {displayMediaLinksButton && (
        <Link component="button" color="primary" underline="hover" onClick={associationClick}>
          <Typography sx={{ fontSize: "0.8rem", fontWeight: "bold" }}>
            {t("element-summary.links.media-links")}
          </Typography>
        </Link>
      )}
      {displayAttachmentsButton && (
        <Link
          component="button"
          color="primary"
          underline="hover"
          onClick={() => actionClick(ActionValue.SHOW_ATTACHMENT)}
        >
          <Typography sx={{ fontSize: "0.8rem", fontWeight: "bold" }}>
            {t("element-summary.links.attachments")}
          </Typography>
        </Link>
      )}
    </Box>
  );
};

export default ElementLinks;
