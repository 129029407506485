import React from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { Box, Chip, useTheme } from "@mui/material";
import clsx from "clsx";

import {
  FooterWidget,
  LanguageSwitcherPosition,
} from "../../../../../models/configuration/components/footerWidget";
import { useCurrentLocale } from "../../../../../providers/i18n/hooks";

const WidgetCardSelectorFooter = function ({
  testId = "WidgetCardSelectorFooter",
  className,
  backgroundColor,
  borderColor,
  container,
  languageSwitcherPosition,
  linksByLocale,
}: FooterWidget & BaseComponentProps) {
  const theme = useTheme();
  const locale = useCurrentLocale();
  return (
    <Box
      className={clsx("WidgetCardSelectorFooter", className)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexDirection: "column",
        width: "100%",
      }}
      data-testid={testId}
    >
      <Box
        style={{
          backgroundColor: theme.palette.primary.main,
          height: theme.spacing(5),
          width: "80%",
          borderRadius: "0 0 .25rem .25rem",
          flexGrow: 1,
          marginBottom: theme.spacing(2),
        }}
      />
      <Box
        style={{
          backgroundColor: backgroundColor ?? theme.palette.primary.main,
          borderTop: `1px solid ${borderColor ?? theme.palette.primary.main}`,
          borderLeft: container ? `1px solid ${borderColor ?? theme.palette.primary.main}` : "none",
          borderRight: container
            ? `1px solid ${borderColor ?? theme.palette.primary.main}`
            : "none",
          width: container ? `calc(100% - ${theme.spacing(2)})` : "100%",
          padding: theme.spacing(1),
        }}
      >
        {languageSwitcherPosition === LanguageSwitcherPosition.Left && (
          <Chip size="small" label={locale} />
        )}
        {!!linksByLocale && (
          <Chip
            size="small"
            style={{ marginLeft: theme.spacing(0.5), marginRight: theme.spacing(0.5) }}
            label={`link${(linksByLocale?.length ?? 0) > 1 ? "s" : ""}: ${linksByLocale?.length}`}
          />
        )}
        {languageSwitcherPosition === LanguageSwitcherPosition.Right && (
          <Chip size="small" label={locale} />
        )}
      </Box>
    </Box>
  );
};

export default WidgetCardSelectorFooter;
