import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { JsonConfig } from "../../../lib/commonPagePropsUtil";
import { getAdminConfigSectionData } from "../../../lib/config/customer-config-utils";
import logger from "../../../lib/logger-utils";
import { KVideoWidget } from "../../../models/configuration/components/kVideoWidget";
import { Type } from "../../../models/configuration/definitions/widget";

import { KVideoWidgetProps } from ".";

export function getKVideoWidgetConfiguration(
  customerSectionConfiguration: JsonConfig[],
  widgetId: KVideoWidget["id"],
): KVideoWidgetProps | undefined {
  const kVideoWidgets = getAdminConfigSectionData<KVideoWidget[]>(
    customerSectionConfiguration,
    CONFIGURATION_SECTION_PATH.COMPONENTS_K_VIDEO_WIDGET,
  );

  if (kVideoWidgets?.length === undefined || kVideoWidgets.length === 0) {
    logger.info(`getKVideoWidgetConfiguration - kVideoWidgets is empty or undefined`);
    return;
  }

  const kVideoWidget: KVideoWidget | undefined = kVideoWidgets?.find((kvw) => kvw.id === widgetId);

  if (!kVideoWidget) {
    logger.info(`getKVideoWidgetConfiguration - can't find kVideoWidget id ${widgetId}`);
    return;
  }

  return { ...kVideoWidget, widgetId, type: Type.Video };
}
