import { KpkApiAdvancedSearchCriteriaType } from "@keepeek/api-client";
import { KpkFilterType } from "@keepeek/commons";

export function convertKpkApiAdvancedSearchCriteriaTypeToKpkFilterType(
  advancedSearchType?: KpkApiAdvancedSearchCriteriaType,
): KpkFilterType | null {
  switch (advancedSearchType) {
    case KpkApiAdvancedSearchCriteriaType.Textarea:
    case KpkApiAdvancedSearchCriteriaType.Textfield:
    case KpkApiAdvancedSearchCriteriaType.Emailfield:
    case KpkApiAdvancedSearchCriteriaType.Extensionfield:
      return KpkFilterType.Text;
    case KpkApiAdvancedSearchCriteriaType.Folderfield:
      return KpkFilterType.Folder;
    case KpkApiAdvancedSearchCriteriaType.Datefield:
    case KpkApiAdvancedSearchCriteriaType.Datetimefield:
      return KpkFilterType.Date;
    case KpkApiAdvancedSearchCriteriaType.Checkboxfield:
      return KpkFilterType.Radio;
    case KpkApiAdvancedSearchCriteriaType.Durationfield:
      return KpkFilterType.Duration;
    case KpkApiAdvancedSearchCriteriaType.Thesaurusfield:
      return KpkFilterType.Thesaurus;
    default:
      return null;
  }
}
