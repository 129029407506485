import { BusinessFilter, BusinessFilterNature, KpkFilterType } from "@keepeek/commons";

export function createFullTextBusinessFilter(search: string, filterLabel: string): BusinessFilter {
  return {
    nature: BusinessFilterNature.FULL_TEXT,
    filter: {
      id: BusinessFilterNature.FULL_TEXT,
      values: [
        {
          id: search,
          label: search,
          selected: null,
        },
      ],
      type: KpkFilterType.Text,
      label: filterLabel,
      modifiers: null,
    },
  };
}
