import {
  authLoadingSelector,
  fetcherTokenIdAtom,
  isAuthenticatedSelector,
  isClientSide,
  KCGuest,
  shareTokenAxiosHeadersSelector,
} from "@keepeek/commons";
import { CustomerAppTheme } from "@keepeek/refront-customers";
import { selector } from "recoil";

import { getAxiosClientInstance } from "../../../lib/axios/axios-utils";
import { Config, JsonConfig } from "../../../lib/commonPagePropsUtil";
import { getCustomerConfig } from "../../../lib/config/customer-config-utils";
import { initConfigApiAtom, initConfigRefresherAtom } from "../atoms/init";
import { InitConfigApi } from "../utils";

export const configApiSelector = selector<InitConfigApi | undefined>({
  key: "ConfigApiSelector",
  get: async ({ get }) => {
    // Refresh mechanism
    // this is to avoid clearing the other selectors if we call the recoil refresh function
    // We just want to recall the api endpoint to have fresh data on the config only
    get(initConfigRefresherAtom);
    if (isClientSide()) {
      get(isAuthenticatedSelector);
      const shareToken = get(fetcherTokenIdAtom);
      const headers = get(shareTokenAxiosHeadersSelector);
      if (shareToken !== "") {
        const { data: configQuery } = await getAxiosClientInstance().get(
          `${location.origin}/api/config?shareToken=${shareToken}`,
          {
            baseURL: location.origin,
            headers,
          },
        );
        return configQuery as InitConfigApi;
      }
      const authenticationInProgress = get(authLoadingSelector);
      // do not fetch configuration during authentication step to avoid double fetch
      if (!authenticationInProgress) {
        const { data: configQuery } = await getAxiosClientInstance().get(
          `${location.origin}/api/config`,
          {
            baseURL: location.origin,
          },
        );
        return configQuery as InitConfigApi;
      }
    }
    return get(initConfigApiAtom);
  },
});

export const configSelector = selector<Config | undefined>({
  key: "ConfigSelector",
  get: ({ get }) => get(initConfigApiAtom)?.config,
});

export const jsonConfigSelector = selector<JsonConfig[] | undefined>({
  key: "JsonConfigSelector",
  get: ({ get }) => get(configSelector)?.jsonConfig,
});

export const clientIpSelector = selector<string | undefined>({
  key: "ClientIpSelector",
  get: ({ get }) => get(initConfigApiAtom)?.clientIp,
});

export const customerAppThemeSelector = selector<CustomerAppTheme>({
  key: "CustomerAppThemeSelector",
  get: () => getCustomerConfig().theme,
});

export const kcGuestSelector = selector<KCGuest | undefined>({
  key: "KCGuestSelector",
  get: ({ get }) => get(configSelector)?.kcGuest,
});
