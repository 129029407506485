import React, { FC } from "react";

import { ComponentOverrideKey } from "@keepeek/refront-customers";
import Head from "next/head";
import { useTranslation } from "react-i18next";
import { useRecoilValueLoadable } from "recoil";

import { CONFIGURATION_SECTION_PATH } from "../../components/layout/adminMenu";
import { getCustomerOverrideComponent } from "../../lib/overrides";
import { Key, Theme } from "../../models/configuration/theme";
import { useConfiguration } from "../../providers/config/hooks";
import { useHeaderMetas } from "../../providers/config/hooks/useHeaderMetas";
import { configSectionThemeAssetSelector } from "../../providers/config/selectors";

const KHead: FC<React.PropsWithChildren<unknown>> = function () {
  const faviconQuery = useRecoilValueLoadable(configSectionThemeAssetSelector(Key.ImageFavicon));
  const [favicon] = faviconQuery.state === "hasValue" ? faviconQuery.contents : [undefined];
  const configTheme = useConfiguration<Theme>(CONFIGURATION_SECTION_PATH.THEME);
  let faviconSrc: string | undefined = favicon;
  if (configTheme && configTheme?.favicon) {
    faviconSrc = configTheme?.favicon?.url;
  }
  const { t } = useTranslation();

  const { title, description, og } = useHeaderMetas();

  const titleRendered = title ? `${title} — ${t("app.title")}` : t("app.title");

  return (
    <Head>
      {faviconSrc && (
        <>
          <link
            key="favicon32"
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/api/favicon?w=32"
          />
          <link
            key="favicon192"
            rel="icon"
            type="image/png"
            sizes="192x192"
            href="/api/favicon?w=192"
          />
        </>
      )}
      <title key="title">{titleRendered}</title>
      {description && <meta key="description" content={description} />}
      {(title || og?.title) && (
        <meta key="ogTitle" property="og:title" content={og?.title ?? title} />
      )}
      {og?.type && <meta key="ogType" property="og:type" content={og.type} />}
      {og?.url && <meta key="ogUrl" property="og:url" content={og.url} />}
      {og?.image && <meta key="ogImage" property="og:image" content={og.image} />}
      {(description || og?.description) && (
        <meta
          key="ogDescription"
          property="og:description"
          content={og?.description ?? description}
        />
      )}
      <meta
        key="ogSiteName"
        property="og:site_name"
        content={og?.siteName ?? (t("app.title") as string)}
      />
      <meta
        key="viewport"
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width"
      />
      <meta key="robots" name="robots" content="noindex, nofollow" />
    </Head>
  );
};

export default getCustomerOverrideComponent(ComponentOverrideKey.KHead, KHead);
