import { BusinessFilter, KpkFilter, KpkFilterValue } from "@keepeek/commons";
import { selector, selectorFamily } from "recoil";

import { lbfState } from "../atoms/lbf";
import { lbfToBusinessFiltersSelector } from "./lbf";

/**
 * Return the active filters from URL
 * This selector allow to have multiple connectors with the URL
 * Support LBF type at the moment
 * @author JBO
 */
export const activeBusinessFiltersFromURLSelector = selector<BusinessFilter[]>({
  key: "ActiveBusinessFiltersSelector",
  get: ({ get }) => {
    // TODO: for the moment we only support the LBF
    // Add support for a new type of URL filtering, more concise and readable but with more logic behind
    const lbf = get(lbfToBusinessFiltersSelector);
    return lbf;
  },
});

export const activeRawURLFiltersFromURLSelector = selector<{ lbf: string }>({
  key: "ActiveRawURLFiltersFromURLSelector",
  get: ({ get }) => {
    // TODO: for the moment we only support the LBF
    // Add support for a new type of URL filtering, more concise and readable but with more logic behind
    const lbf = get(lbfState);
    const params = {
      lbf,
    };
    return params;
  },
});

export const activeFilterValuesFromURLSelector = selectorFamily<
  KpkFilterValue[],
  { filterId: KpkFilter["id"]; nature: BusinessFilter["nature"] }
>({
  key: "ActiveFilterFromLBFSelector",
  get:
    ({ filterId, nature: natureIn }) =>
    ({ get }) => {
      const businessFilter = get(activeBusinessFiltersFromURLSelector).find(
        ({ filter, nature }) => filter.id === filterId && nature === natureIn,
      );
      if (!businessFilter) {
        return [];
      }
      return businessFilter.filter.values;
    },
});
