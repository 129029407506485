import { useEffect } from "react";

import { BasketKey, DataViewKey, KpkMedia } from "@keepeek/commons";
import { useSetRecoilState } from "recoil";

import { selectedElements } from "../atoms";

export const useKeyboardSelection = (key: DataViewKey | BasketKey, elements: KpkMedia[]) => {
  const setSelectedElements = useSetRecoilState(selectedElements(key));
  useEffect(() => {
    const ctrlAHandler = (e: KeyboardEvent) => {
      const isControlPressed = e.ctrlKey || e.metaKey;
      const aIsPressed = e.key === "a" || e.key === "A";
      if (isControlPressed && aIsPressed && elements.length > 0) {
        e.preventDefault();
        setSelectedElements(elements.map((el) => el.id));
      }
    };

    document.body.addEventListener("keydown", ctrlAHandler);

    return () => {
      document.body.removeEventListener("keydown", ctrlAHandler);
    };
  }, [elements, setSelectedElements]);
};
