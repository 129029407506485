import { BusinessFilter, BusinessFilterNature, KpkFilterType } from "@keepeek/commons";

/**
 * Create a Business Filter of BASKET Nature
 * @param basketId basket id to filter on
 */
export function createBasketBusinessFilter(basketId: number): BusinessFilter {
  return {
    nature: BusinessFilterNature.BASKET,
    filter: {
      id: BusinessFilterNature.BASKET,
      label: "basketId",
      type: KpkFilterType.Basket,
      modifiers: null,
      values: [
        {
          id: basketId,
          label: "basketId",
          title: "",
          selected: null,
        },
      ],
    },
    hide: true,
  };
}
