import { DataViewKey, KpkMedia } from "@keepeek/commons";
import uniq from "lodash/uniq";
import { selectorFamily } from "recoil";

import { selectedElements } from "../atoms";
import { DEPRECATED_dataViewAutoSelector } from "./deprecated";

export type DataViewElementSelector = {
  key: DataViewKey;
  id: number;
};

export const elementIsSelected = selectorFamily<boolean, DataViewElementSelector>({
  key: "ElementIsSelected",
  get:
    ({ key, id }) =>
    ({ get }) =>
      get(selectedElements(key)).includes(id),
  set:
    ({ key, id }) =>
    ({ set, get }, value) => {
      const currentSelecteds = get(selectedElements(key));
      if (value) {
        set(selectedElements(key), uniq([...currentSelecteds, id]));
      } else {
        const newValues = currentSelecteds.filter((value) => value !== id);
        set(selectedElements(key), newValues);
      }
    },
});

export const dataViewElement = selectorFamily<
  KpkMedia | undefined,
  { key: DataViewKey; id: number }
>({
  key: "DataViewElement",
  get:
    ({ key, id }) =>
    ({ get }) =>
      get(DEPRECATED_dataViewAutoSelector(key)).elements.find((e) => e.id === id),
});
