import { FormatType } from "@keepeek/commons";
import { DOWNLOAD_MANAGER_FORMAT_BY } from "@keepeek/refront-components";
import { atom } from "recoil";

import { localStorageEffect } from "../../../lib/recoil-persist";

// Preferences

export const downloadManagerPreferenceLastUsedFormatsState = atom<FormatType["id"][]>({
  key: "DownloadManagerPreferenceLastUsedFormatsState",
  default: [],
  effects: [localStorageEffect("DownloadManagerPreferenceLastUsedFormatsState")],
});

export const downloadManagerPreferenceFormatByState = atom<DOWNLOAD_MANAGER_FORMAT_BY>({
  key: "DownloadManagerPreferenceFormatByState",
  default: DOWNLOAD_MANAGER_FORMAT_BY.TYPE,
  effects: [localStorageEffect("DownloadManagerPreferenceFormatByState")],
});

export const downloadManagerPreferenceFormatFavoritesState = atom<FormatType["id"][]>({
  key: "DownloadManagerPreferenceFormatFavoritesState",
  default: [],
  effects: [localStorageEffect("DownloadManagerPreferenceFormatFavoritesState")],
});
